import { useState, useEffect } from 'react';

const useTimer = (
  expirationTimeInMinutes: number = 30,
  onTimerExpired: () => void = () => {}
) => {
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    const loginAttemptCount = parseInt(localStorage.getItem('loginAttemptCount') || '0', 10);

    const startTimer = () => {
      // If countdownStartTime is not set, start the timer
      const newStartTime = new Date().getTime();
      setStartTime(newStartTime);
      sessionStorage.setItem('countdownStartTime', newStartTime.toString());
    };

    // Start the timer immediately if loginAttemptCount is greater than or equal to 3
    if (loginAttemptCount >= 3) {
      const countdownStartTime = sessionStorage.getItem('countdownStartTime');
      if (!countdownStartTime) {
        startTimer();
      }
    }

    // Run the timer logic whenever loginAttemptCount changes
    const loginCountInterval = setInterval(() => {
      const updatedLoginAttemptCount = parseInt(localStorage.getItem('loginAttemptCount') || '0', 10);
      if (updatedLoginAttemptCount >= 3) {
        const countdownStartTime = sessionStorage.getItem('countdownStartTime');
        if (!countdownStartTime) {
          startTimer();
        }
      }
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(loginCountInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect should only run once when the component mounts

  useEffect(() => {
    const timerInterval = setInterval(() => {
      // Check the timer expiration every second
      if (startTime) {
        const elapsedMilliseconds = new Date().getTime() - startTime;
        const elapsedMinutes = elapsedMilliseconds / (1000 * 60);

        if (elapsedMinutes >= expirationTimeInMinutes) {
          // If the expiration time has passed, reset the attempt count
          localStorage.removeItem('loginAttemptCount');
          localStorage.removeItem('countdownStartTime');
          onTimerExpired();
        }
      }
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);

  }, [startTime, expirationTimeInMinutes, onTimerExpired]);

  return startTime;
};

export default useTimer;
