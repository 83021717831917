import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TypeDiv from "../../../atoms/TypeDiv";
// import { getInvestmentDataById } from "../../services/addCompanyService";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { getPeopleInvestmentDataById } from "./helper";
import "./styles.scss";

const ViewInvestorPreference = ({ investmentType }) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [investmentData, setInvestmentData] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res;
        dispatch(setLoading(true));
        if (investmentType === "company") {
          res = await getData({
            endpoint: "Company/getInvestmentPreferenceDetails",
            params: { preference_id: id },
            token: cookies.t,
          });
          dispatch(setLoading(false));
        } else if (investmentType === "people") {
          dispatch(setLoading(true));
          res = await getPeopleInvestmentDataById(id);
          dispatch(setLoading(false));
        }

        // console.log("res", res);
        setInvestmentData(res[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isMounted.current) {
      fetchData();
      isMounted.current = false;
    }
  }, [id, investmentType, cookies.t]); // Make sure to include all dependencies

  return (
    <div
      className="investment-preference-view"
      id="Investment-Preference-Container"
    >
      {/* <PageHeader title="View Investment Preference" /> */}
      <div className="h1-j">View Investment Preference</div>

      <div className="inv-pref-container">
        <div className="inv-pref-product">
          <label htmlFor="">Product Type</label>
          <p className="view-muted">{investmentData?.type_name?.[0] || "--"}</p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Product Structure</label>
          <p className="view-muted">
            {investmentData?.structure_name?.join(", ") || "--"}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Product Strategy</label>
          <p>
            {investmentData?.strategy_name
              ? [investmentData?.strategy_name].join(", ")
              : "--"}
          </p>
        </div>
        <div className="inv-pref-product">
          <label htmlFor="">Product Geography</label>
          <p>
            {investmentData?.geography_name
              ? [investmentData?.geography_name].join(", ")
              : "--"}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Product Sector</label>
          <p>
            {investmentData?.sector_name
              ? [investmentData?.sector_name].join(", ")
              : "--"}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Track Length Record</label>
          <p>
            {investmentData?.min_track_record}{" "}
            {investmentData?.min_track_record &&
              investmentData?.max_track_record &&
              "-"}
            {investmentData?.max_track_record}{" "}
            {(investmentData?.min_track_record ||
              investmentData?.max_track_record) &&
              "years"}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">AUM Threshold($mm)</label>
          <p>
            {investmentData?.min_aum}{" "}
            {investmentData?.min_aum && investmentData?.max_aum && "-"}
            {investmentData?.max_aum}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Average Ticket Size (US$MM)</label>
          <p>{investmentData?.average_ticket_size || "--"}</p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">Seed</label>
          <p className="view-muted">
            {investmentData?.seed ? (
              <TypeDiv data="Yes" color="#83BF6E" style={{ color: "#fff" }} />
            ) : (
              <TypeDiv data="No" color="#FFB7B7" style={{ color: "#1A1D1F" }} />
            )}
          </p>
        </div>
        <div className="inv-pref-product">
          <label htmlFor="">Will look at pre-launch funds?</label>
          <p className="view-muted">
            {investmentData?.prelaunch ? (
              <TypeDiv data="Yes" color="#83BF6E" style={{ color: "#fff" }} />
            ) : (
              <TypeDiv data="No" color="#FFB7B7" style={{ color: "#1A1D1F" }} />
            )}
          </p>
        </div>

        <div className="inv-pref-product">
          <label htmlFor="">First Loss Capital</label>
          <p className="view-muted">
            {investmentData?.first_loss_capital ? (
              <TypeDiv data="Yes" color="#83BF6E" style={{ color: "#fff" }} />
            ) : (
              <TypeDiv data="No" color="#FFB7B7" style={{ color: "#1A1D1F" }} />
            )}
          </p>
        </div>

        {/* <div className="inv-pref-product">
                <div className="view-muted">
                <label htmlFor=""></label>
                <p>{investmentData?.seed && (
                      <TypeDiv
                        data="Seed"
                        given_color="#3D9BE9"
                        style={{ color: "#fff" }}
                      />
                    )}</p>
                    <p>{investmentData?.prelaunch && (
                      <TypeDiv
                        data="Pre Launch"
                        given_color="#3D9BE9"
                        classes="ms-1"
                        style={{ color: "#fff" }}
                      />
                    )}</p>
                </div>
                
            </div> */}
      </div>

      <footer className="footer">
        <button
          type="button"
          className={`btn btn-outline-dark px-4 mt-0 `}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </footer>
    </div>
  );
};

export default ViewInvestorPreference;
