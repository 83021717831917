import { FieldArray } from "formik";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services";
import {
  getStrategyById,
  getStructureById,
  getSubStrategyById,
  getTypeById,
} from "../../../../../../services/editHelper";
import SelectDropdown from "../../../../../atoms/Dropdown";
import InputLayout from "../../../../../atoms/InputLayout";

// Modified fetchData function
const fetchData = async (
  fetchFunction,
  option,
  setData,
  index = null,
  subIndex = null
) => {
  const data = await fetchFunction(option);
  setData((prevData) => {
    return produce(prevData, (draft) => {
      if (index !== null) {
        if (!draft[index]) draft[index] = [];
        if (subIndex !== null) {
          if (!draft[index][subIndex]) draft[index][subIndex] = [];
          draft[index][subIndex] = data;
        } else {
          draft[index] = data;
        }
      } else {
        draft.push(data);
      }
    });
  });
};

const ProductTaxonomy = ({ setFieldValue, values, initialValues }) => {
  const [cookies] = useCookies(["t"]);
  const [structure, setStructure] = useState([]);
  const [type, setType] = useState([]);
  const [strategy, setStrategy] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subStrategies, setSubStrategies] = useState([]);
  const [hasInitialized, setHasInitialized] = useState(false);

  const fetchCategoryData = useCallback(async () => {
    const res = await getData({
      endpoint: "Taxonomy/getCategories",
      params: {},
      token: cookies.t,
    });
    setCategoryData(res);
  }, [cookies.t]);

  useEffect(() => {
    fetchCategoryData();
  }, [fetchCategoryData]);

  // Initialization logic for fetching data based on initialValues
  useEffect(() => {
    if (
      initialValues?.name &&
      initialValues?.taxonomy?.length &&
      !hasInitialized
    ) {
      initialValues.taxonomy.forEach((tax, index) => {
        fetchData(getStructureById, tax.product_category, setStructure, index);
        fetchData(getTypeById, tax.product_structure, setType, index);
        fetchData(getStrategyById, tax.product_type, setStrategy, index);

        tax.product_strategy_sub_strategy.forEach((subStrat, stratIndex) => {
          fetchData(
            getSubStrategyById,
            subStrat.product_strategy,
            setSubStrategies,
            index,
            stratIndex
          );
        });
      });
      setHasInitialized(true);
    }
  }, [initialValues, hasInitialized]);

  const dataObj = {
    product_category: null,
    product_structure: null,
    product_type: null,
    product_strategy_sub_strategy: [
      { product_strategy: null, product_sub_strategy: [] },
    ],
  };

  return (
    <FieldArray name="taxonomy">
      {({ push, remove }) => (
        <>
          <div className="product-taxonomy-accordion">
            {values.taxonomy?.map((taxonomy, taxIndex) => (
              <div key={taxIndex} className="accordion mt-4">
                <div
                  className="accordion-item"
                  style={{
                    position: "relative",
                    marginBottom: "0px !important",
                  }}
                >
                  <h2
                    className="accordion-header"
                    id={`heading${taxIndex}`}
                    style={{ display: "flex" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <h4
                        className="normal-heading"
                        style={{
                          marginLeft: "25px",
                          minWidth: "max-content",
                          marginBottom: "0",
                        }}
                      >
                        {`Product Taxonomy ${taxIndex + 1}`}
                      </h4>
                      {taxIndex === 0 && (
                        <span
                          className="cursor-pointer blue-div add-feild inv-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            push(dataObj);
                          }}
                        >
                          Add
                        </span>
                      )}
                      {taxIndex !== 0 && (
                        <span
                          className="cursor-pointer error-div remove-feild inv-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            remove(taxIndex);
                            const removedId = values.taxonomy[taxIndex].id;
                            if (removedId) {
                              setFieldValue("remove_taxonomy_ids", [
                                ...values.remove_taxonomy_ids,
                                removedId,
                              ]);
                            }
                          }}
                        >
                          Remove
                        </span>
                      )}
                    </div>
                    <button
                      className={`accordion-button ${
                        taxIndex === 0 ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${taxIndex}`}
                    ></button>
                  </h2>
                  <div
                    id={`collapse${taxIndex}`}
                    className={`accordion-collapse collapse ${
                      taxIndex === 0 ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <div className="p-1">
                        <div className="row g-3">
                          <InputLayout>
                            <label className="required-field">
                              Product Category
                            </label>
                            <SelectDropdown
                              data={categoryData}
                              onChange={(option) => {
                                fetchData(
                                  getStructureById,
                                  option,
                                  setStructure,
                                  taxIndex
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_category`,
                                  option
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_structure`,
                                  null
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_type`,
                                  null
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_strategy_sub_strategy`,
                                  [
                                    {
                                      product_strategy: null,
                                      product_sub_strategy: [],
                                    },
                                  ]
                                );
                              }}
                              placeholder="Select Product Category"
                              selectedValue={taxonomy.product_category}
                            />
                          </InputLayout>

                          <InputLayout>
                            <label className="required-field">
                              Product Structure
                            </label>
                            <SelectDropdown
                              data={structure[taxIndex] || []}
                              onChange={(option) => {
                                fetchData(
                                  getTypeById,
                                  option,
                                  setType,
                                  taxIndex
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_structure`,
                                  option
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_type`,
                                  null
                                );
                              }}
                              placeholder="Select Product Structure"
                              selectedValue={taxonomy.product_structure}
                            />
                          </InputLayout>

                          <InputLayout>
                            <label className="required-field">
                              Product Type
                            </label>
                            <SelectDropdown
                              data={type[taxIndex] || []}
                              onChange={(option) => {
                                fetchData(
                                  getStrategyById,
                                  option,
                                  setStrategy,
                                  taxIndex
                                );
                                setFieldValue(
                                  `taxonomy[${taxIndex}].product_type`,
                                  option
                                );
                              }}
                              placeholder="Select Product Type"
                              selectedValue={taxonomy.product_type}
                            />
                          </InputLayout>
                        </div>

                        {/* Product Strategy and Sub Strategy */}
                        <FieldArray
                          name={`taxonomy[${taxIndex}].product_strategy_sub_strategy`}
                        >
                          {({ push: strategyPush, remove: strategyRemove }) => (
                            <>
                              {taxonomy.product_strategy_sub_strategy?.map(
                                (subStrat, stratIndex) => (
                                  <div
                                    key={stratIndex}
                                    className="mb-3 row g-3 mt-3"
                                  >
                                    <InputLayout>
                                      <label>Product Strategy</label>
                                      <SelectDropdown
                                        data={strategy[taxIndex] || []}
                                        onChange={(option) => {
                                          fetchData(
                                            getSubStrategyById,
                                            option,
                                            setSubStrategies,
                                            taxIndex,
                                            stratIndex
                                          );
                                          setFieldValue(
                                            `taxonomy[${taxIndex}].product_strategy_sub_strategy[${stratIndex}].product_strategy`,
                                            option
                                          );
                                        }}
                                        placeholder="Select Product Strategy"
                                        selectedValue={
                                          subStrat.product_strategy
                                        }
                                      />
                                    </InputLayout>

                                    <InputLayout>
                                      <label>Product Sub Strategy</label>
                                      <SelectDropdown
                                        data={
                                          subStrategies[taxIndex]?.[
                                            stratIndex
                                          ] || []
                                        }
                                        onChange={(option) => {
                                          setFieldValue(
                                            `taxonomy[${taxIndex}].product_strategy_sub_strategy[${stratIndex}].product_sub_strategy`,
                                            option
                                          );
                                        }}
                                        placeholder="Select Product Sub Strategy"
                                        selectedValue={
                                          subStrat.product_sub_strategy
                                        }
                                        onClear={() =>
                                          setFieldValue(
                                            `taxonomy[${taxIndex}].product_strategy_sub_strategy[${stratIndex}].product_sub_strategy`,
                                            null
                                          )
                                        }
                                        multiSelect
                                      />
                                    </InputLayout>
                                    <InputLayout>
                                      <div className="d-flex add-remove-container">
                                        {stratIndex === 0 && (
                                          <div
                                            onClick={() =>
                                              strategyPush({
                                                product_strategy: null,
                                                product_sub_strategy: [],
                                              })
                                            }
                                            className="add-div"
                                          >
                                            <span className="add-feild">
                                              Add
                                            </span>
                                          </div>
                                        )}

                                        {stratIndex !== 0 && (
                                          <div
                                            onClick={() =>
                                              strategyRemove(stratIndex)
                                            }
                                            className="remove-div"
                                          >
                                            <span className="remove-feild">
                                              Remove
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </InputLayout>
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default ProductTaxonomy;
