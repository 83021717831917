import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { postData } from "../../../services";
import Copy from "../../Icons/Copy";
import CustomButton from "../../atoms/CustomeButton";
import Error from "../../atoms/Error";
import "./styles.scss";

const ShareLink = ({
  roomData,
  handleShareLinkEmail,
  generateURL,
  setGenerateURL,
  isCopy,
  modalId,
  // expiry
  handleCopyUrl,
  setExpiry,
  name,
  module,
  company_id,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t"]);
  const [expiryDays, setExpriryDays] = useState();
  const [errorInput, setErrorInput] = useState(false);
  const [isShare, setIsShare] = useState(true);

  const sharedBase = process.env.REACT_APP_USER_BASE_URL;

  const handleGenrateLink = async (e) => {
    // debugger;
    e.preventDefault();
    // dispatch(setLoading(true));
    const res = await postData({
      endpoint: "ContentSharing/generateLink",
      params: {
        user_role: "Customer",
        name: name,
        module: "Blog",
        base_link: `${sharedBase}view-${module?.toLowerCase()}?id=${id}`,
        days: expiryDays,
        type: module,
        company_id: company_id,
      },
      token: cookies.t,
    });
    // dispatch(setLoading(false));
    setIsShare(false);
    setGenerateURL(res?.data?.data);
    setExpiry(expiryDays);
  };

  return (
    <>
      <div className="shareLinkWrap" key={modalId}>
        <div className="shareLink">
          Access will expire in set days (max 365 days)
          <div className="inputFiledWrap">
            <div className="inputFiled">
              <input
                id="primealphainputId"
                type="number"
                className="daysInput"
                autoComplete="off"
                value={expiryDays}
                onChange={(event) => {
                  setExpriryDays(event.target.value);
                  if (event.target.value > 365) {
                    setErrorInput(true);
                  } else {
                    setErrorInput(false);
                  }
                }}
                disabled={generateURL}
              />
              {expiryDays > 1 ? "days" : "days"}
            </div>
            <div></div>
          </div>
          {errorInput && <Error error="Max 365 days" />}
        </div>
        <div className="generate-link-container">
          <div
            className="linkAndIcon"
            style={{ width: generateURL ? "100%" : "77%" }}
          >
            <label id="primealphaurl" style={{ marginBottom: "0" }}>
              {generateURL ? generateURL?.link : sharedBase}
            </label>
            <i onClick={handleCopyUrl} className="cursor-pointer">
              <Copy />
            </i>
          </div>

          {!generateURL && (
            <CustomButton
              text="Generate Link"
              handleClick={(e) => {
                handleGenrateLink(e);
              }}
              type="btn-primary btn-sm"
              disabled={!expiryDays}
            />
          )}
        </div>
        <div
          style={{ display: generateURL?.link ? "flex" : "none" }}
          className="subShareLink-container"
        >
          <div className="subShareLink">
            Anyone with this link is <strong>not required</strong> to submit
            user information to view content.
          </div>
          <div className="genratedButton">
            <CustomButton
              text="Share"
              handleClick={() => {
                handleShareLinkEmail();
              }}
              type="btn-primary"
              disabled={isShare}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ShareLink;
