// components/Loader.js
import { useContext } from "react";
import { useCookies } from "react-cookie";
import { LoadingContext } from ".";
import CloseButton from "../../components/atoms/CloseButton";
import { deleteData } from "../../services";
import "./Loader.css"; // Create your CSS file for styling

const Loader = () => {
  const [cookies] = useCookies(["t"]);
  const {
    isLoading,
    startLoading,
    stopLoading,
    fileName,
    setFileName,
    inProcess,
  } = useContext(LoadingContext);

  const removeDataRoomItem = async () => {
    const id = localStorage.getItem("in-process");

    stopLoading();

    if (id) {
      const res = await deleteData({
        endpoint: "Product/deleteDataRoomData",
        token: cookies.t,
        params: { data_id: id, user_role: "Customer" },
      });

      localStorage.removeItem("in-process");
    }
  };

  // const isLoading = true;
  // const isPaused = true;

  return (
    <>
      {isLoading && (
        <div className="upload-loader-container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="h4-j">
              Video {inProcess ? "Uploaded" : "Uploading"}
            </div>

            <div
              className="cursor-pointer"
              onClick={() => removeDataRoomItem()}
            >
              <CloseButton />
            </div>
          </div>
          <hr className="mt-2" />

          <div className="d-flex uploading-info">
            {inProcess ? (
              <p className="p p16">Processing Video...</p>
            ) : (
              <p className="p p16">{fileName || "Video"}</p>
            )}
            <span
              className="spinner-border spinner-border-sm ms-2 text-secondary"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
