import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { postData } from "../../../services";
import FormHeading from "../../atoms/FormHeading";
import ResetPasswordForm from "../../organisms/forms/authForms/ResetPasswordForm";
import "./reset.scss";

const ResetPassword = () => {
  const location = useLocation();
  const [linkExpired, setLinkExpired] = useState(false);

  const type =
    window.location.pathname === "/pass/reset-password" ? "reset" : "create";

  // console.log("type", type);

  // Create a URLSearchParams object with the query parameters
  const params = new URLSearchParams(location.search);

  // Extract the user_id and expiry values
  const userId = params.get("user_id");
  const token = params.get("token");
  const expiry = new Date(params.get("expiry")); // Type assertion since get returns string | null

  const checkValidity = async () => {
    const res = await postData({
      endpoint: "CustomerAuth/checkLinkIsExpired",
      data: {},
      params: { user_id: userId },
    });

    if (res) {
      if (res?.data?.response == 400) {
        setLinkExpired(true);
      }
    }
  };

  useEffect(() => {
    checkValidity();
  }, []);

  // Compare the current time with the expiry time
  const currentTime = new Date();

  // Conditionally render the Form component
  const showForm = currentTime < expiry && !linkExpired;

  return (
    <div className="reset-contain">
      {showForm ? (
        <>
          <FormHeading
            title={
              type === "reset" ? "Reset Your Password" : "Create Your Password"
            }
            description="Enter your new password and confirm the new password."
            type="center"
          />
          <ResetPasswordForm uid={userId} token={token} expiry={expiry} />
        </>
      ) : (
        <FormHeading
          title="Link Expired"
          description="This link has already been expired. Please generate a new link."
          type="center"
        />
      )}
    </div>
  );
};

export default ResetPassword;
