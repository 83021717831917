// Loader.tsx
import React from "react";
import "./loader.scss";

const Loader: React.FC = () => {
  return (
    <div className="loaderContainer">
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export const SmallLoader = () => {
  return (
    <div>
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="0 0 50 50">
            {/* Adjust the radius (r) to reduce the size */}
            <circle
              className="path"
              cx="25"
              cy="25"
              r="15"
              fill="none"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export const ExtraSmallLoader = () => {
  return (
    <div className="xs-loader">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
