import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const StrategyComponent = ({ setFieldValue, values }) => (
  <>
    <QuillEditor
      label="Overview"
      placeholderText="Enter Overview"
      onChange={(content) => setFieldValue("add_startegy.overview", content)}
      value={values.add_startegy.overview}
      type=""
      hp="80%"
      height="300px"
    />
    <QuillEditor
      label="Investment Universe"
      placeholderText="Investment Universe"
      onChange={(content) =>
        setFieldValue("add_startegy.investment_universe", content)
      }
      value={values.add_startegy.investment_universe}
      type=""
      hp="80%"
      height="300px"
    />
    <QuillEditor
      label="Differentiators/Value Proposition"
      placeholderText="Enter Differentiators/Value Proposition"
      onChange={(content) =>
        setFieldValue("add_startegy.differentiators", content)
      }
      value={values.add_startegy.differentiators}
      type=""
      hp="80%"
      height="300px"
    />
    <QuillEditor
      label="Risk and Return Drivers"
      placeholderText="Enter Risk and Return Drivers"
      onChange={(content) =>
        setFieldValue("add_startegy.risk_and_return_drivers", content)
      }
      value={values.add_startegy.risk_and_return_drivers}
      type=""
      hp="80%"
      height="300px"
    />
    <QuillEditor
      label="Capacity"
      placeholderText="Enter capacity"
      onChange={(content) => setFieldValue("add_startegy.capacity", content)}
      value={values.add_startegy.capacity}
      type=""
      hp="80%"
      height="300px"
    />
    <QuillEditor
      label="Notes"
      placeholderText="Enter Notes"
      onChange={(content) => setFieldValue("add_startegy.notes", content)}
      value={values.add_startegy.notes}
      type=""
      hp="80%"
      height="300px"
    />
  </>
);

export default StrategyComponent;
