// context/LoadingContext.js
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const FieldsModifiedContext = createContext();

export const FieldsModifiedProvider = ({ children }) => {
  const [number, setNumber] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const [loc, setLoc] = useState(location.pathname);

  useEffect(() => {
    if (number > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [number]);

  useEffect(() => {
    setNumber(0);
  }, [location.pathname]);

  return (
    <FieldsModifiedContext.Provider
      value={{
        number,
        setNumber,
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </FieldsModifiedContext.Provider>
  );
};
