import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { extractLatLongFromGoogleMapsUrl } from "../../../../../../helpers/extraxtLatLong";
import {
  getCityById,
  getCountries,
  getStatesById,
} from "../../../../../../services/editHelper";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../../../atoms/Dropdown";
import Error from "../../../../../atoms/Error";
import InputLayout from "../../../../../atoms/InputLayout";
import { addCompanyAddress, editAddressDetail } from "./addressHelper";

import GoogleSelect from "../../../../../atoms/GoogleSelect";

const AddAddress = ({ id, onClose, onSubmit, initialData, onUpdate }) => {
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();

  // To manage geo Address -------------------------
  const [location, setLocation] = useState();
  const [addressDataList, setAddressDataList] = useState();
  const [addressOptions, setAddressOptions] = useState();
  const [countryData, setCountryData] = useState();
  const [open, setOpen] = useState(false);
  const [stateNameId, setStatenameId] = useState();

  useEffect(() => {
    setLocation(initialData?.address);
  }, [initialData]);

  useEffect(() => {
    if (!open) {
      setAddressOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (addressDataList) {
      const tempAddressData = addressDataList?.map((item) => {
        return {
          label: item?.address,
          value: item?.address,
          latitude: item?.latitude,
          longitude: item?.longitude,
        };
      });
      setAddressOptions(tempAddressData);
    }
  }, [addressDataList]);

  // ------------------------------------------------

  const fetchRequiredData = async (initialData) => {
    const data = await getStatesById(initialData?.country_id);
    setStates(data);
    const sdata = await getCityById(initialData?.state_region_id);
    setCities(sdata);
  };

  const fetchCountries = async () => {
    const res = await getCountries();

    if (res) {
      setCountryData(res);
    }
  };

  useEffect(() => {
    if (initialData) {
      fetchRequiredData(initialData);
    }

    fetchCountries();
  }, [initialData]);
  // declarations of Managing Country , State and City ------------------------------------------------
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // ---------------------------------------------------------------------------------------------------
  const initialValues = {
    id: id,
    country: initialData?.country_id || null,
    state_region: initialData?.state_region_id || null,
    postal_code: initialData?.zip_code || "",
    city: initialData?.city_id || null,
    address: initialData?.address || null,
    landmark: initialData?.landmark || "",
    address2: initialData?.address2 || null,
    city_name: "",
    state_name: initialData?.state_region || null,
    title: initialData?.title || "",
    longitude: initialData?.longitude || null,
    latitude: initialData?.latitude || null,
    address_link: initialData?.address_link || null,
    map_link:
      initialData?.map_link ||
      (!initialData?.address_link &&
        initialData?.longitude &&
        initialData?.latitude &&
        `https://www.maps.google.com?q=${initialData?.latitude},${initialData?.longitude}`) ||
      null,
  };

  const validationSchema = Yup.object({
    country: Yup.string().required("Country is required"),
    state_region: Yup.string().required("State is required"),
    postal_code: Yup.string().required("Postal code is required"),
    city: Yup.string().required("City is required"),
    address: Yup.string(),
    title: Yup.string().required("Title is Required"),
    map_link: Yup.string()
      .matches(
        /^(https?:\/\/)?(www\.)?((maps\.)?google\.com\/maps\/(\?q=.*))?/,
        "Please enter a valid Google Maps link."
      )
      .nullable(),
    address_link: Yup.string().nullable(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (initialData) {
      try {
        dispatch(setLoading(true));
        const resp = await editAddressDetail(
          { ...values, id: initialData.address_id, user_role: "Customer" },
          cookies.t
        );
        if (resp) {
          dispatch(setLoading(false));
          toast.success("Address Updated SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });
          onClose();
          onUpdate();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        dispatch(setLoading(true));
        const resp = await addCompanyAddress(
          { ...values, company_id: cookies.cid, user_role: "Customer" },
          cookies.t
        );
        if (resp) {
          dispatch(setLoading(false));
          toast.success("Address Added SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });
          onClose();
          onUpdate();
        }
      } catch (error) {
        console.log(error);
      }
      resetForm();
    }
  };

  const handleMapView = (values) => {
    window.open(
      "https://maps.google.com?q=" + values?.latitude + "," + values?.longitude
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur
      validateOnChange
      key={id}
    >
      {({
        values,
        dirty,
        isValid,
        errors,
        handleChange,
        // isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <div className="row g-3">
            <InputLayout>
              <label htmlFor="address" className="required-field">
                Address Name
              </label>
              <Field
                type="text"
                id="title"
                name="title"
                placeholder="Eg. Head Office, Branch etc"
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="country" className="required-field">
                Country
              </label>
              <SelectDropdown
                onChange={async (option) => {
                  setFieldValue("country", option.value);
                  const data = await getStatesById(option.value);
                  setFieldValue("state_region", null);
                  setFieldValue("city", null);
                  setFieldValue("city_name", null);
                  setFieldValue("address", null);
                  setAddressOptions(null);
                  setAddressDataList(null);
                  setStates(data);
                  setCities(null);
                }}
                format="all"
                onClear={() => {
                  setFieldValue("country", null);
                  setFieldValue("state_region", null);
                  setFieldValue("city", null);
                }}
                placeholder="Select Country"
                data={countryData}
                selectedValue={initialData?.country_id}
              />
            </InputLayout>
          </div>

          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="state_region" className="required-field">
                State/Region
              </label>
              <SelectDropdown
                onChange={async (option) => {
                  setFieldValue("state_region", option.value);
                  setFieldValue("state_name", option.label);
                  const data = await getCityById(option.value);
                  // console.log(data);
                  setFieldValue("city", null);
                  setFieldValue("address", null);
                  setCities(data); // Corrected this line
                  setStatenameId(option);
                }}
                onClear={() => {
                  setFieldValue("state_region", null);
                  setFieldValue("city", null);
                }}
                placeholder="Select State"
                format="all"
                data={states}
                selectedValue={initialData?.state_region_id}
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="city" className="required-field">
                City
              </label>
              <SelectDropdown
                onChange={(option) => {
                  // console.log('option',option);
                  setFieldValue("city", option);
                  setFieldValue("city_name", option);
                  setFieldValue("city", option);
                }}
                onClear={() => {
                  setFieldValue("city", null);
                }}
                placeholder="Select City"
                data={cities}
                selectedValue={initialData?.city_id}
              />
            </InputLayout>
          </div>

          <div className="form-group mt-3">
            <label htmlFor="address">Address Line 1</label>
            <Field
              type="text"
              id="address"
              name="address"
              placeholder="Enter Address Line 1 "
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="address2">Address Line 2</label>
            <Field
              type="text"
              id="address2"
              name="address2"
              placeholder="Enter Address Line 2 "
            />
          </div>

          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="postal_code" className="required-field">
                Zip Code
              </label>
              <Field
                type="text"
                id="postal_code"
                name="postal_code"
                placeholder="Enter Zip Code"
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="landmark">Landmark</label>
              <Field
                type="text"
                id="landmark"
                name="landmark"
                placeholder="Enter Landmark"
              />
            </InputLayout>
          </div>

          <label htmlFor="" className="mt-3">
            Fetch Location
          </label>

          <div className="form-group">
            <div className="d-flex gap-2">
              <GoogleSelect
                placeholder="Enter Address"
                selected={initialData?.address_link}
                state={values?.state_name}
                disabled={!values.state_region && !values.city}
                type="address"
                onChange={(value) => {
                  setFieldValue("address_link", value?.value?.address);
                  setFieldValue("longitude", value?.value?.longitude);
                  setFieldValue("latitude", value.value.latitude);
                }}
                classes={values.address_link ? "w-50" : "w-100"}
              />

              {values.address_link && (
                <>
                  <Field
                    id="longitude"
                    name="longitude"
                    placeholder="Longitude"
                    className="w-10"
                    style={{ flex: 0.5 }}
                    disabled
                  />

                  <Field
                    id="latitude"
                    name="latitude"
                    placeholder="Latitude"
                    className="w-10"
                    disabled
                    style={{ flex: 0.5 }}
                  />
                  <div className="d-flex align-items-center jusitify-content-center">
                    <div
                      className="view-more fw-bolder"
                      onClick={() => handleMapView(values)}
                    >
                      View in map
                    </div>
                  </div>
                </>
              )}
            </div>

            <label htmlFor="" style={{ textAlign: "center" }} className="my-2">
              Or
            </label>

            <div className="d-flex gap-2">
              <Field
                type="text"
                id="map_link"
                name="map_link"
                placeholder="Google maps Link "
                onChange={(e) => {
                  // console.log('hi')
                  setFieldValue("map_link", e.target.value);
                  const res = extractLatLongFromGoogleMapsUrl(e.target.value);
                  setFieldValue("longitude", res?.longitude);
                  setFieldValue("latitude", res?.latitude);
                  setFieldValue("address_link", null);
                  handleChange(e);
                }}
                className="w-100"
                style={{ flex: 1 }}
                disabled={values.address_link}
              />

              {values.map_link && (
                <>
                  <Field
                    id="longitude"
                    name="longitude"
                    placeholder="Longitude"
                    className="w-10"
                    style={{ flex: 0.2 }}
                    disabled
                  />

                  <Field
                    id="latitude"
                    name="latitude"
                    placeholder="Latitude"
                    className="w-10"
                    disabled
                    style={{ flex: 0.2 }}
                  />
                  <div className="d-flex align-items-center jusitify-content-center">
                    <div
                      className="view-more fw-bolder"
                      onClick={() => window.open(values.map_link)}
                    >
                      View in map
                    </div>
                  </div>
                </>
              )}
            </div>
            {errors?.map_link && <Error error={errors.map_link} />}
          </div>
          <div className="d-flex justify-content-end mb-2 mt-3">
            <button
              className="btn btn-primary w-30"
              type="submit"
              disabled={initialData ? !isValid : !dirty || !isValid}
            >
              {initialData ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAddress;
