import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/toast";

import { useDispatch } from "react-redux";
import { statusData } from "../../../../../../helpers/staticData";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../../../atoms/Dropdown";
import CustomDropzone from "../../../../../molecules/Dropzone";
import { addCompanyInsight, editInsightDetail } from "./insightHelper";

const validationSchema = Yup.object({
  url: Yup.string().url("Invaid URL").required("Url is required"),
  name: Yup.string().required("Name is required"),
  status: Yup.bool().required(),
});

const AddInsightForm = ({
  initialData,
  onSubmit,
  onClose,
  id,
  onDelete,
  onUpdated,
}) => {
  const { id: pid } = useParams();
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();

  const initialValues = {
    id: initialData ? initialData.id : id,
    name: initialData ? initialData.name : "",
    url: initialData?.url || "",
    description: initialData?.description || "",
    status: initialData?.status || "",
    thumbnail: initialData?.thumbnail || null,
  };

  const handleSubmit = async (values) => {
    if (initialData) {
      try {
        dispatch(setLoading(true));
        const res = await editInsightDetail(
          { ...values, user_role: "Customer" },
          cookies.t
        );
        if (res.status === true) {
          dispatch(setLoading(false));
          toast.success("Insight Updated SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });
          onClose();
          onUpdated();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        dispatch(setLoading(true));
        const res = await addCompanyInsight(
          { ...values, company_id: cookies.cid, user_role: "Customer" },
          cookies.t
        );
        if (res) {
          dispatch(setLoading(false));
          toast.success("Insight Added SuccessFully", {
            duration: 1000,
            style: confimationStyles,
          });

          onClose();
          onUpdated();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      key={id}
    >
      {({ dirty, isValid, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name" className="required-field">
              Name
            </label>
            <Field type="text" id="name" name="name" placeholder="Enter Name" />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="url" className="required-field">
              URL
            </label>
            <Field type="text" id="url" name="url" placeholder="Enter URL" />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="">Thumbnail</label>
            <CustomDropzone
              adType="Image"
              description="Only PNG and JPEG image formats are allowed."
              selectedFile={initialData?.thumbnail}
              onFileSelect={(file) => setFieldValue("thumbnail", file)}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="status" className="required-field">
              Status
            </label>
            <SelectDropdown
              placeholder="Select Status"
              onChange={(option) => setFieldValue("status", option)}
              data={statusData}
              selectedValue={initialData?.status}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="url">Description</label>
            <Field
              type="text"
              id="description"
              name="description"
              placeholder="Enter Description"
              as="textarea"
              rows={4}
            />
          </div>

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary px-4 w-100 mt-3"
              type="submit"
              disabled={initialData ? !isValid : !isValid || !dirty}
            >
              {initialData ? "Update" : "Add"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddInsightForm;
