const DotsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_590_682)">
        <path
          d="M1.33333 9.33268C2.06971 9.33268 2.66667 8.73572 2.66667 7.99935C2.66667 7.26297 2.06971 6.66602 1.33333 6.66602C0.596954 6.66602 0 7.26297 0 7.99935C0 8.73572 0.596954 9.33268 1.33333 9.33268Z"
          fill="#1A1D1F"
        />
        <path
          d="M8.00008 9.33268C8.73646 9.33268 9.33341 8.73572 9.33341 7.99935C9.33341 7.26297 8.73646 6.66602 8.00008 6.66602C7.2637 6.66602 6.66675 7.26297 6.66675 7.99935C6.66675 8.73572 7.2637 9.33268 8.00008 9.33268Z"
          fill="#1A1D1F"
        />
        <path
          d="M14.6666 9.33268C15.403 9.33268 15.9999 8.73572 15.9999 7.99935C15.9999 7.26297 15.403 6.66602 14.6666 6.66602C13.9302 6.66602 13.3333 7.26297 13.3333 7.99935C13.3333 8.73572 13.9302 9.33268 14.6666 9.33268Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_682">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DotsIcon;
