const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1268_21720)">
        <path
          d="M9.333 16C9.18875 16 9.04839 15.9532 8.93299 15.8667L6.26633 13.8667C6.18353 13.8046 6.11633 13.724 6.07004 13.6315C6.02376 13.5389 5.99966 13.4368 5.99966 13.3333V9.58667L1.32233 4.32467C0.990142 3.94992 0.773248 3.48721 0.697715 2.99216C0.622182 2.4971 0.691224 1.99077 0.896544 1.53401C1.10186 1.07725 1.43472 0.689506 1.85512 0.417377C2.27551 0.145249 2.76555 0.000316279 3.26633 0L12.733 0C13.2337 0.000587367 13.7237 0.14576 14.1439 0.418071C14.5641 0.690383 14.8967 1.07825 15.1018 1.53506C15.3069 1.99187 15.3758 2.49818 15.3 2.99316C15.2243 3.48815 15.0073 3.95073 14.675 4.32533L9.99966 9.58667V15.3333C9.99966 15.5101 9.92942 15.6797 9.8044 15.8047C9.67938 15.9298 9.50981 16 9.333 16ZM7.333 13L8.66633 14V9.33333C8.66646 9.1701 8.72648 9.0126 8.835 8.89067L13.6803 3.43933C13.8419 3.25672 13.9473 3.03136 13.984 2.7903C14.0206 2.54924 13.9869 2.30274 13.8868 2.08037C13.7868 1.85801 13.6247 1.66924 13.4201 1.53673C13.2154 1.40421 12.9768 1.33359 12.733 1.33333H3.26633C3.02263 1.3337 2.78421 1.40436 2.57966 1.53684C2.37512 1.66932 2.21313 1.858 2.11313 2.08023C2.01313 2.30247 1.97937 2.54884 2.01588 2.78979C2.05239 3.03074 2.15764 3.25604 2.31899 3.43867L7.165 8.89067C7.27327 9.0127 7.33304 9.17019 7.333 9.33333V13Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1268_21720">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default FilterIcon;
