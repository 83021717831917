import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton } from "@mui/material";
import moment from "moment";

// import { useDelete } from "../../../../../../hooks/useDelete";
import { useDispatch } from "react-redux";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import EditBlue from "../../../../../Icons/EditBlue";
import View from "../../../../../Icons/View";
import WorkIcon from "../../../../../Icons/Work";
import IconCircle from "../../../../../atoms/IconCircle";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";
import "./edit.scss";

const Education = ({ type, token }) => {
  const dispatch = useDispatch();
  const { id, added_id, company_id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const [education, setEducation] = useState();

  const fetchEducation = useCallback(async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/getAllPeopleEducation",
      params: {
        page: currentPage,
        page_limit: itemsPerPage,
        people_id: id || added_id,
      },
      token: token,
    });
    dispatch(setLoading(false));

    setEducation(res);
  }, [currentPage, itemsPerPage, id, added_id, token]);

  useEffect(() => {
    fetchEducation();
  }, [fetchEducation]);

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  // const deleteEducaation = useDelete();

  // const handleDelete = (data) => {
  //   setModalState((prevState) => ({
  //     ...prevState,
  //     isDeleteModalOpen: true,
  //     modalId: data.id,
  //     name: data?.degree || "",
  //   }));
  // };

  // const handleDeletion = async () => {
  //   try {
  //     await deleteEducaation("People/deletePeopleEducation", "Education", {
  //       id: modalState.modalId,
  //     });
  //     fetchEducation();
  //     setModalState((prevState) => ({
  //       ...prevState,
  //       isDeleteModalOpen: false,
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //  Pagination management
  const totalItems = education?.total_record;
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {/* {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Education"
          onClick={handleDeletion}
        />
      )} */}

      {education?.data?.length ? (
        <>
          <div className="keyLinkWrap">
            <h4 className="fw-bolder mb-2">Education Detail </h4>
            {type !== "view" && (
              <button
                className={`btn btn-primary px-4 mt-0 d-flex `}
                style={{ gap: "5px" }}
                onClick={() =>
                  navigate(
                    `/dashboard/people/edit/add-education-detail/${
                      id || added_id
                    }`
                  )
                }
              >
                <i className={``}>
                  <Add />
                </i>
                Add Education
              </button>
            )}
          </div>

          {education?.data?.map((edu, index) => (
            <div key={index} className="AddWork-info-div">
              <div className="work-building-wrap">
                <IconCircle
                  color="#F4F4F4"
                  icon={<WorkIcon />}
                  size="54px"
                  iconSize="20px"
                  iconColor="#000000"
                />
                <div>
                  <span className="work-title">{edu.degree}</span>
                  <div className="">
                    <span className="work-company-name company-name-email">
                      {edu?.field_of_study}
                    </span>
                    {edu?.field_of_study && edu?.institute_name && (
                      <span
                        className="emailDot"
                        style={{ backgroundColor: "#000000" }}
                      ></span>
                    )}

                    <span className="work-email company-name-email email-name">
                      {edu?.institute_name}
                    </span>
                  </div>
                  <div>
                    <span className="work-start-date">
                      {edu?.start_date &&
                        moment(edu?.start_date).format("MMM YYYY")}{" "}
                      {edu?.start_date && edu?.end_date && "-"}&nbsp;
                      {edu?.currently_working_here
                        ? "Present"
                        : edu?.end_date &&
                          moment.utc(edu?.end_date).format("MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>

              {type !== "view" && (
                <div className="work-details">
                  <div className="keyLink-edit-remove">
                    <IconButton
                      type="button"
                      onClick={() => {
                        navigate(
                          `/dashboard/people/edit/view-education-detail/${edu?.id}`
                        );
                      }}
                    >
                      <i className="">
                        <View />
                      </i>
                    </IconButton>
                    <IconButton
                      type="button"
                      onClick={() =>
                        navigate(
                          `/dashboard/people/edit/edit-education-detail/${edu?.id}`
                        )
                      }
                    >
                      <i>
                        <EditBlue className="edit-blue-icon" />
                      </i>
                    </IconButton>
                    {/* <IconButton type="button" onClick={() => handleDelete(edu)}>
                      <i className="fi fi-rr-trash actions-icon-custom"></i>
                    </IconButton> */}
                  </div>
                </div>
              )}
            </div>
          ))}

          {totalItems >= 10 && (
            <div className="mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Education Details added yet"
          icon={<WorkIcon />}
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Education Details added yet"
          subtitle="To add Education, click on the add education."
          icon={<WorkIcon />}
          handleClick={() => {
            navigate(
              `/dashboard/people/edit/add-education-detail/${id || added_id}`
            );
          }}
          tag="Add Education"
        />
      )}
    </>
  );
};

export default Education;
