import { Box } from "@mui/material";
import React, { useState } from "react";
import TabStrip from "../../../molecules/TabStrip";
import About from "./components/About";
import InvestmentPreference from "./components/InvestmentPreference";
import Keylinks from "./components/Keylinks";

interface DataProps {
  data: {
    work_experience: any[]; // replace 'any' with the actual type of work_experience
    investment_preference: any[]; // replace 'any' with the actual type of investment_preference
    key_link: any[]; // replace 'any' with the actual type of key_link
    people_overview: { role: null };
  };
}

const Data: React.FC<DataProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState("About Me");

  let dataArray: { id: number; label: string; view: JSX.Element }[] = [];
  dataArray.push({
    id: 0,
    label: "About Me",
    view: <About data={data} />,
  });

  if (
    data?.investment_preference?.length > 0 &&
    data?.people_overview?.role !== "Manager/Sponsor"
  ) {
    dataArray.push({
      id: 2,
      label: "Investment Preference",
      view: <InvestmentPreference data={data} />,
    });
  }

  if (data?.key_link?.length > 0) {
    dataArray.push({
      id: 3,
      label: "Keylinks",
      view: <Keylinks data={data?.key_link} />,
    });
  }

  // if (data?.key_link?.length > 0) {
  //   dataArray.push({
  //     id: 3,
  //     label: "KeyLinks",
  //     view: <Keylinks data={data} />,
  //   });
  // }

  // Always add "About Me" tab

  const _getComponent = (tabs: string) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    setActiveTab(value);
  };

  return (
    <>
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
      />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ pt: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default Data;
