import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../../services";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";

const LibraryCatalog = ({ onClick, accessAllow }) => {
  const [filterData, setFilterData] = useState();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const getFilters = async () => {
    try {
      const res = await getData({
        endpoint: "Blog/getMappedCategory",
        params: { type: "Library" },
      });
      setFilterData(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  const handleItemClick = (item) => {
    // console.log(item);
    if (accessAllow) {
      navigate(`/dashboard/library/${item?.label}`);
    } else {
      window.location.href = userInfo?.product_learn_more_link;
    }
  };

  return (
    <div onClick={() => onClick()} className={styles.lib_cat_div}>
      <h1>Libary Catalogue</h1>
      <div className={styles.lib_cat_search_items}>
        {filterData?.map((item, index) => {
          return (
            <button key={index} onClick={() => handleItemClick(item)}>
              {item?.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LibraryCatalog;
