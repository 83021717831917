import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSubscription } from "../../../../SubscriptionContext";
import ProductImg from "../../../../assets/images/Product.png";
import { confimationStyles } from "../../../../assets/styles/toast";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import EditBlue from "../../../Icons/EditBlue";
import ShareIcon from "../../../Icons/ShareIcon";
import CustomButton from "../../../atoms/CustomeButton";
import Breadcrumb from "../../../molecules/Breadcrumb";
import Modal from "../../../molecules/Modal/Normal";
import SaveComponent from "../../../molecules/SaveComponent";
import ShareLink from "../../../organisms/ShareFunctionality/ShareLink";
import ShareLinkEmail from "../../../organisms/ShareFunctionality/ShareLinkEmail";
import Data from "./Data";
import "./product.scss";

const Product = ({ type, product_id }) => {
  const location = useLocation();
  const { navigationType } = location?.state || {};
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const [cookies] = useCookies(["t", "cid", "is_admin"]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [generateURL, setGenerateURL] = useState();
  const [isCopy, setIsCopy] = useState(false);
  const [expiry, setExpiry] = useState(false);

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    isViewModalOpen: false,
  });
  const [productData, setProductData] = useState({
    product_configuration: [
      {
        name: "",
        company_name: "",
        company_id: "",
        type_name: "",
        aum_amount: "",
        aum_month_year: "",
        saved_list: "",
      },
    ],
    company_id: "",
  });

  // Sharing
  const handleShare = (data) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      FormData: data,
      isModalOpen: true,
    }));
  };

  const handleShareLinkEmail = () => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      isViewModalOpen: true,
    }));
  };

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphaurl")?.innerHTML;

    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  const getProductData = async () => {
    setIsLoading(true);
    dispatch(setLoading(true));
    try {
      const res = await getData({
        endpoint:
          type === "shared"
            ? "Product/customer/shared/getProductDetails"
            : "Product/customer/getProductProfileDetails",
        params: { product_id: product_id || id },
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setProductData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, [id]);

  const breadcrumbItems = [
    {
      label: productData?.product_configuration[0]?.company_name,
      url: navigationType === "other" ? "other" : "company",
      id: productData?.product_configuration[0]?.company_id,
    },
    {
      label: productData?.product_configuration[0]?.name,
      active: true,
    },
  ];

  return (
    <div
      id="Product-Info-Containter"
      className="product-container"
      style={{ padding: type === "shared" && 0 }}
    >
      {type !== "shared" && (
        <Breadcrumb
          items={breadcrumbItems}
          style={{ padding: "10px 15px 0 0" }}
        />
      )}

      <div className="product-header">
        <div className="info-part">
          <img src={ProductImg} alt="" className="product-image" />
          <div>
            <h2 className="h2-i semi-light">
              {productData?.product_configuration[0]?.name}
            </h2>
            <p className="p mb-0">
              {productData?.product_configuration[0]?.taxonomy
                ?.map((item) => item.type_name)
                .join(", ")}
              {productData?.product_configuration[0]?.aum_amount && (
                <>
                  &nbsp;$
                  {productData?.product_configuration[0]?.aum_amount
                    ? Number(
                        productData.product_configuration[0].aum_amount
                      ).toLocaleString("en-US")
                    : null}
                  {productData?.product_configuration[0]?.aum_month_year && (
                    <>
                      &nbsp; (as of{" "}
                      {moment
                        .utc(
                          productData?.product_configuration[0]?.aum_month_year
                        )
                        .format("MMM YYYY") || "--"}
                      )
                    </>
                  )}
                </>
              )}
            </p>
          </div>
        </div>

        {type !== "shared" && (
          <div className="btn-container">
            {(userInfo?.has_master_access ||
              (productData?.company_id === cookies.cid &&
                userInfo?.is_admin)) && (
              <CustomButton
                type="btn-outline-dark"
                classes="Edit-black-icon-container"
                handleClick={(e) => {
                  // isSubscriptionExpired
                  //   ? handleUserClick(e)
                  //   :
                  handleShare(productData?.product_configuration[0]);
                }}
                text="Share"
                icon={<ShareIcon />}
              />
            )}

            {productData?.company_id !== cookies.cid ? (
              <>
                <SaveComponent
                  type="Product"
                  id={id}
                  saved_id={productData?.product_configuration[0]?.saved_list}
                />
              </>
            ) : userInfo?.is_admin ? (
              <CustomButton
                type="btn-outline-primary"
                classes="Edit-blue-icon-container"
                handleClick={(e) => {
                  // isSubscriptionExpired
                  //   ? handleUserClick(e)
                  //   :
                  navigate(`/dashboard/product/edit/${id}`);
                }}
                text="Edit"
                icon={<EditBlue className="Edit-blue-icon" />}
              />
            ) : null}
          </div>
        )}
      </div>

      <div className="mt-4">
        <Data
          data={productData}
          company_id={productData?.product_configuration[0]?.company_id}
          product_id={product_id}
          type={type}
        />
      </div>

      <>
        {modalState.isModalOpen && (
          <Modal
            id={modalState.modalId}
            isActive={modalState.isModalOpen}
            title={modalState.title}
            width="642px"
            onClose={() => {
              setModalState((prev) => ({
                ...prev,
                isModalOpen: false,
              }));
              setGenerateURL(null);
            }}
            bodyStyle={{ padding: "24px" }}
          >
            <ShareLink
              id={modalState.modalId}
              name={modalState.FormData?.header || modalState.FormData?.name}
              roomData={modalState.FormData}
              handleShareLinkEmail={handleShareLinkEmail}
              generateURL={generateURL}
              setGenerateURL={setGenerateURL}
              setExpiry={setExpiry}
              isCopy={isCopy}
              setIsCopy={setIsCopy}
              handleCopyUrl={handleCopyUrl}
              module="Product"
              type="Product"
              company_id={productData?.product_configuration[0]?.company_id}
            />
          </Modal>
        )}

        {modalState.isViewModalOpen && (
          <Modal
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            title={modalState.title}
            width="642px"
            onClose={() =>
              setModalState((prev) => ({
                ...prev,
                isViewModalOpen: false,
              }))
            }
          >
            <ShareLinkEmail
              generateURL={generateURL}
              isCopy={isCopy}
              handleCopyUrl={handleCopyUrl}
              setModalStateEmail={setModalState}
              setGenerateURL={setGenerateURL}
              onClose={() =>
                setModalState((prev) => ({
                  ...prev,
                  isViewModalOpen: false,
                }))
              }
              expiry={expiry}
              module="Product"
              type="Product"
            />
          </Modal>
        )}
      </>

      {type !== "shared" && (
        <footer className="footer mt-auto">
          <div className="d-flex justify-content-end me-2">
            <button
              type="button"
              className={`btn btn-outline-dark px-4 mt-0 `}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "auto" });
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Product;
