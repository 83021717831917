import React from "react";
import "./selectorDiv.scss";

interface SelectorItem {
  id: number;
  label: string;
}

interface SelectorDivProps {
  activeTab: string;
  onTabChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string
  ) => void;
  data: SelectorItem[];
}

const SelectorDiv: React.FC<SelectorDivProps> = ({
  activeTab,
  onTabChange,
  data,
}) => {
  return (
    <div className="select-div hor-scroll-none">
      {data?.map((item) => (
        <button
          key={item.id}
          className={`selector-button ${
            item.label === activeTab ? "selector-active" : ""
          }`}
          onClick={(e) => onTabChange(e, item.label)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default SelectorDiv;
