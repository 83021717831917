import { Form, Formik } from "formik";

import moment from "moment";
import {
  binary,
  eligibleInvestors,
  fundStatus,
  fundTypeOptions,
  redemptionFrequencyOptions,
  subscriptionFrequencyOptions,
} from "../../../../../../helpers/staticData";
import SelectDropdown from "../../../../../atoms/Dropdown";
import InputLayout from "../../../../../atoms/InputLayout";
import { MonthYearSelector } from "../../../../../molecules/MonthYearSelector";

const FundStructureForm = ({ setFieldValue, values, touched, errors }) => {
  return (
    <Formik>
      <Form>
        <div className="row g-3 mt-1">
          <InputLayout>
            <label htmlFor="fund_type">Open/Close Ended</label>
            <SelectDropdown
              data={fundTypeOptions}
              onChange={(option) =>
                setFieldValue("add_details.fund_type", option)
              }
              selectedValue={values.add_details.fund_type}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="funding_status">Funding Status</label>
            <SelectDropdown
              data={fundStatus}
              onChange={(option) =>
                setFieldValue("add_details.funding_status", option)
              }
              selectedValue={values.add_details.funding_status}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="funding_goal">Funding Goal</label>
            <input
              type="number"
              id="funding_goal"
              name="funding_goal"
              placeholder="Enter funding goal"
              step="0.01" // Set the step to 0.01 to allow decimals with two decimal places
              onKeyDown={(e) => {
                // Prevent the input of more than one decimal point
                if (
                  (e.key === "." && e.target.value.includes(".")) ||
                  e.key === "e" ||
                  e.key === "-"
                ) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let value = e.target.value;
                // Limit to one decimal place
                const decimalIndex = value.indexOf(".");
                if (decimalIndex !== -1) {
                  value = value.slice(0, decimalIndex + 2); // Limit to two decimal places
                }
                if (value.length === 0) {
                  setFieldValue("add_details.funding_goal", null);
                } else {
                  setFieldValue("add_details.funding_goal", value);
                }
              }}
              value={values.add_details.funding_goal}
            />
          </InputLayout>
        </div>

        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="add_details.target_close_date">
              Target Close Date
            </label>
            <MonthYearSelector
              placeholder="Select Target Close Date"
              onChange={(date) =>
                setFieldValue(
                  "add_details.target_close_date",
                  moment(`${date}-01`).toISOString()
                )
              }
              disablePast={true}
              selectedDate={values.add_details.target_close_date}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="add_details.eligible_investor">
              Eligible Investors
            </label>
            <SelectDropdown
              data={eligibleInvestors}
              onChange={(option) =>
                setFieldValue("add_details.eligible_investor", option)
              }
              selectedValue={values.add_details.eligible_investor}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Minimum Investment ($USD)</label>
            <input
              type="number"
              id="minimum_investment"
              name="minimum_investment"
              placeholder="Enter Minimum Investment"
              onChange={(e) => {
                const value = parseInt(e.target.value); // Parse input value as integer
                if (isNaN(value)) {
                  // Check if input is not a number
                  setFieldValue("add_details.minimum_investment", null);
                } else {
                  setFieldValue("add_details.minimum_investment", value);
                }
              }}
              value={values.add_details.minimum_investment}
              step="1" // Allow only whole numbers
            />
          </InputLayout>
        </div>

        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="">Management Fee</label>
            <input
              type="number"
              id="management_fee"
              name="management_fee"
              placeholder="Enter Management Fee (In %)"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setFieldValue("add_details.management_fee", null);
                } else {
                  setFieldValue("add_details.management_fee", e.target.value);
                }
              }}
              value={values.add_details.management_fee}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Performance Fee</label>
            <input
              type="number"
              id="performance_fee"
              name="performance_fee"
              placeholder="Enter Performance Fee (In %)"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setFieldValue("add_details.performance_fee", null);
                } else {
                  setFieldValue("add_details.performance_fee", e.target.value);
                }
              }}
              value={values.add_details.performance_fee}
            />
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Hurdle Rate</label>
            <input
              type="number"
              id="hurdle_rate"
              name="hurdle_rate"
              placeholder="Enter Hurdle Rate (In %)"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setFieldValue("add_details.hurdle_rate", null);
                } else {
                  setFieldValue("add_details.hurdle_rate", e.target.value);
                }
              }}
              value={values.add_details.hurdle_rate}
            />
          </InputLayout>
        </div>

        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="highWaterMark">High Water Mark</label>
            <SelectDropdown
              data={binary}
              onChange={(option) =>
                setFieldValue("add_details.high_water_mark", option)
              }
              placeholder="Select High Water Mark"
              selectedValue={values.add_details.high_water_mark}
            />
          </InputLayout>
          <InputLayout>
            <label htmlFor="subscriptionFrequency">
              Subscription Frequency
            </label>
            <SelectDropdown
              data={subscriptionFrequencyOptions}
              onChange={(option) =>
                setFieldValue("add_details.subscription_frequency", option)
              }
              placeholder="Select Subscription Frequency"
              selectedValue={values.add_details.subscription_frequency}
            />
          </InputLayout>
          <InputLayout>
            <label htmlFor="redemption_frequency">Redemtion Frequency</label>
            <SelectDropdown
              data={redemptionFrequencyOptions}
              onChange={(option) =>
                setFieldValue("add_details.redemption_frequency", option)
              }
              placeholder="Select Redemtion Frequency"
              selectedValue={values.add_details.redemption_frequency}
            />
          </InputLayout>
        </div>

        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="">Redemption Notice Period (In Days)</label>
            <input
              type="number"
              id="redemption_notice_period"
              name="redemption_notice_period"
              placeholder="Enter Redemption Notice Period"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setFieldValue("add_details.redemption_notice_period", null);
                } else {
                  setFieldValue(
                    "add_details.redemption_notice_period",
                    e.target.value
                  );
                }
              }}
              value={values.add_details.redemption_notice_period}
            />
          </InputLayout>
          <InputLayout>
            <label htmlFor="">Redemption Penalty </label>
            <input
              type="number"
              id="redemption_penalty"
              name="redemption_penalty"
              placeholder="Enter Redemption Penalty (In %)"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setFieldValue("add_details.redemption_penalty", null);
                } else {
                  setFieldValue(
                    "add_details.redemption_penalty",
                    e.target.value
                  );
                }
              }}
              value={values.add_details.redemption_penalty}
            />
          </InputLayout>
          <InputLayout>
            <label htmlFor="">Lockup Period </label>
            <SelectDropdown
              data={binary}
              onChange={(option) =>
                setFieldValue("add_details.lockup_period", option)
              }
              placeholder="Select Lockup Period"
              selectedValue={values.add_details.lockup_period}
            />
          </InputLayout>
        </div>
      </Form>
    </Formik>
  );
};

export default FundStructureForm;
