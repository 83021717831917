import React from "react";
import ProfilePhoto from "../../../assets/images/ProfilPhoto.png";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./user_profile_card.module.scss";

interface CardProps {
  profilePicture?: string;
  profileName: string;
  profilePosition?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  type?: string;
}

const UserProfileCard: React.FC<CardProps> = ({
  profilePicture,
  profileName,
  profilePosition,
  style,
  type,
  onClick,
}) => {
  const handleViewProfile = () => {
    // Add logic for viewing the profile
    console.log(`Viewing profile of ${profileName}`);
  };

  return (
    <div className={styles.card} style={style}>
      {profilePicture ? (
        <img
          src={profilePicture || ProfilePhoto}
          alt="Profile"
          className={styles.cardImg}
        />
      ) : (
        <CircleAvatar
          name={profileName}
          size="60px"
          fontSize="18px"
          color="#F4F4F4"
          iconColor="#3D9BE9"
          border="1px solid #efefef"
        />
      )}
      <div className={styles.profileName}>{profileName}</div>
      <div
        className={`p mb-2 ${type === "user-team" ? "text-truncate" : ""}`}
        title={profilePosition}
      >
        {profilePosition}
      </div>
      <div className={styles.cardContent}>
        <button
          className={`${styles.viewProfileBtn} btn btn-primary`}
          onClick={onClick}
        >
          View Profile
        </button>
      </div>
    </div>
  );
};

export default UserProfileCard;
