import { useState } from "react";
import { useParams } from "react-router-dom";
import { isUUID } from "../../../../../../../helpers/checkUuid";
import { transformData } from "../../../../../../../helpers/transformData";
import CustomDropzone from "../../../../../../molecules/Dropzone";
import { parseFile } from "./trackAumHelper";

const TrackAumForm = ({ id, onClose, setFieldValue }) => {
  const { id: pid } = useParams();
  const [netReturnFile, setNetReturnFile] = useState(null);
  const [monthlyAUMFile, setMonthlyAUMFile] = useState(null);

  const handleNetReturnFileSelect = (file) => {
    setNetReturnFile(file);
  };

  const handleMonthlyAUMFileSelect = (file) => {
    setMonthlyAUMFile(file);
  };

  const handleAdd = async () => {
    let netReturnTransformedData;
    let monthlyReturnTransformData;

    const parseNetReturnFile = () => {
      return new Promise((resolve) => {
        if (netReturnFile) {
          parseFile(netReturnFile, (parsedData) => {
            netReturnTransformedData = transformData(parsedData, "net");
            resolve();
          });
        } else {
          resolve();
        }
      });
    };

    const parseMonthlyAUMFile = () => {
      return new Promise((resolve) => {
        if (monthlyAUMFile) {
          parseFile(monthlyAUMFile, (parsedData) => {
            monthlyReturnTransformData = transformData(parsedData, "monthly");

            resolve();
          });
        } else {
          resolve();
        }
      });
    };

    // Wait for both parsing operations to complete
    if (netReturnFile && !monthlyAUMFile) {
      await parseNetReturnFile();
    } else if (!netReturnFile && monthlyAUMFile) {
      await parseMonthlyAUMFile();
    } else {
      await Promise.all([parseNetReturnFile(), parseMonthlyAUMFile()]);
    }

    if (isUUID(pid)) {
      if (netReturnTransformedData && monthlyReturnTransformData) {
        setFieldValue("add_net_aum", netReturnTransformedData);
        setFieldValue("net_aum_file", netReturnFile?.name);
        setFieldValue("add_monthly_aum", monthlyReturnTransformData);
        setFieldValue("monthly_aum_file", monthlyAUMFile?.name);
      } else if (netReturnTransformedData) {
        setFieldValue("add_net_aum", netReturnTransformedData);
        setFieldValue("net_aum_file", netReturnFile?.name);
      } else if (monthlyReturnTransformData) {
        setFieldValue("add_monthly_aum", monthlyReturnTransformData);
        setFieldValue("monthly_aum_file", monthlyAUMFile?.name);
      }

      setMonthlyAUMFile(null);
      setNetReturnFile(null);

      onClose();
    }
  };

  return (
    <>
      <div className="form-group mt-3" key={id}>
        <label htmlFor="">Net Returns (%)</label>
        <CustomDropzone
          adType="Excel"
          onFileSelect={handleNetReturnFileSelect}
          type="aum"
          id={id}
        />
      </div>

      <div className="form-group mt-3" key={id + 1}>
        <label htmlFor="">Monthly AUM ($MM)</label>
        <CustomDropzone
          adType="Excel"
          onFileSelect={handleMonthlyAUMFileSelect}
          type="aum"
          id={id + 1}
        />
      </div>

      <div className="d-flex justify-content-end mt-3">
        <button
          className="btn btn-primary w-100"
          onClick={handleAdd}
          disabled={!netReturnFile && !monthlyAUMFile}
        >
          Add
        </button>
      </div>
    </>
  );
};

export default TrackAumForm;
