import React, { ChangeEvent, FocusEvent, useState } from "react";
import styles from "./password.module.scss";

interface PasswordFieldProps {
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  autoComplete?: string;
  className?: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  id,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  autoComplete,
  className,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="form-group">
      <label htmlFor={label}>{label}</label>
      <div
        className={`input-group ${isFocused ? "focused" : ""} ${
          styles.input_group
        }`}
      >
        <input
          type={passwordVisible ? "text" : "password"}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur && onBlur(e);
          }}
          onFocus={() => setIsFocused(true)}
          placeholder={placeholder}
          className={`${styles.input_pass} ${className}`}
          style={{
            borderRight: passwordVisible
              ? 0
              : isFocused
              ? "1px solid #3d9be9 !important"
              : "1px solid #f4f4f4 !important",
          }}
          autoComplete="off"
        />
        {value && value.length > 0 && (
          <div
            className={`input-group-append ${
              isFocused ? styles.focused : ""
            } ${className}`}
          >
            <span
              className={styles.info}
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? "Hide" : "Show"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
