import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductImg from "../../../../../../assets/images/Product.png";
import { getData } from "../../../../../../services";
import FormHeading from "../../../../../atoms/FormHeading";
import Footer from "../../../../../molecules/Footer";
import Navbar from "../../../../../molecules/Navbar";
import Video from "../Video";
import "./styles.scss";

const SharedVideos = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const id = params.get("link_id");
  const video_ids = params.get("id");
  const videoIdArray = video_ids?.split(",");

  const [data, setData] = useState();
  const [isExpired, setIsExpired] = useState(false);

  const getDetails = async () => {
    try {
      const res = await getData({
        endpoint: "Product/shared/getVideosDetailsByIds",
        params: { video_ids: JSON.stringify(videoIdArray) },
      });
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const checkExpiry = async () => {
    const res = await getData({
      endpoint: "ContentSharing/checkLinkExpiry",
      params: {
        link_id: id,
      },
    });

    if (res.response === 200) {
      //   console.log("called");
      setIsExpired(false);
      getDetails();
    } else {
      setIsExpired(true);
    }
  };

  useEffect(() => {
    checkExpiry();
  }, []);
  return (
    <>
      <Navbar />
      <div className="shared_videos_containter">
        {isExpired ? (
          <div className="shared-expired">
            <FormHeading
              title="Link Expired"
              description="This link has already been expired or Revoked"
              type="center"
            />
          </div>
        ) : (
          //   <Product type="view" product_id={product_id} />
          <>
            <div className="d-flex align-items-center gap-3">
              <img src={ProductImg} alt="" className="product-image" />
              <div>
                <h2 className="h2-i semi-light">
                  {data?.product_config?.name}
                </h2>
                <p className="p mb-0">
                  {data?.product_config?.type_name}
                  {data?.product_config?.aum_amount && (
                    <>
                      &nbsp;$
                      {data?.product_config?.aum_amount
                        ? Number(
                            data?.product_config.aum_amount
                          ).toLocaleString("en-US")
                        : null}
                      {data?.product_config?.aum_month_year && (
                        <>
                          &nbsp; (as of{" "}
                          {moment
                            .utc(data?.product_config?.aum_month_year)
                            .format("MMM YYYY") || "--"}
                          )
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="mt-3">
              {data && (
                <Video data={data?.video_details} visibility_type="shared" />
              )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default SharedVideos;
