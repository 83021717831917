import { useDispatch } from "react-redux";
import { getData, patchData, postData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";

export const GeoAutoCompleteAddress = async (params, token) => {
  const dispatch = useDispatch();
  const payload = {
    query: params.inputValue,
    state: params.stateName,
  };
  try {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Wrapper/googleSearchAddress",
      params: payload,
      token: token,
    });
    dispatch(setLoading(false));
    return res;
  } catch (exp) {
    console.log("GoogleAutoComplete", exp);
  }
};

export const addCompanyAddress = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "Company/addCompanyAddress",
      data: data,
      params: {},
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const editAddressDetail = async (data, token) => {
  const res = await patchData({
    endpoint: "Company/editCompanyAddress",
    data: data,
    params: {},
    token: token,
  });
  return res;
};
