import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthenticated } from "../hooks/useAuthenticated";

// Create a context for subscription status
const SubscriptionContext = createContext();

// Custom hook to use the SubscriptionContext
export const useSubscription = () => useContext(SubscriptionContext);

// SubscriptionProvider component to wrap around the App
export const SubscriptionProvider = ({ children }) => {
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [top, setTop] = useState("0px");

  const isAuthenticated = useAuthenticated();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  // Simulate checking subscription status from an API or server
  useEffect(() => {
    const checkSubscription = () => {
      const trial_data_string = localStorage.getItem("trial_info");
      let trial_data;

      if (trial_data_string !== null) {
        // Only parse if the string is not null
        trial_data = JSON.parse(trial_data_string);
      }

      // checking the userInfo object first
      const isUserInfoValid =
        userInfo &&
        typeof userInfo === "object" &&
        userInfo.hasOwnProperty("subscription_enable") &&
        userInfo.hasOwnProperty("subs_end_date");

      if (
        // (!trial_data?.subscription_enable

        //   ||
        //   (Object.keys(userInfo).length > 0 &&
        //     !userInfo?.subscription_enable)) &&
        // (moment(trial_data?.subs_end_date)
        //   .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        //   .isBefore(moment())

        //   ||
        //   (Object.keys(userInfo).length > 0 &&
        //     moment(userInfo?.subs_end_date)
        //       .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        //       .isBefore(moment())))

        !trial_data?.subscription_enable ||
        // when userInfo is not valid
        (!isUserInfoValid &&
          moment(trial_data?.subs_end_date)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isBefore(moment())) ||
        // If userInfo is valid, perform checks based on userInfo
        (isUserInfoValid &&
          userInfo?.subscription_enable === false &&
          moment(userInfo?.subs_end_date)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isBefore(moment()))
      ) {
        setSubscriptionExpired(true);
        setIsShow(false);
      } else {
        setSubscriptionExpired(false);
        setIsShow(false);
      }
      // Replace with actual API call to check subscription status
    };

    if (isAuthenticated) {
      checkSubscription();
    }
  }, [isAuthenticated]);

  // Function to handle user clicks
  const handleUserClick = (e) => {
    if (isSubscriptionExpired) {
      e.preventDefault();
      e.stopPropagation();
      setIsShow(true);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscriptionExpired,
        handleUserClick,
        setIsShow,
        isShow,
        top,
        setTop,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

// Fake API call function to simulate checking subscription status
