// context/LoadingContext.js
import { createContext, useState } from "react";

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [inProcess, setInProcess] = useState(null);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        startLoading,
        stopLoading,
        fileName,
        setFileName,
        inProcess,
        setInProcess,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
