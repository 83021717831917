import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { getData } from "../../../../../../services";
import {
  addProductVideo,
  editProductVideo,
} from "../../../../../../services/productHelper";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Error from "../../../../../atoms/Error";
import InputLayout from "../../../../../atoms/InputLayout";
import { DateSelector } from "../../../../../molecules/MonthYearSelector";
import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const AddVideoForm = ({ id, onClose, initialData, onAddorUpdate }) => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();

  const initialValues = {
    name: initialData?.name || "",
    link: initialData?.link || "",
    date: initialData?.date || "",
    description: initialData?.description || "",
    is_featured: initialData?.featured || false,
    transcript: initialData?.transcript || "",
    time: initialData?.time || null,
    user_role: "Customer",
  };

  const { id: pid } = useParams();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Video Name is required"),
    link: Yup.string()
      .matches(
        /^(https?:\/\/)?(www\.)?(vimeo\.com\/(channels\/\w+\/|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)([?]|$)|player\.vimeo\.com\/video\/(\d+))/,
        "Invalid Vimeo URL"
      )
      .required("Video Link is required"),
    date: Yup.date().required("Date is required"),
    description: Yup.string(),
    transcript: Yup.string(),
  });

  const onSubmit = async (values, { resetForm }) => {
    const dataToSend = {
      ...values,
      product_id: pid,
    };
    try {
      dispatch(setLoading(true));
      const res = await addProductVideo(dataToSend, cookies.t);
      if (res) {
        dispatch(setLoading(false));
        toast.success("Video added successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        resetForm();
        onAddorUpdate();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      id: initialData?.video_id,
    };

    try {
      dispatch(setLoading(true));
      const res = await editProductVideo(data, cookies.t);
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Video Updated successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        onAddorUpdate();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLinkBlur = async (setFieldValue, item) => {
    const vimeoRegex = /https:\/\/vimeo\.com\/(\d+)/;
    const vimeoMatch = item.match(vimeoRegex);

    if (vimeoMatch) {
      try {
        // Get video ID from the URL
        const videoId = item.split("/").pop();

        if (videoId) {
          const response = await getData({
            endpoint: "Wrapper/videoDuration",
            token: cookies.t,
            params: {
              videoId,
            },
          });

          // const duration = data.duration;
          setFieldValue("time", response?.duration);
        }
      } catch (error) {
        console.error("Error fetching video details:", error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      key={id}
    >
      {({
        dirty,
        isValid,
        values,
        setFieldValue,
        handleBlur,
        handleChange,
        touched,
        errors,
      }) => (
        <Form>
          <div className="row g-3">
            <InputLayout>
              <label htmlFor="name" className="required-field">
                Video Name
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Enter Video Name"
                className={`${
                  touched.name && errors.name ? "error-input" : ""
                }`}
              />
              {touched.name && errors.name && <Error error={errors.name} />}
            </InputLayout>

            <InputLayout>
              <label htmlFor="link" className="required-field">
                Video Link
              </label>
              <Field
                type="text"
                id="link"
                name="link"
                placeholder="Enter Video link"
                onBlur={(e) => {
                  handleBlur(e);
                  if (!errors.link) {
                    handleLinkBlur(setFieldValue, values.link);
                  }
                }}
                className={`${
                  touched.link && errors.link ? "error-input" : ""
                }`}
              />

              {touched.link && errors.link && <Error error={errors.link} />}
            </InputLayout>
          </div>

          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="date" className="required-field">
                Date
              </label>
              <DateSelector
                placeholder="Enter Date"
                onChange={(selected) => setFieldValue("date", selected)}
                selectedDate={initialData?.date}
              />
            </InputLayout>

            <InputLayout>
              <div className="add-page-switch" style={{ marginTop: "31px" }}>
                <label htmlFor="" className="mb-0">
                  Is Featured
                </label>
                <div className="form-switch">
                  {values.is_featured ? "Yes" : "No"}
                  <input
                    className="form-check-input ms-3"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="is_featured"
                    checked={values.is_featured}
                    value={values.is_featured}
                    onChange={(e) => {
                      values.is_featured = !values.is_featured;
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </InputLayout>
          </div>

          <div className="form-group mt-3">
            <QuillEditor
              classes="mt-0 mb-0"
              label="Description"
              placeholderText="Enter Description"
              onChange={(content) => {
                setFieldValue("description", content);
              }}
              value={initialData?.description}
              type=""
              hp="80%"
              height="300px"
            />
          </div>

          <div className="row g-3 mt-1">
            <QuillEditor
              classes="mt-0 mb-0"
              label="Transcript"
              placeholderText="Enter Video Transcript"
              onChange={(content) => {
                setFieldValue("transcript", content);
              }}
              value={initialData?.transcript}
              type=""
              hp="80%"
              height="300px"
            />
          </div>

          {/* {console.log("values", values)} */}

          {initialData ? (
            <button
              type="button"
              className="btn btn-primary w-100 mt-4 mb-3"
              disabled={!isValid}
              onClick={() => handleEdit(values)}
            >
              Update
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary w-100 mt-4 mb-3"
              disabled={!isValid || !dirty}
            >
              Submit
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddVideoForm;
