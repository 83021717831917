import { getData } from ".";

export const GeoAutoCompleteAddress = async (params, token) => {
  const payload = {
    query: params.inputValue,
    state: params.stateName,
  };
  try {
    const res = await getData({
      endpoint: "Wrapper/googleSearchAddress",
      params: payload,
      token: token,
    });
    return res;
  } catch (exp) {}
};

export const GeoAutoCompleteApi = async (params, token) => {
  const payload = {
    query: params.inputValue,
    state: params.stateName,
  };
  try {
    const res = await getData({
      endpoint: "Wrapper/googleSearchCity",
      params: payload,
      token: token,
    });
    return res;
  } catch (exp) {}
};
