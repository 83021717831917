import { useNavigate } from "react-router-dom";
import { convertToValueLabel } from "..";
import { useSubscription } from "../../../../../SubscriptionContext";
import DeleteRed from "../../../../Icons/DeleteRed";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import TypeDiv from "../../../../atoms/TypeDiv";
import NoContentCard from "../../../../molecules/NoContentCard";
import BlogFilters from "../../../../organisms/blogFilters/SaveListFilters";
import Shimmer from "../Shimmer/shimmer";
import "../saved_list.scss";

const SavedPeople = ({
  removeIcon,
  data,
  handleDelete,
  isSearched,
  filter,
  onFilterChange,
  selectedFilter,
  loadShimmer,
}: any) => {
  const navigate = useNavigate();

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  console.log("save-data", data);
  return (
    <div className="saved-data-container">
      <>
        {loadShimmer ? (
          <Shimmer />
        ) : (
          <>
            <div className="mt-3">
              <BlogFilters
                data={convertToValueLabel(data?.distinct_groups)}
                onFilterSelect={(option: any) => onFilterChange(option)}
                showDeleteIcon
                type="People"
                alreadySelected={selectedFilter}
              />
            </div>

            {data?.data?.length > 0 ? (
              <>
                {data?.data?.map((item: any) => (
                  <div className="saved-item-container">
                    <div
                      className="saved-item cursor-pointer"
                      key={item.saved_list}
                      onClick={(e) => {
                        isSubscriptionExpired
                          ? handleUserClick(e)
                          : window.open(
                              `/dashboard/people/${item.people_id}`,
                              "_blank"
                            );
                      }}
                    >
                      <PeopleProfileCard
                        profile={item?.profile_photo}
                        size="90px"
                        borderRadius="20%"
                        name={item.name}
                      />

                      <div>
                        <div className="d-flex align-items-center gap-3 mb-2">
                          <h4 className="h4-i semi-light mb-0">{item?.name}</h4>
                          {(!selectedFilter || selectedFilter === "All") && (
                            <TypeDiv data={item?.group_name} color="#FFD88D" />
                          )}
                        </div>
                        <p className="p mb-1">{item.job_title}</p>
                        <p className="p-dark mb-1">{item.company}</p>
                        <p className="p mb-1">
                          <>
                            {item?.state}
                            {item?.state && ", "} {item?.country}
                          </>
                        </p>
                      </div>
                    </div>

                    <button
                      className={
                        !removeIcon ? "btn btn-outline-danger" : "manage-icon"
                      }
                      onClick={(e) => {
                        isSubscriptionExpired
                          ? handleUserClick(e)
                          : handleDelete(item.saved_list);
                      }}
                    >
                      {!removeIcon ? (
                        "Remove"
                      ) : (
                        <i className="d-flex">
                          <DeleteRed className="Saved-delete-icon-container" />
                        </i>
                      )}
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <div className="non-saved">
                {isSearched ? (
                  <NoContentCard title="Sorry! No Result Found" type="Search" />
                ) : (
                  <NoContentCard title="No Person Saved Yet !" type="" />
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default SavedPeople;
