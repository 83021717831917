import { useCallback, useEffect, useState } from "react";
import { getData } from "../../../services";
import Pagination from "../../molecules/Pagination";
import "./renderFaq.scss";

const RenderFaq = ({ color, type, faq_type, search_query }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setPage(1); // Reset current page to 1
    setPageLimit(perPage);
  }, []);

  const fetchFaqData = async () => {
    try {
      setIsLoading(true);
      let endpoint = "";

      // Set the API endpoint based on faq_type
      switch (faq_type) {
        case "General":
          endpoint = "Settings/getGeneralFaqs";
          break;
        case "Investor":
          endpoint = "Settings/getInvestorFaqs";
          break;
        case "Manager/Sponsors":
          endpoint = "Settings/getManagerFaqs";
          break;
        default:
          break;
      }

      // Fetch data from the API
      const response = await getData({
        endpoint: endpoint,
        params: {
          search_query: search_query,
          page: page,
          page_limit: pageLimit,
        },
      });

      setData(response); // Update data with the response
    } catch (error) {
      console.error("Failed to fetch FAQ data:", error);
    } finally {
      setIsLoading(false); // Turn off loading state
    }
  };

  useEffect(() => {
    fetchFaqData();
  }, [faq_type, search_query, page, pageLimit]);

  // Check if data is provided
  if (!data || data?.data?.length === 0) {
    return <div style={{ textAlign: "center" }}>No data available</div>;
  }

  const itemsToShow =
    type === "all"
      ? data && data?.data?.length > 0
        ? data?.data
        : []
      : data && data?.data?.length > 0
      ? data?.data.slice(0, 5)
      : [];

  return (
    <div id="faq-rendering">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <span
            className="spinner-border spinner-border-lg ms-2 mt-5"
            role="status"
            aria-hidden="true"
            style={{ width: "3rem", height: "3rem" }}
          ></span>
        </div>
      ) : (
        <div key={faq_type}>
          <div className="accordion" id="accordionExample">
            {itemsToShow?.map((item) => (
              <div className="accordian" key={item.id}>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading${item.id}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${item.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${item.id}`}
                      style={{ backgroundColor: color }}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${item.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${item.id}`}
                    data-bs-parent="#accordionExample" // This ensures only one is open at a time
                  >
                    <div
                      className="accordion-body"
                      style={{
                        backgroundColor:
                          color === "#ffffff" ? "aliceblue" : color,
                        borderRadius: "0px 0px 16px 16px",
                      }}
                    >
                      <div>
                        <div className="shared_blog">
                          <p
                            className="card-body rich-text-editor-container control-image-accrordion"
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {data?.total_record > 10 && (
            <Pagination
              currentPage={page}
              totalItems={data?.total_record}
              itemsPerPage={pageLimit}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RenderFaq;
