const Lock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4119_14784)">
        <path
          d="M22.1663 9.828V8.16667C22.1663 6.00073 21.3059 3.92351 19.7744 2.39196C18.2428 0.860414 16.1656 0 13.9997 0C11.8337 0 9.75652 0.860414 8.22497 2.39196C6.69342 3.92351 5.83301 6.00073 5.83301 8.16667V9.828C4.79393 10.2815 3.90952 11.0279 3.28792 11.9761C2.66633 12.9242 2.3345 14.0329 2.33301 15.1667V22.1667C2.33486 23.7132 2.95004 25.1959 4.0436 26.2894C5.13716 27.383 6.61981 27.9981 8.16634 28H19.833C21.3795 27.9981 22.8622 27.383 23.9558 26.2894C25.0493 25.1959 25.6645 23.7132 25.6663 22.1667V15.1667C25.6649 14.0329 25.333 12.9242 24.7114 11.9761C24.0898 11.0279 23.2054 10.2815 22.1663 9.828ZM8.16634 8.16667C8.16634 6.61957 8.78092 5.13584 9.87489 4.04188C10.9688 2.94791 12.4526 2.33333 13.9997 2.33333C15.5468 2.33333 17.0305 2.94791 18.1245 4.04188C19.2184 5.13584 19.833 6.61957 19.833 8.16667V9.33333H8.16634V8.16667ZM23.333 22.1667C23.333 23.0949 22.9643 23.9852 22.3079 24.6415C21.6515 25.2979 20.7613 25.6667 19.833 25.6667H8.16634C7.23808 25.6667 6.34785 25.2979 5.69147 24.6415C5.03509 23.9852 4.66634 23.0949 4.66634 22.1667V15.1667C4.66634 14.2384 5.03509 13.3482 5.69147 12.6918C6.34785 12.0354 7.23808 11.6667 8.16634 11.6667H19.833C20.7613 11.6667 21.6515 12.0354 22.3079 12.6918C22.9643 13.3482 23.333 14.2384 23.333 15.1667V22.1667Z"
          fill="white"
        />
        <path
          d="M13.9997 16.333C13.6903 16.333 13.3935 16.4559 13.1747 16.6747C12.9559 16.8935 12.833 17.1903 12.833 17.4997V19.833C12.833 20.1424 12.9559 20.4392 13.1747 20.658C13.3935 20.8767 13.6903 20.9997 13.9997 20.9997C14.3091 20.9997 14.6058 20.8767 14.8246 20.658C15.0434 20.4392 15.1664 20.1424 15.1664 19.833V17.4997C15.1664 17.1903 15.0434 16.8935 14.8246 16.6747C14.6058 16.4559 14.3091 16.333 13.9997 16.333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4119_14784">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Lock;
