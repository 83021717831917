import LoadingBar from "react-top-loading-bar";

const GlobaLoader = ({ loadingBarRef }) => {
  return (
    <>
      <LoadingBar
        color="#3D9BE9"
        height={4}
        ref={loadingBarRef}
        shadow
        waitingTime={1000}
        className="loadingBar"
      />
    </>
  );
};
export default GlobaLoader;
