import { expitationTime } from "../../../../helpers/expirationTime";

function getDaysLeftUntilTrialEnd(trialEndDateUTC) {
  // Convert the UTC date string to a Date object
  const trialEndDate = new Date(trialEndDateUTC);

  // Get the current date in local time
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = trialEndDate.getTime() - currentDate.getTime();

  // Convert the time difference from milliseconds to days
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

export const commonLogicForLoginAndVerification = (data, cookie, setCookie) => {
  setCookie("cid", data?.company_id, {
    path: "/",
    expires: expitationTime(),
  });
  setCookie("t", data?.token, {
    path: "/",
    expires: expitationTime(),
  });
  setCookie("token", data?.token, {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });
  setCookie("username", data?.user_name, {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });

  if (data?.subscription_enable || data?.subs_end_date) {
    localStorage.setItem(
      "trial_info",
      JSON.stringify({
        trial_end_date: data?.trial_end_date,
        is_trial: data?.is_trial,
        subs_end_date: data?.subs_end_date,
        subscription_enable: data?.subscription_enable,
        days_left: getDaysLeftUntilTrialEnd(data?.subs_end_date),
        is_last_subs_mode_trial: data?.is_last_subs_mode_trial,
      })
    );
  }
};
