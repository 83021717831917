import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { confimationStyles } from "../../../../assets/styles/toast";
import { postData } from "../../../../services";
import Error from "../../../atoms/Error";
import PasswordField from "../../../atoms/PasswordField";
import style from "../forms.module.scss";
// import ResetPassword from '..';
// import { useCreatePassword } from '../../../services/loginService';

interface FormProps {
  expiry?: any;
  uid?: any;
  token?: any;
  type?: any;
}

const ResetPasswordForm: React.FC<FormProps> = ({
  expiry,
  uid,
  token,
  type,
}) => {
  const navigate = useNavigate();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  //   const { mutate } = useCreatePassword();

  // Form
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    const dataToSend = {
      password: values.password,
      user_id: uid,
      expiry: expiry,
      token: token,
    };

    try {
      const { data: resultData, isLoading } = await postData({
        endpoint: "/CustomerAuth/createPassword",
        data: {},
        params: dataToSend,
      });

      if (resultData.status) {
        navigate("/auth/login");
        toast.success("Password Reset Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (error) {
      // console.log(dataToSend)
    }
    setSubmitting(false);
  };

  // Password Conditions
  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    const isPasswordValid =
      newPassword.length >= 8 &&
      /[A-Z]/.test(newPassword) &&
      /[a-z]/.test(newPassword) &&
      /[0-9]/.test(newPassword) &&
      /[!@#$%^&*]/.test(newPassword);
    setIsPasswordValid(isPasswordValid);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        touched,
        errors,
        values,
        isValid,
        dirty,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group mt-4">
            <PasswordField
              label="Password"
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.password || isSubmitting) && errors.password
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.password || isSubmitting) && errors.password && (
              <Error error={errors.password} />
            )}
          </div>

          <div
            className={`${style.password_strength} ${
              !isPasswordValid && touched.password
                ? style.conditions_not_met
                : ""
            }`}
          >
            <p className={style.condition_heading}>Password must :</p>
            <div className={style.condition_wrapper}>
              <div
                className={`${style.condition} ${
                  values.password.length >= 8 ? style.met : ""
                }`}
              ></div>
              <span className={style.condition_description}>
                {" "}
                Minimum 8 characters
              </span>
            </div>
            <div className={style.condition_wrapper}>
              <div
                className={`${style.condition}  ${
                  /[A-Z]/.test(values.password) ? style.met : ""
                }`}
              ></div>
              <span className={style.condition_description}>
                At least one uppercase character
              </span>
            </div>
            <div className={style.condition_wrapper}>
              <div
                className={`${style.condition}  ${
                  /[a-z]/.test(values.password) ? style.met : ""
                }`}
              ></div>
              <span className={style.condition_description}>
                At least one lowercase character
              </span>
            </div>
            <div className={style.condition_wrapper}>
              <div
                className={`${style.condition}  ${
                  /[0-9]/.test(values.password) ? style.met : ""
                }`}
              ></div>
              <span className={style.condition_description}>
                At least one numeric character
              </span>
            </div>
            <div className={style.condition_wrapper}>
              <div
                className={`${style.condition}  ${
                  /[!@#$%^&*]/.test(values.password) ? style.met : ""
                }`}
              ></div>
              <span className={style.condition_description}>
                One special character
              </span>
            </div>
          </div>

          <div className="form-group mt-4">
            <PasswordField
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.confirmPassword || isSubmitting) &&
                errors.confirmPassword
                  ? "error-input"
                  : ""
              }`}
            />
          </div>

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
          >
            {type === "reset" ? "Reset" : "Create"} Password
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
