import { patchData, postData } from "../../../../../../services";

export const addCompanySocial = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "Company/addCompanyChannel",
      data: data.links,
      params: { company_id: data.company_id, user_role: "Customer" },
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const editSocialDetail = async (data, token) => {
  const res = await patchData({
    endpoint: "Company/editCompanyChannel",
    data: { ...data, user_role: "Customer" },
    params: {},
    token: token,
  });
  return res;
};
