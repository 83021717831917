import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { useSubscription } from "../../../SubscriptionContext";
import FaqIcon from "../../Icons/FaqIcon";
import HelpCenter from "../../Icons/Help-center";
import HomeIcon from "../../Icons/HomeIcon";
import Investors from "../../Icons/Investors";
import Library from "../../Icons/Library";
import Lock from "../../Icons/Lock";
import Product from "../../Icons/Product";
import Requests from "../../Icons/Requests";
import Resources from "../../Icons/Resources";
import SavedIcon from "../../Icons/SavedIcon";
import Share from "../../Icons/Share";
import Thought from "../../Icons/Thought";
import "./sidebar.scss";

// Custom NavLink wrapper to handle isActive logic
const CustomNavLink = ({
  to,
  label,
  icon,
  isLocked,
  alternatePath,
  index,
  handleSideBar,
}: {
  to: string;
  label: string;
  icon: any;
  isLocked: boolean | undefined;
  alternatePath: string;
  index: any;
  handleSideBar: any;
}) => {
  const location = useLocation();
  const isActive = () => {
    return to === "/dashboard"
      ? location.pathname === "/dashboard"
      : location.pathname.startsWith(to);
  };
  return (
    <li
      key={index}
      onClick={handleSideBar}
      className={`${isActive() && "active-side"}`}
    >
      <NavLink
        to={isLocked ? alternatePath : to}
        className={`${isActive() && "sidebar-active-link"} sidebar-link`}
        id={`${label}-link`}
      >
        {typeof icon === "object" ? (
          <div className="d-flex align-items-center">
            <i>{icon}</i>
            {label}
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <i className={`fi ${icon}`}></i>
            {label}
          </div>
        )}

        {isLocked && <Lock />}
      </NavLink>
    </li>
  );
};

const Sidebar = ({ isActive, setIsActive, sidebarRef, onClick }: any) => {
  const { isSubscriptionExpired } = useSubscription();

  const userInfo: any = useSelector(
    (state: any) => state.peopleData?.peopleData
  );

  const screenWidth = useScreenWidth();
  let links = [
    {
      to: "/dashboard",
      label: "Home",
      icon: <HomeIcon />,
      alternatePath: "/dashboard",
    },
    {
      to: "/dashboard/products",
      label: "Fund Directory",
      icon: <Product />,
      alternatePath: "/dashboard/products",
      isLocked: !(userInfo?.has_master_access || userInfo?.manager_database),
    },
    {
      to: "/dashboard/library",
      label: "Library",
      icon: <Library />,
      isLocked:
        isSubscriptionExpired ||
        !(
          userInfo?.has_master_access || userInfo?.investment_library_education
        ),
      alternatePath: userInfo?.product_learn_more_link,
    },
    {
      to: "/dashboard/investors",
      label: "Investors",
      icon: <Investors />,
      alternatePath: "/dashboard/investors",
      isLocked: !(userInfo?.has_master_access || userInfo?.investor_database),
    },
    {
      to: "/dashboard/resources",
      label: "Resources",
      icon: <Resources />,
      isLocked:
        isSubscriptionExpired ||
        !(userInfo?.has_master_access || userInfo?.resource),
      alternatePath: userInfo?.investor_learn_more_link,
    },
    {
      to: "/dashboard/saved-list",
      label: "Saved List",
      icon: <SavedIcon className="grey-fill-saved" />,
      alternatePath: "/dashboard/saved-list",
      isLocked: isSubscriptionExpired,
    },
    {
      to: "/dashboard/shared-content",
      label: "Shared Content",
      icon: <Share />,
      alternatePath: "/dashboard/shared-content",
      isLocked: isSubscriptionExpired,
    },
    {
      to: "/dashboard/requests",
      label: "Requests",
      icon: <Requests />,
      alternatePath: "/dashboard/requests",
      isLocked: isSubscriptionExpired,
    },
    {
      to: "/dashboard/help-center",
      label: "Help Center",
      icon: <HelpCenter />,
      alternatePath: "/help-center",
    },
    {
      to: "/dashboard/faq",
      label: "FAQ",
      icon: <FaqIcon />,
      alternatePath: "/faq",
    },
  ];

  if (userInfo?.has_master_access || userInfo?.is_admin) {
    // Insert "Thought Leadership" link at the 6th position
    links.splice(5, 0, {
      to: "/dashboard/my-blogs",
      label: "My Blogs",
      icon: <Thought />,
      alternatePath: "/dashboard/my-blogs",
    });
  }

  const memoizedSetIsActive = useCallback((newIsActive: boolean) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);

  const handleSideBar = () => {
    if (screenWidth < 1233) setIsActive(false);
  };

  return (
    <>
      {isActive && (
        <div
          className="sidebar"
          style={{ width: "18rem" }}
          ref={sidebarRef}
          onClick={onClick}
        >
          <div className="ul">
            {links?.map((link: any, index: number) => (
              <CustomNavLink
                index={index}
                to={link.to}
                label={link.label}
                icon={link.icon}
                isLocked={link.isLocked}
                alternatePath={link.alternatePath}
                handleSideBar={handleSideBar}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
