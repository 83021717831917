import { useNavigate } from "react-router-dom";
import Image from "../../../assets/images/bg-image.png";
import ContentContainer from "../../atoms/ContentContainer";
import Heading from "../../atoms/Heading";
import PageCard from "../../atoms/PageCard";
// import Footer from "../../molecules/Footer";
// import Navbar from "../../molecules/Navbar";
import ContactTemplate from "../../templates/ContactTemplate";
import FaqTemplate from "../../templates/FaqTemplate";
import HeroSection from "../../templates/InfoPage/HeroSection/HeroSection";
type Props = {};

const HelpCenter = (props) => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Navbar sideBarRequired={false} /> */}
      <ContentContainer id="Help-Center-Container">
        <Heading title="Help Center" type="main" />
        {/* <BannerImage altText="text" imageUrl={Image}>
          {" "}
          Give us a shout!
          <br />
          Send us a message.
        </BannerImage> */}

        <HeroSection
          ImagePic={Image}
          Heading={
            <>
              Give us a shout!
              <br />
              Send us a message.
            </>
          }
          HeadingStyle={true}
        />
        <ContactTemplate />
        <PageCard
          customStyles={{ backgroundColor: "#F9F9F9" }}
          classes="mt-4 mt-sm-5"
        >
          <Heading title="Frequently Asked Questions">
            <button
              className="btn btn-outline-dark responsive-btn"
              onClick={() => navigate("/dashboard/faq")}
            >
              View All
            </button>
          </Heading>
          <FaqTemplate color="#F9F9F9" />
        </PageCard>
      </ContentContainer>
      {/* <Footer /> */}
    </>
  );
};

export default HelpCenter;
