import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../Icons/FilterIcon";
import CustomButton from "../../atoms/CustomeButton";
import CloseBtnIcom from "../Modal/CloseBtnIcon";
import { DateSelector } from "../MonthYearSelector";

const FilterComponent = ({
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  dateRangeCount = 1,
  dateRangeNames = ["Date range"],
  type = "left",
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const filterData = propsFilterData || {};

  const updated = dateRangeNames.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  const updatedFilterData = isDateRange
    ? { ...filterData, ...updated }
    : filterData;

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate0: null,
    endDate0: null,
    startDate1: null,
    endDate1: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  useEffect(() => {
    const keys = Object.keys(filterData);
    setSelectedKey(keys[0]);
  }, [filterData]);

  const toggleFilterBox = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
  };

  const handleOptionChange = (option) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      if (updatedOptionsMap[selectedKey].includes(option)) {
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (prevOption) => prevOption !== option
        );
      } else {
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          option,
        ];
      }

      return updatedOptionsMap;
    });
  };

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      startDate0: dateRange.startDate0,
      endDate0: dateRange.endDate0,
      endDate1: dateRange.endDate1,
      startDate1: dateRange.startDate1,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
  };

  return (
    <div className="filter-container" ref={containerRef}>
      <CustomButton
        handleClick={toggleFilterBox}
        icon={<FilterIcon />}
        text="Filters"
      />

      {isOpen && (
        <div
          className={type === "right" ? "right-filter-box" : "filter-box"}
          ref={dropdownRef}
        >
          <div className="filter-header">
            <p className="header">Filters</p>

            <span
              className="header"
              style={{ cursor: "pointer" }}
              onClick={toggleFilterBox}
            >
              <i className="d-flex">
                <CloseBtnIcom />
              </i>
            </span>
          </div>
          <div className="filter-data-box">
            <div className="filter-key-box">
              {Object.keys(updatedFilterData)?.map((key) => (
                <div
                  key={key}
                  onClick={() => handleKeyClick(key)}
                  className={`filter-key ${
                    selectedKey === key ? "active-key" : ""
                  }`}
                >
                  {key}
                </div>
              ))}
            </div>

            <div className="filters-column">
              {selectedKey === "Update Date" ? (
                <div>
                  <div>
                    <label htmlFor="startDate">Start Date</label>
                    <DateSelector
                      placeholder="After Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, startDate1: date }))
                      }
                      selectedDate={dateRange.startDate1}
                    />
                  </div>

                  <div className="mt-3">
                    <label htmlFor="endDate">End Date</label>
                    <DateSelector
                      placeholder="Before Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, endDate1: date }))
                      }
                      selectedDate={dateRange.endDate1}
                    />
                  </div>
                </div>
              ) : selectedKey === "Create Date" ? (
                <div>
                  <div>
                    <label htmlFor="startDate">Start Date</label>
                    <DateSelector
                      placeholder="Start Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, startDate0: date }))
                      }
                      selectedDate={dateRange.startDate0}
                    />
                  </div>

                  <div className="mt-3">
                    <label htmlFor="endDate">End Date</label>
                    <DateSelector
                      placeholder="End Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({ ...prev, endDate0: date }))
                      }
                      selectedDate={dateRange.endDate0}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {updatedFilterData[selectedKey].map((option) => (
                    <>
                      <div key={option} className="mb-1">
                        <label className="check-container" htmlFor={option}>
                          {option}
                          <input
                            type="checkbox"
                            id={option}
                            checked={
                              selectedOptionsMap[selectedKey] &&
                              selectedOptionsMap[selectedKey].includes(option)
                            }
                            onChange={() => handleOptionChange(option)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          <hr className="my-0" />
          <div className="d-flex justify-content-end gap-3 p-3">
            <button
              className="btn btn-outline-dark mt-0"
              onClick={() => handleReset()}
            >
              Reset
            </button>
            <button
              className="btn btn-primary mt-0"
              onClick={() => handleApply()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
