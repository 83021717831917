const LogOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_7593_19534)">
        <path
          d="M17.1222 6.87833L14.2129 3.96908C14.0715 3.83246 13.882 3.75686 13.6854 3.75857C13.4887 3.76028 13.3006 3.83916 13.1616 3.97822C13.0225 4.11727 12.9436 4.30538 12.9419 4.50203C12.9402 4.69868 13.0158 4.88813 13.1524 5.02958L16.0617 7.93883C16.1481 8.02698 16.2237 8.12518 16.2867 8.23133C16.2754 8.23133 16.2664 8.22533 16.2552 8.22533L4.49219 8.24933C4.29328 8.24933 4.10251 8.32835 3.96186 8.469C3.82121 8.60965 3.74219 8.80042 3.74219 8.99933C3.74219 9.19824 3.82121 9.38901 3.96186 9.52966C4.10251 9.67031 4.29328 9.74933 4.49219 9.74933L16.2507 9.72533C16.2717 9.72533 16.2889 9.71483 16.3092 9.71333C16.2427 9.8402 16.1583 9.95688 16.0587 10.0598L13.1494 12.9691C13.0778 13.0383 13.0207 13.121 12.9814 13.2125C12.9421 13.304 12.9214 13.4024 12.9205 13.502C12.9196 13.6016 12.9386 13.7004 12.9763 13.7925C13.014 13.8847 13.0697 13.9685 13.1401 14.0389C13.2106 14.1093 13.2943 14.165 13.3865 14.2027C13.4786 14.2404 13.5774 14.2594 13.677 14.2585C13.7766 14.2577 13.875 14.237 13.9665 14.1977C14.058 14.1583 14.1408 14.1012 14.2099 14.0296L17.1192 11.1203C17.6816 10.5577 17.9975 9.79482 17.9975 8.99933C17.9975 8.20384 17.6816 7.44091 17.1192 6.87833H17.1222Z"
          fill="#BE0F00"
        />
        <path
          d="M5.25 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H5.25C5.44891 1.5 5.63968 1.42098 5.78033 1.28033C5.92098 1.13968 6 0.948912 6 0.75C6 0.551088 5.92098 0.360322 5.78033 0.21967C5.63968 0.0790176 5.44891 0 5.25 0L3.75 0C2.7558 0.00119089 1.80267 0.396661 1.09966 1.09966C0.396661 1.80267 0.00119089 2.7558 0 3.75L0 14.25C0.00119089 15.2442 0.396661 16.1973 1.09966 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H5.25C5.44891 18 5.63968 17.921 5.78033 17.7803C5.92098 17.6397 6 17.4489 6 17.25C6 17.0511 5.92098 16.8603 5.78033 16.7197C5.63968 16.579 5.44891 16.5 5.25 16.5Z"
          fill="#BE0F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_7593_19534">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LogOutIcon;
