import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { deleteData } from "../../../services";
import { removeItem } from "../../../store/slices/savedSlice";
import CloseButton from "../../atoms/CloseButton";
import Modal from "../../molecules/Modal/DeleteModal";
import "./blog_filter.scss";

const typeData = {
  Company: "company",
  People: "people",
  product: "product",
};

const BlogFilters = ({
  data,
  onFilterSelect,
  alreadySelected,
  showDeleteIcon = false,
  type,
}: any) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("All");
  const [enableDelete, setEnableDelete] = useState(false);
  const [filterData, setFilterData] = useState(data || []);
  const [cookies] = useCookies(["t"]);
  const [modal, setModal] = useState({ isOpen: false, name: null });

  useEffect(() => {
    if (data) {
      setFilterData(data);
      // debugger;
    }
  }, []);

  const handleFilterClick = (name: string) => {
    // Only switch filters if delete mode is off
    if (!enableDelete) {
      setSelected(name);
      onFilterSelect(name); // Emit the selected value to the parent component
    }
  };

  const handleDeleteInitialize = (value: string) => {
    // Open the delete confirmation modal
    setModal({ isOpen: true, name: value });
  };

  const handleDelete = async (value: string) => {
    // Remove the item from the list
    setFilterData((prevData: any) =>
      prevData.filter((item: any) => item.value !== value)
    );

    dispatch(removeItem({ type: typeData[type], item: value }));
    // If the deleted item is currently selected, reset the selected filter

    // Close the modal
    setModal((prev) => ({ ...prev, isOpen: false }));

    const res = await deleteData({
      endpoint: "SavedList/removeSavedListByGroup",
      type: "normal",
      token: cookies.t,
      params: {
        user_id: cookies.cuid,
        group: value,
        map_type: type,
      },
    });

    if (selected === value) {
      setSelected("All");
      onFilterSelect("All");
    }
  };

  useEffect(() => {
    if (alreadySelected) {
      setSelected(alreadySelected);
      onFilterSelect(alreadySelected);
    }
  }, [alreadySelected]);

  return (
    <div className="filter-item-container d-flex justify-content-between filter-border ">
      {/* Left side: Filters */}
      <div className="d-flex gap-3 flex-wrap w-90 left-side-filters">
        <div
          className={`filter-item ${selected === "All" ? "active" : ""}`}
          onClick={() => handleFilterClick("All")}
        >
          All
        </div>
        {filterData?.map((item) => (
          <div
            key={item.value}
            className={`filter-item ${selected === item.label ? "active" : ""}`}
            onClick={() => handleFilterClick(item.label)}
          >
            <div className="d-flex align-items-center gap-2">
              {item.label}
              {enableDelete && (
                <CloseButton
                  onClose={() => handleDeleteInitialize(item.value)}
                  className=""
                  style={{}}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Right side: Delete Button */}
      <div
        className="delete-option d-flex justify-content-end"
        style={{ flex: 1 }}
      >
        {showDeleteIcon && filterData?.length > 0 && (
          <div className="cursor-pointer" title="Delete Groups">
            {/* Toggle delete mode */}
            <div
              className={`filter-item active`}
              onClick={() => setEnableDelete((prev) => !prev)}
            >
              Delete Existing Group
            </div>
          </div>
        )}
      </div>

      {/* Modal for Deletion Confirmation */}
      {modal.isOpen && (
        <Modal
          id=""
          isActive={modal.isOpen}
          onClose={() => setModal({ isOpen: false, name: null })}
          onClick={() => handleDelete(modal.name)}
          name={modal.name}
          type="Delete Group"
          description={`Are you sure you want to delete the Group ${modal.name}`}
        />
      )}
    </div>
  );
};

export default BlogFilters;
