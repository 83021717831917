import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Delay the scroll to ensure it happens after rendering
    const scrollToTop = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    // Clear the timeout on cleanup
    return () => clearTimeout(scrollToTop);
  }, [pathname]);

  // Return null or an empty fragment as the component needs to return something
  return null;
};

export default ScrollToTop;
