import React, { ReactNode, CSSProperties } from 'react';
import styles from './page_card.module.scss';

interface PageCardProps {
  children: ReactNode;
  classes?: string;
  customStyles?: CSSProperties;  // Changed from 'styles' to 'customStyles'
}

const PageCard: React.FC<PageCardProps> = ({ children, classes = '', customStyles = {} }) => {
  return (
    <div className={`${styles.page_card} ${classes}`} style={customStyles}>
      {children}
    </div>
  );
};

export default PageCard;
