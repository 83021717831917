const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_7593_17985)">
        <path
          d="M9 9C9.89002 9 10.76 8.73608 11.5001 8.24162C12.2401 7.74715 12.8169 7.04434 13.1575 6.22208C13.4981 5.39981 13.5872 4.49501 13.4135 3.6221C13.2399 2.74918 12.8113 1.94736 12.182 1.31802C11.5526 0.688685 10.7508 0.260102 9.87791 0.0864683C9.00499 -0.0871652 8.10019 0.00194979 7.27792 0.342544C6.45566 0.683138 5.75285 1.25991 5.25839 1.99994C4.76392 2.73996 4.5 3.60999 4.5 4.5C4.50119 5.69311 4.97568 6.83701 5.81934 7.68067C6.66299 8.52432 7.80689 8.99881 9 9ZM9 1.5C9.59334 1.5 10.1734 1.67595 10.6667 2.00559C11.1601 2.33524 11.5446 2.80377 11.7716 3.35195C11.9987 3.90013 12.0581 4.50333 11.9424 5.08527C11.8266 5.66722 11.5409 6.20177 11.1213 6.62132C10.7018 7.04088 10.1672 7.3266 9.58527 7.44236C9.00333 7.55811 8.40013 7.4987 7.85195 7.27164C7.30377 7.04458 6.83524 6.66006 6.50559 6.16671C6.17595 5.67337 6 5.09335 6 4.5C6 3.70435 6.31607 2.94129 6.87868 2.37868C7.44129 1.81607 8.20435 1.5 9 1.5Z"
          fill="#1A1D1F"
        />
        <path
          d="M9 10.5005C7.2104 10.5025 5.49466 11.2143 4.22922 12.4797C2.96378 13.7452 2.25199 15.4609 2.25 17.2505C2.25 17.4494 2.32902 17.6402 2.46967 17.7808C2.61032 17.9215 2.80109 18.0005 3 18.0005C3.19891 18.0005 3.38968 17.9215 3.53033 17.7808C3.67098 17.6402 3.75 17.4494 3.75 17.2505C3.75 15.8581 4.30312 14.5227 5.28769 13.5382C6.27226 12.5536 7.60761 12.0005 9 12.0005C10.3924 12.0005 11.7277 12.5536 12.7123 13.5382C13.6969 14.5227 14.25 15.8581 14.25 17.2505C14.25 17.4494 14.329 17.6402 14.4697 17.7808C14.6103 17.9215 14.8011 18.0005 15 18.0005C15.1989 18.0005 15.3897 17.9215 15.5303 17.7808C15.671 17.6402 15.75 17.4494 15.75 17.2505C15.748 15.4609 15.0362 13.7452 13.7708 12.4797C12.5053 11.2143 10.7896 10.5025 9 10.5005Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7593_17985">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UserIcon;
