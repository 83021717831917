import "./HeroSection.scss";
const HeroSection = ({
  ImagePic,
  Heading,
  Button,
  onClick,
  Button2,
  onClick2,
  isButton2Visible,
  HeadingStyle,
}) => {
  return (
    <div className="Image-Container">
      <div className="Image-Pic">
        {ImagePic && (
          <img src={ImagePic} className="Image-Pic" alt="Hero Section" />
        )}
        <div
          className={
            HeadingStyle ? "Pic-Content-fund-directory" : "Pic-Content"
          }
        >
          <h1
            className={
              HeadingStyle ? "Pic-Content-fund-Heading" : "Pic-Heading"
            }
          >
            {Heading}
          </h1>

          {Button && (
            <div className="Btns">
              <button className="btn btn-primary" onClick={onClick}>
                {Button}
              </button>
              {isButton2Visible && Button2 && (
                <button className="btn btn-primary" onClick={onClick2}>
                  {Button2}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
