const WorkIcon = ({ width = "32" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1805_14023)">
        <path
          d="M25.3333 5.33333H23.8667C23.5572 3.82855 22.7384 2.47646 21.5483 1.50496C20.3582 0.533453 18.8696 0.00193937 17.3333 0L14.6667 0C13.1304 0.00193937 11.6418 0.533453 10.4517 1.50496C9.26157 2.47646 8.4428 3.82855 8.13333 5.33333H6.66667C4.89921 5.33545 3.20474 6.03851 1.95496 7.28829C0.705176 8.53808 0.00211714 10.2325 0 12L0 25.3333C0.00211714 27.1008 0.705176 28.7953 1.95496 30.045C3.20474 31.2948 4.89921 31.9979 6.66667 32H25.3333C27.1008 31.9979 28.7953 31.2948 30.045 30.045C31.2948 28.7953 31.9979 27.1008 32 25.3333V12C31.9979 10.2325 31.2948 8.53808 30.045 7.28829C28.7953 6.03851 27.1008 5.33545 25.3333 5.33333ZM14.6667 2.66667H17.3333C18.1577 2.67008 18.9608 2.92811 19.6329 3.40545C20.305 3.88279 20.8132 4.55612 21.088 5.33333H10.912C11.1868 4.55612 11.695 3.88279 12.3671 3.40545C13.0392 2.92811 13.8423 2.67008 14.6667 2.66667ZM6.66667 8H25.3333C26.3942 8 27.4116 8.42143 28.1618 9.17157C28.9119 9.92172 29.3333 10.9391 29.3333 12V16H2.66667V12C2.66667 10.9391 3.08809 9.92172 3.83824 9.17157C4.58839 8.42143 5.6058 8 6.66667 8ZM25.3333 29.3333H6.66667C5.6058 29.3333 4.58839 28.9119 3.83824 28.1618C3.08809 27.4116 2.66667 26.3942 2.66667 25.3333V18.6667H14.6667V20C14.6667 20.3536 14.8071 20.6928 15.0572 20.9428C15.3072 21.1929 15.6464 21.3333 16 21.3333C16.3536 21.3333 16.6928 21.1929 16.9428 20.9428C17.1929 20.6928 17.3333 20.3536 17.3333 20V18.6667H29.3333V25.3333C29.3333 26.3942 28.9119 27.4116 28.1618 28.1618C27.4116 28.9119 26.3942 29.3333 25.3333 29.3333Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1805_14023">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WorkIcon;
