import "./shimmer.scss";
const Shimmer = () => {
  return (
    <>
      <div className="shimmer-wrapper-conatiner">
        {/* <div className="navbar-container-shimmer">
          <div className="navbar-container-shimmer-1"></div>
          <div className="navbar-container-shimmer-2">
            <div className="navbar-container-shimmer-2-1"></div>
            <div className="navbar-container-shimmer-2-2"></div>
          </div>
        </div> */}
        <div className="Home-shimmer-container">
          {/* <div className="side-shimmer-container">
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-container"></div>
          </div> */}
          <div className="Home-page-shimmer-conatiner">
            <div className="item-shimmer-container-cover">
              <div className="item-shimmer-container-logo"></div>
            </div>
            <div style={{ padding: "1rem" }}>
              <div className="item-shimmer-container-lh"></div>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div className="item-shimmer-container-h"></div>
                <div className="item-shimmer-container-h"></div>
              </div>
              <div className="item-shimmer-container-lh"></div>
            </div>
            <div
              className="d-flex tabstrip-shimmer-container"
              style={{ gap: "10px" }}
            >
              <div className="item-shimmer-container"></div>
              <div className="item-shimmer-container"></div>
              <div className="item-shimmer-container"></div>
              <div className="item-shimmer-container"></div>
              <div className="item-shimmer-container"></div>
            </div>
            <div className="item-shimmer-container-content"></div>
          </div>
          <div className="address-shimmer-container">
            <div className="item-shimmer-container"></div>
            <div className="item-shimmer-medium-container"></div>
            <div className="item-shimmer-medium-container"></div>
            <div className="item-shimmer-medium-container"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Shimmer;
