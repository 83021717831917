export const getFileName = (file) => {
  if (file) {
    const urlObject = new URL(file);
    const filename = urlObject.pathname.split("/").pop();
    const afterUnderscore = filename.split("_")[1];
    const decodedString = decodeURIComponent(afterUnderscore);
    return decodedString;
  } else {
    return "";
  }
};

export const videoFileName = (file) => {
  if (file && file.name) {
    const filename = file.name;
    const afterUnderscore = filename.split("_")[1];

    // If there is no underscore in the filename, handle that case as well
    if (!afterUnderscore) {
      return filename; // Or return an empty string or error message as per your logic
    }

    const decodedString = decodeURIComponent(afterUnderscore);
    return decodedString;
  } else {
    return "";
  }
};
