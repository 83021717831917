const renderSection = (title: string, content: string) => (
  <div className="mb-small">
    <div className="h3-j semi pro-info-heading">{title}</div>
    <div className="quill quill-change">
      <div
        className="ql-container ql-snow ql-no-border"
        style={{ background: "white" }}
      >
        <div
          className="ql-editor blog-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  </div>
);

const Strategy = ({ data }: any) => {
  // console.log("s-data", data);
  return (
    <>
      <h1 className="h1-j mb-large">Strategy</h1>

      {data?.overview && renderSection("Overview", data?.overview)}
      {data?.investment_universe &&
        renderSection("Investment Universe", data?.investment_universe)}
      {data?.diffrentaitors &&
        renderSection(
          "Differentiators / Value Proposition",
          data?.diffrentaitors
        )}
      {data?.risk_and_return_drivers &&
        renderSection("Risk and Return Drivers", data?.risk_and_return_drivers)}
      {data?.capacity && renderSection("Capacity", data?.capacity)}
      {data?.notes && renderSection("Notes", data?.notes)}
    </>
  );
};

export default Strategy;
