import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutModal from "../../molecules/Modal/LogoutModal";

import LogOutIcon from "../../Icons/LogOutIcon";
import PasswordIcon from "../../Icons/PasswordIcon";
import UserEdit from "../../Icons/UserEdit";
import UserIcon from "../../Icons/UserIcon";
import Navbar from "../../molecules/Navbar";
import "./edit.scss";

const EditProfileLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname === path ? "li-active" : "";
  };
  return (
    <>
      <Navbar searchRequired={false} sideBarRequired={false} />

      <div className="edit-screen">
        <div className="edit-navigation">
          <ul>
            <li
              className={isActive("/dashboard/edit/view-profile")}
              onClick={() => navigate("/dashboard/edit/view-profile")}
            >
              <i className="d-flex">
                <UserIcon />
              </i>
              My Profile
            </li>
            <li
              className={isActive("/dashboard/edit/edit-profile")}
              onClick={() => navigate("/dashboard/edit/edit-profile")}
            >
              <i className="d-flex">
                <UserEdit />
              </i>
              Edit Profile
            </li>
            <li
              className={isActive("/dashboard/edit/change-password")}
              onClick={() => navigate("/dashboard/edit/change-password")}
            >
              <i className="d-flex">
                <PasswordIcon />
              </i>
              Change Password
            </li>
            <li className="logout" onClick={() => setIsModalOpen(true)}>
              <i className="d-flex">
                <LogOutIcon />
              </i>
              Logout
            </li>
          </ul>
        </div>
        <div className="edit-content">
          <div className="edit-child">
            <Outlet />
          </div>
        </div>
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </>
  );
};

export default EditProfileLayout;
