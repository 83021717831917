import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import TabStrip from "../../../../molecules/TabStrip";
import "../product.scss";

import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { confimationStyles } from "../../../../../assets/styles/toast";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import { postData } from "../../../../../services";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import EditBlock from "../../../../templates/BlockEdit";
import DataRoom from "./DataRoom";
import Disclaimer from "./Disclaimer";
import ProductConfiguration from "./ProductConfiguration";
import ProductOverview from "./ProductOverview";
import Video from "./Video";

type IProps = {
  type: string;
};

const Edit = (props: IProps) => {
  const { type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, company_id } = useParams();

  const [cookies] = useCookies(["cid", "t"]);

  const [editActive, setEditActive] = useState(false);
  const [activeTab, setActiveTab] = useState("Product Configuration");
  const screenWidth = useScreenWidth();

  useEffect(() => {
    if (screenWidth <= 900) {
      setEditActive(false);
    } else {
      setEditActive(true);
    }
  }, [screenWidth]);

  const dataArray = [
    {
      id: 0,
      label: "Product Configuration",
      view: (
        <ProductConfiguration
          page_type={type}
          onTabChange={(value: any) => handleChange(value)}
        />
      ),
    },
    {
      id: 1,
      label: "Product Overview",
      view: (
        <ProductOverview
          page_type={type} // Make sure type is defined or adjust accordingly
          onTabChange={(value: any) => handleChange(value)} // Assuming onTabChange is a function in your component
        />
      ),
    },
    {
      id: 2,
      label: "Video",
      view: <Video />,
    },
    {
      id: 3,
      label: "Dataroom",
      view: <DataRoom company_id={company_id} />,
    },
    {
      id: 4,
      label: "Disclaimer",
      view: <Disclaimer />,
    },
  ];

  const _getComponent = (tabs: any) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event: any, value: any) => {
    setActiveTab(value);
  };

  const handleChange = (value: any) => {
    setActiveTab(value);
  };

  const handleCreate = async () => {
    const data = {
      product_id: id,
      is_draft: false,
      user_role: "Customer",
    };
    try {
      dispatch(setLoading(true));
      const res = await postData({
        endpoint: "Product/productCreateOrSaveAsDraft",
        data: {},
        params: data,
        token: cookies.t,
      });
      if (res) {
        dispatch(setLoading(false));
        toast.success("Product Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });

        localStorage.removeItem("product-state");

        navigate(-2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="edit-container" id="Product-Form-Conatiner">
      {editActive && (
        <>
          <div className="h-j">
            {type === "add" ? "Add Product" : "Edit Product"}
          </div>
          <div className="edit-div">
            <TabStrip
              activeTab={activeTab}
              onTabChange={onTabChange}
              data={dataArray}
              allowedTabId={0}
              areTabsDisabled={!id}
              key={id}
            />
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  pt: 2,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "120vh",
                }}
              >
                {_getComponent(activeTab)}
              </Box>
            </Box>

            {type !== "edit" && (
              <footer className="footer">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className={`btn btn-outline-dark px-4 mt-0 `}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    disabled={!id}
                    onClick={() => handleCreate()}
                  >
                    Create Product
                  </button>
                </div>
              </footer>
            )}
          </div>
        </>
      )}
      {screenWidth <= 900 && <EditBlock />}
    </div>
  );
};
export default Edit;
