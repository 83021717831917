// import React, { useEffect, useState } from "react";
// import Loader, { SmallLoader } from "../../../../components/Loader";
// import "./video.scss";
// import TypeDiv from "../../../../components/TypeDiv";
// import moment from "moment";
// import InputLayout from "../../../../components/InputLayout";
// import { htmlToText } from "../../../../helpers/htmlToText";

// const View = ({ data }) => {
//   const [loading, setLoading] = useState(true);

//   const renderText = (text) => (text ? htmlToText(text) : "--");

//   const getEmbedUrl = (link) => {
//     const youtubeRegex =
//       /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
//     const vimeoRegex = /vimeo\.com\/(?:video\/)?(\d+)/;

//     const youtubeMatch = link.match(youtubeRegex);
//     const vimeoMatch = link.match(vimeoRegex);

//     if (youtubeMatch) {
//       return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
//     } else if (vimeoMatch) {
//       return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
//     } else {
//       return null;
//     }
//   };

//   const embedUrl = getEmbedUrl(data?.link);

//   const handleLoad = () => {
//     setLoading(false);
//   };

//   return (
//     <div>
//       <div className="video-div">
//         {!loading && embedUrl ? (
//           <iframe
//             src={embedUrl}
//             title="Embedded Video"
//             frameBorder="0"
//             onLoad={handleLoad}
//             style={{ borderRadius: "10px" }}
//           ></iframe>
//         ) : (
//           <SmallLoader />
//         )}

//         <iframe
//           src={embedUrl}
//           title="Embedded Video"
//           frameBorder="0"
//           id="hidden-iframe"
//           onLoad={handleLoad}
//         ></iframe>
//       </div>

//       <div className="video-info-div mt-2">
//         <div className="details">
//           <h4>{data?.name}</h4>
//           {data?.featured && (
//             <TypeDiv
//               data={data?.featured && "Featured"}
//               given_color="#B5E4CA"
//             />
//           )}
//         </div>
//         <p className="view-muted">
//           {moment.utc(data?.date).format("DD MMM YYYY")}
//         </p>

//         <InputLayout classes="mt-2">
//           <label htmlFor="">
//             Transcript
//           </label>
//           <div
//             className="view-muted"
//             dangerouslySetInnerHTML={{ __html: data?.transcript || "--" }}
//           ></div>
//         </InputLayout>

//         {}

//         <InputLayout classes="mt-2">
//           <label htmlFor="">
//             Description
//           </label>
//           <div className="view-muted">{data?.description}</div>
//         </InputLayout>
//       </div>
//     </div>
//   );
// };

// export default View;

import React from 'react'

const View = () => {
  return (
    <div>View</div>
  )
}

export default View
