import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import MyComponent from ".";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Footer from "../../../molecules/Footer";
import Navbar from "../../../molecules/Navbar";
import "./styles.scss";

const Terms = () => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const [termsData, setTermsData] = useState([]);
  const getTermsData = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const terData = await getData({
        endpoint: "Settings/getPage?id=1",
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setTermsData(terData);
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t, getData, setTermsData]);

  useEffect(() => {
    getTermsData();
  }, [getTermsData]);

  const LastUpdatedDate = termsData[0]?.modified_date
    ? (() => {
        const date = new Date(termsData[0]?.modified_date);
        const day = date.getDate();
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "";
  return (
    <>
      <Navbar sideBarRequired={false} />
      <div className="conditions-container">
        <MyComponent selectedComponent="Terms" />
        <div className="conditions-container-header">
          <h1 className="conditions-container-header-heading">Terms Of Use</h1>
          <p className="conditions-container-header-date">
            Last Modified :{LastUpdatedDate}
          </p>
        </div>
        <div
          className="rich-text-editor-container shared_blog"
          dangerouslySetInnerHTML={{ __html: termsData[0]?.text }}
        ></div>
      </div>
      <Footer />
    </>
  );
};
export default Terms;
