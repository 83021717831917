import React, { useEffect, useState } from "react";
import { getFileName } from "../../../helpers/fileName";
import Upload from "../../Icons/Upload";
import Error from "../../atoms/Error";
import "./dropzone.scss";

interface CustomDropzoneProps {
  onFileSelect: (file: File) => void;
  adType: string;
  id: string;
  type: string;
  selectedFile?: File;
  maxFileSizeMB?: any;
}

const CustomDropzone: React.FC<CustomDropzoneProps> = ({
  onFileSelect,
  adType,
  id,
  type,
  selectedFile,
  maxFileSizeMB = 5,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null); // State for error message
  const [fileName, setFileName] = useState<string | null>(null);

  // Use useEffect to update the file state when the selectedFile prop changes
  useEffect(() => {
    if (selectedFile) {
      setFileName(getFileName(selectedFile));
      setFile(selectedFile);
    }
  }, [selectedFile]);

  let allowedFileTypes: string[] | null = null;
  if (adType === "Document") {
    allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "text/csv",
    ];
  } else if (adType === "Excel") {
    allowedFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
  } else if (adType === "Audio") {
    allowedFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"]; // Add more audio formats as needed
  } else if (adType === "Pdf") {
    allowedFileTypes = ["application/pdf"];
  } else if (adType === "Others") {
    allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/csv",
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
    ];
  } else if (adType === "Video") {
    allowedFileTypes = [
      "video/mp4",
      "video/x-msvideo", // AVI
      "video/x-matroska", // MKV
      "video/quicktime", // MOV
      "video/webm", // WebM
      "video/mpeg", // MPEG
    ];
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    const droppedFile = e.dataTransfer.files[0];
    validateAndSetFile(droppedFile);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0];
    validateAndSetFile(selectedFile);
  };

  const validateAndSetFile = (file: File) => {
    if (isFileTypeAllowed(file.type) && isFileSizeAllowed(file.size)) {
      setFile(file);
      setFileName(file.name);
      setError(null); // Reset error state
      readFileContents(file);
    } else {
      setError(generateErrorMessage(file.type, file.size));
      setFile(null); // Clear the file state
      setFileName(null);
    }
  };

  const isFileTypeAllowed = (fileType: string) => {
    return allowedFileTypes ? allowedFileTypes?.includes(fileType) : true;
  };

  const isFileSizeAllowed = (fileSize: number) => {
    const maxSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes
    return fileSize <= maxSizeBytes;
  };

  const generateErrorMessage = (fileType: string, fileSize: number) => {
    let errorMessage = "File type or size not allowed.";
    if (!isFileTypeAllowed(fileType)) {
      errorMessage = "File type not supported. Please upload a valid File.";
    } else if (!isFileSizeAllowed(fileSize)) {
      errorMessage = `File size must be less than ${maxFileSizeMB} MB.`;
    }
    return errorMessage;
  };

  const readFileContents = (file: File) => {
    const reader = new FileReader();
    if (type === "aum") {
      onFileSelect(file);
    } else {
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const fileContents = e.target!.result;
        // console.log("File contents:", fileContents);
        // onFileSelect(file); // Emit the file contents to the parent component
      };
      reader.readAsDataURL(file);
      onFileSelect(file);
    }
  };

  const handleClick = () => {
    const inputElement = document.querySelector<HTMLInputElement>(
      `#dropzone-${id}-file-input`
    );
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div
      className={`dropzone ${isDragOver ? "drag-over" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
      key={id}
    >
      <input
        type="file"
        id={`dropzone-${id}-file-input`}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <i className="">
        <Upload className="upload-insights-icon" />
      </i>
      <p className="p-dark mb-0">
        Drag drop a file here, or click to select a file
      </p>
      <p className="p-dark mb-0">Upto {maxFileSizeMB} Mb is allowed </p>
      {error && <Error error={error} />}
      {file && !error && (
        <span className="p-dark">
          <b>Selected File :</b>
          &nbsp;{fileName}
        </span>
      )}
    </div>
  );
};

export default CustomDropzone;
