import Default from "../../../../assets/images/default.png";
import Heading from "../../../atoms/Heading";
import Lock from "../../../pages/dashboard/Investors/requirnment/Icons/Lock";
import "./PortfolioCard.scss";
export const PortCards = ({
  CardPic,
  CardHeading,
  CardDate,
  PicContent,
  subscribed,
  onCardClick,
}) => {
  return (
    <div className="Portfolio-Insight-Single-Card" onClick={onCardClick}>
      <div
        className="Portfolio-Insight-Single-Card-Pic mb-3"
        style={{ maxWidth: "100%", overflow: "hidden" }}
      >
        <img src={CardPic || Default} alt="Card Picture"></img>
      </div>

      <h1>{CardHeading}</h1>
      <span>{CardDate}</span>
      <span className="Portfolio-content-pic">{PicContent}</span>
    </div>
  );
};
const PortfolioCard = ({
  Heading: heading,
  HeadingContent,
  children,
  subscribed,
  onClick,
}) => {
  const handleClick = () => {
    if (!subscribed) {
      onClick();
    } else {
      return;
    }
  };
  return (
    <div className="Portfolio-Insight-Card-Container" onClick={handleClick}>
      <div className="Portfolio-header">
        <div className="Portfolio-heading-information">
          <div className="mb-1">
            <Heading type="sub" title={heading} />
          </div>

          <span>{HeadingContent}</span>
        </div>
        {!subscribed && (
          <button>
            <Lock />
          </button>
        )}
      </div>
      <div className="Portfolio-Insight-Cards-inner mt-2">{children}</div>
    </div>
  );
};

export default PortfolioCard;
