import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomeButton";
import ImageIcon from "../../../../../atoms/ImageIcon";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import AddChannel from "./AddChannel";

import { useParams } from "react-router-dom";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import EditBlue from "../../../../../Icons/EditBlue";
import Social from "../../../../../Icons/Socials";
import EditChannel from "./EditChannel";
import "./styles.scss";

const Socials = ({ type }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [socials, setSocials] = useState();
  const [cookies] = useCookies(["t", "cid"]);

  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    formData: null, // Set initial state to null
    isEditModalOpen: false,
    isDeleteModalOpen: false,
  });

  const fetchSocialsData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/getPeopleAllChannel",
      params: { people_id: id, page: 1, page_limit: 1000 },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setSocials(res);
    }
  };

  useEffect(() => {
    fetchSocialsData();
  }, []);

  const handleView = () => {};

  const deleteSocial = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.type,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteSocial("Company/deleteCompanyChannel", "Social", {
        channel_id: modalState.modalId,
        user_role: "Customer",
      });
      fetchSocialsData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // Hnadling Add modals
  const openModal = (title, formData, id) => {
    const modalId = uuidv4();
    setModalState({
      modalId: modalId,
      modalTitle: title,
      isModalOpen: true,
      formData: formData,
    });
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const openEditModal = (title, formData, id) => {
    const modalId = id !== undefined ? id : uuidv4();
    setModalState({
      modalId: modalId,
      modalTitle: title,
      isModalOpen: false,
      formData: formData,
      isEditModalOpen: true,
    });
  };

  const closeEditModal = () => {
    setModalState((prev) => ({ ...prev, isEditModalOpen: false }));
  };

  const handleEdit = (data) => {
    openEditModal("Edit Socials", data, data.id);
  };

  return (
    <div>
      {socials?.data.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bolder">Social</h4>
            {type !== "view" && (
              <CustomButton
                text="Add Socials"
                icon={<Add />}
                handleClick={() => openModal("Add Social")}
                // handleClick={() => {}}
                type="btn-primary"
              />
            )}
          </div>

          {socials?.data.map((channel, index) => (
            <div key={channel.id} className="social-info-div">
              <div className="d-flex align-items-center w-60">
                <ImageIcon type={channel.type} />

                <div className="d-flex flex-column">
                  <span className="ms-3">{channel.type}</span>
                  <p className="p medium ms-3 cursor-pointer">{channel.link}</p>
                </div>
              </div>

              {type !== "view" && (
                <div>
                  <IconButton type="button" onClick={() => handleEdit(channel)}>
                    <i className="">
                      <EditBlue className="edit-blue-icon" />
                    </i>
                  </IconButton>

                  {/* <IconButton
                    type="button"
                    onClick={() => handleDelete(channel)}
                    className="ms-2"
                  >
                    <i className="fi fi-rr-trash actions-icon-custom"></i>
                  </IconButton> */}
                </div>
              )}
            </div>
          ))}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Social Channel Added Yet!"
          icon={<Social />}
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Social Channel Added Yet!"
          subtitle="To add channel, tap the add channel button."
          icon={<Social />}
          handleClick={() => openModal("Add Socials")}
          // handleClick={() => {}}
          tag="Add Socials"
        />
      )}

      {modalState.isModalOpen && (
        <Modal
          modalId={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.modalTitle}
          width="642px"
          overflowStyle={{ overflow: "visible" }}
          type="visible"
        >
          <AddChannel
            type="company"
            onClose={closeModal}
            id={modalState.modalId}
            initialData={modalState.formData}
            onDelete={() => {}}
            onAdded={() => fetchSocialsData()}
          />
        </Modal>
      )}

      {modalState.isEditModalOpen && (
        <Modal
          modalId={modalState.modalId}
          isActive={modalState.isEditModalOpen}
          onClose={closeEditModal}
          title={modalState.modalTitle}
          width="642px"
          overflowStyle={{ overflow: "visible" }}
          type="visible"
        >
          <EditChannel
            type="company"
            onClose={closeEditModal}
            id={modalState.modalId}
            initialData={modalState.formData}
            onDelete={() => {}}
            onUpdate={() => fetchSocialsData()}
          />
        </Modal>
      )}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Social"
          onClick={handleDeletion}
        />
      )}
    </div>
  );
};

export default Socials;
