import { patchData, patchFormData, postData, postFormData } from ".";
import axios from "../axios";

export const editProductConfiguration = async (data, token) => {
  const res = await patchData({
    endpoint: "Product/editProduct",
    data: data,
    params: {},
    token: token,
  });
  return res;
};

export const addProductConfiguration = async (data, token) => {
  const res = await postData({
    endpoint: "Product/addProduct",
    data: data,
    params: {},
    token: token,
  });

  return res;
};

export const addProductDataRoom = async (data, token) => {
  try {
    const response = await postFormData({
      endpoint: "Product/addDataRoom",
      token: token,
      params: {},
      data: data,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const editProductDataRoom = async (data, token) => {
  try {
    const res = await patchFormData({
      endpoint: "Product/editDataRoomData",
      data: data,
      params: {},
      token: token,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addProductVideo = async (data, token) => {
  try {
    const response = await postData({
      endpoint: "Product/addProductVideo",
      data: data,
      params: {},
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const editProductVideo = async (data, token) => {
  try {
    const res = await patchFormData({
      endpoint: "Product/editProductVideo",
      data: data,
      params: {},
      token: token,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editVideoStatus = async (data, token) => {
  try {
    const response = await patchData({
      endpoint: "Product/editVideoIsFeaturedAndIsMaster",
      params: {},
      data: data,
      token: token,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getCompanyDetail = async (id, token) => {
  try {
    const response = await axios.get("Company/getCompanyDetails", {
      params: { company_id: id },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data[0];
  } catch (error) {
    console.log(error);
  }
};
