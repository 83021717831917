export const modifyDuplicateLabels = (
  items,
  labelKey = "label",
  identifierKey = "structure_name"
) => {
  // Step 1: Count occurrences of each label
  const labelCount = items.reduce((acc, item) => {
    acc[item[labelKey]] = (acc[item[labelKey]] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Map over the items to modify labels if duplicates are found
  const modifiedItems = items.map((item) => ({
    ...item,
    [labelKey]:
      labelCount[item[labelKey]] > 1
        ? `${item[labelKey]} (${item[identifierKey]})`
        : item[labelKey],
  }));

  return modifiedItems;
};
