import { useState } from "react";
import Image from "../../../assets/images/bg-image.png";
import ContentContainer from "../../atoms/ContentContainer";
import Heading from "../../atoms/Heading";
import Search from "../../atoms/Search";
// import Footer from "../../molecules/Footer";
// import Navbar from "../../molecules/Navbar";
import FaqTemplate from "../../templates/FaqTemplate";
import HeroSection from "../../templates/InfoPage/HeroSection/HeroSection";

const FAQ = (props) => {
  // State to store the latest search value
  const [searchedValue, setSearchedValue] = useState("");

  // Function to handle search emit
  const handleSearchEmit = (searchValue) => {
    // Update the state with the latest search value
    setSearchedValue(searchValue);
  };

  return (
    <>
      {/* <Navbar sideBarRequired={false} /> */}
      <ContentContainer id="FAQ-Container">
        <Heading title="Frequently Asked Questions" type="main">
          {/* Pass the onSearchEmit handler to the Search component */}
          <Search onSearchEmit={handleSearchEmit} variant="search_lg" />
        </Heading>
        {/* <BannerImage altText="text" imageUrl={Image} /> */}
        <HeroSection
          ImagePic={Image}
          Heading={
            <>
              Have questions? <br />
              Explore the most common inquiries below.
            </>
          }
          HeadingStyle={true}
        />
        <div className="py-5">
          {/* Pass the searchedValue as a prop to FaqTemplate */}
          <FaqTemplate
            type="all"
            color="#ffffff"
            searchedValue={searchedValue}
          />
        </div>
      </ContentContainer>
      {/* <Footer /> */}
    </>
  );
};

export default FAQ;
