import CloseBtnIcom from "../../molecules/Modal/CloseBtnIcon";

const CloseButton = ({ onClose, className, style }) => {
  return (
    <div className={className} onClick={onClose} style={style}>
      <i className="d-flex">
        <CloseBtnIcom />
      </i>
    </div>
  );
};

export default CloseButton;
