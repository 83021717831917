import { Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { modifyDuplicateLabels } from "../../../../../helpers/modifyDuplicateLabels";
import { getData } from "../../../../../services";
import {
  getGeography,
  getSector,
  getStructure,
  getTypeById,
} from "../../../../../services/editHelper";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import Add from "../../../../Icons/Add";
import CustomButton from "../../../../atoms/CustomeButton";
import SelectDropdown from "../../../../atoms/Dropdown";
import Error from "../../../../atoms/Error";
import InputLayout from "../../../../atoms/InputLayout";
import {
  addInvestorPreference,
  addPeopleInvestorPreference,
  editInvestmentDetail,
  editPeopleInvestmentDetail,
  getInvestmentDataById,
  getPeopleInvestmentDataById,
} from "../helper";
import "../styles.scss";

function convertEmptyStringToNull(obj) {
  if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (obj[key] === "") {
        obj[key] = null;
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          obj[key][index] = convertEmptyStringToNull(item);
        });
      } else if (typeof obj[key] === "object") {
        obj[key] = convertEmptyStringToNull(obj[key]);
      }
    }
  }
  return obj;
}

const transformData = (data) => {
  // Create an object to group the data
  const grouped = data.reduce((acc, { value, label, structure_name }) => {
    // If the label is not in the accumulator, initialize it
    if (!acc[label]) {
      acc[label] = { value: [], structure_name: [] };
    }
    // Push the value and structure_name into their respective arrays
    acc[label].value.push(value);
    acc[label].structure_name.push(structure_name);
    return acc;
  }, {});

  // Convert the grouped object back to an array
  return Object.entries(grouped).map(([label, { value, structure_name }]) => {
    // If there's only one value, return the original structure
    return {
      value: value,
      label: label,
      structure_name:
        structure_name.length > 1 ? structure_name : structure_name[0],
    };
  });
};

const validationSchema = Yup.object().shape({
  prefrences: Yup.array().of(
    Yup.object().shape({
      // product_structure: Yup.ara().required("Product Structure is required"),
      product_type: Yup.array().required("Product Type is required"),
      product_strategy: Yup.array().nullable(),
      product_sector: Yup.array().nullable(),
      product_geography: Yup.array().nullable(),
      min_track_record: Yup.number()
        .integer("Min Track Record must be a whole number")
        .min(0)
        .nullable(),
      max_track_record: Yup.number().when(
        "min_track_record",
        (min_track_record, schema) =>
          min_track_record &&
          schema
            .integer("Max Track Record must be a whole number")
            .min(
              min_track_record,
              "Max Track Record must be greater than or equal to Min Track Record"
            )
            .nullable()
      ),
      min_aum: Yup.number()
        .integer("Min AUM must be a whole number")
        .min(0)
        .nullable(),
      max_aum: Yup.number().when(
        "min_aum",
        (min_aum, schema) =>
          min_aum &&
          schema
            .integer("Max AUM must be a whole number")
            .min(min_aum, "Max AUM must be greater than or equal to Min AUM")
            .nullable()
      ),
      average_ticket_size: Yup.number()
        .min(0, "Average Ticket Size must be greater than 0")
        .nullable(),
    })
  ),
});

const InvestorPrefrence = ({ investmentType }) => {
  const { iid, people_id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies] = useCookies(["t", "cid"]);

  const [investmentData, setInvestmentData] = useState(null);
  const [type, setType] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [strategy, setStrategy] = useState([]);
  const [geography, setGeography] = useState([]);
  const [structureData, setStructureData] = useState([]);
  const [sector, setSector] = useState([]);
  const [structure, setStructure] = useState([]);

  //   const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchStructureData = async () => {
    const res = await getStructure(cookies.t);

    if (res) {
      setStructureData(res);
    }
  };

  useEffect(() => {
    fetchStructureData();
    fetchGeographyAndSector();
    fetchInvestmentData();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "Taxonomy/getAllTypeList",
        params: { status: false },
        token: cookies.t,
      });

      const newRes = transformData(res);
      setAllTypes(newRes);
    })();
  }, []);

  const fetchType = async (option, index) => {
    const data = await getTypeById(option);
    setType((prevTypes) => {
      const updatedTypes = [...prevTypes];
      updatedTypes[index] = data;

      // console.log("updated-types", updatedTypes);
      return updatedTypes;
    });
  };

  const fetchStrategy = async (option, index) => {
    const data = await getData({
      endpoint: "Taxonomy/getStrategyByMultipleType",
      params: { type_ids: JSON.stringify(option) },
    });

    const distinctData = modifyDuplicateLabels(data);

    setStrategy((prevStrategies) => {
      const updatedStrategies = [...prevStrategies];
      updatedStrategies[index] = distinctData;
      return updatedStrategies;
    });
  };

  const fetchGeographyAndSector = async () => {
    const gdata = await getGeography();
    setGeography(gdata);
    const sdata = await getSector();
    setSector(sdata);
  };

  const fetchInvestmentData = async () => {
    let res;
    if (investmentType === "company") {
      dispatch(setLoading(true));
      res = await getInvestmentDataById(iid);
      dispatch(setLoading(false));
    } else if (investmentType === "people") {
      dispatch(setLoading(true));
      res = await getPeopleInvestmentDataById(iid);
      dispatch(setLoading(false));
    }

    setInvestmentData(res[0]);
  };

  const fetchRequiredData = async (investmentData) => {
    await findStructure({
      value: investmentData?.type_name?.[0] || null,
      index: 0,
    });
    await fetchType(investmentData?.product_structure, 0);
    await fetchStrategy(investmentData?.product_type, 0);
    // await fetchGeographyAndSector();
  };

  useEffect(() => {
    if (investmentData && allTypes.length) {
      fetchRequiredData(investmentData);
    }
  }, [investmentData, allTypes]);

  const initialValues = {
    prefrences: [
      {
        product_structure: investmentData?.product_structure || "",
        product_type: investmentData?.product_type || "",
        product_strategy: investmentData?.product_strategy || "",
        product_sector: investmentData?.sector || "",
        product_geography: investmentData?.geography || "",
        min_track_record: investmentData?.min_track_record || null,
        max_track_record: investmentData?.max_track_record || null,
        min_aum: investmentData?.min_aum || null,
        max_aum: investmentData?.max_aum || null,
        seed: investmentData?.seed || false,
        user_role: "Customer",
        look_at_prelaunch_fund: investmentData?.prelaunch || false,
        first_loss_capital: investmentData?.first_loss_capital || false,
        average_ticket_size: investmentData?.average_ticket_size || null,
      },
    ],
  };

  const handleSubmit = async (values) => {
    if (iid) {
      const dataToSend = {
        id: iid,
        ...values.prefrences[0],
      };

      for (const key in dataToSend) {
        if (dataToSend[key] === "") {
          dataToSend[key] = null;
        }
      }

      let res;
      try {
        if (investmentType === "company") {
          dispatch(setLoading(true));
          res = await editInvestmentDetail(dataToSend, cookies.t);
          dispatch(setLoading(false));
        } else if (investmentType === "people") {
          dispatch(setLoading(true));
          res = await editPeopleInvestmentDetail(dataToSend, cookies.t);
          dispatch(setLoading(false));
        }
        // console.log(res);
        if (res.status) {
          toast.success("Investment Preference Edited Succesfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let dataToSend;
      if (investmentType === "company") {
        dataToSend = {
          company_id: cookies.cid,
          ...convertEmptyStringToNull(values),
        };
      } else {
        dataToSend = {
          people_id: people_id,
          ...convertEmptyStringToNull(values),
        };
      }
      let res;
      try {
        if (investmentType === "company") {
          dispatch(setLoading(true));
          res = await addInvestorPreference(dataToSend, cookies.t);
          dispatch(setLoading(false));
        } else if (investmentType === "people") {
          dispatch(setLoading(true));
          res = await addPeopleInvestorPreference(dataToSend, cookies.t);
          dispatch(setLoading(false));
        }

        if (res) {
          toast.success("Investment Preference added succesfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateStructure = async (data, index) => {
    setStructure((prevStructure) => {
      const updatedStructure = [...prevStructure];
      updatedStructure[index] = data;

      return updatedStructure;
    });
  };

  const findStructure = ({ value, index, setFieldValue }) => {
    const valuesArray = Array.isArray(value) ? value : [value];

    const allStructureIds = [];

    valuesArray.forEach((val) => {
      const find = allTypes.find((item) => item.label === val);
      const structures = find?.structure_name;
      if (!structures) {
        return; // Skip to the next value if none found
      }

      // Find the corresponding IDs for the structure names
      let structure_ids;
      let structureArray = [];
      if (Array.isArray(structures)) {
        structure_ids = structures.map((structure) => {
          const foundItem = structureData.find(
            (item) => item.label === structure
          );
          if (foundItem) {
            structureArray.push(foundItem);
          }
          return foundItem ? foundItem.value : null; // Return the ID or null if not found
        });
      } else {
        const foundItem = structureData.find(
          (item) => item.label === structures
        );
        if (foundItem) {
          structureArray.push(foundItem);
        }
        structure_ids = foundItem ? [foundItem.value] : null;
      }

      structureArray = Array.from(
        new Set(structureArray.map((item) => item.value))
      ).map((value) => structureData.find((item) => item.value === value));

      updateStructure(structureArray, index);

      if (structure_ids) {
        allStructureIds.push(...structure_ids);
      }
    });

    // Set the structure IDs or `null` if no structure IDs were found
    if (setFieldValue) {
      setFieldValue(
        `prefrences[${index}].product_structure`,
        allStructureIds.length ? allStructureIds : null
      );
    }
  };

  return (
    <Formik
      key={Math.random}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      // validateOnChange
      validateOnBlur
      enableReinitialize
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleBlur,
        values,
      }) => (
        <>
          <div
            className="investment-preference-view"
            id="Investment-Preference-Container"
          >
            <FieldArray name="prefrences">
              {({ push, remove }) => (
                <>
                  <div className="d-flex justify-content-between mb-4">
                    <h1 className="h-j">
                      {iid
                        ? "Edit Investment Preference"
                        : "Add Investment Preference"}
                    </h1>

                    {!iid && (
                      <button
                        className={`btn btn-primary px-4 mt-0 `}
                        onClick={() => push(dataObj)}
                      >
                        <i className={`d-flex me-2`}>
                          <Add />
                        </i>
                        Add Prefrences
                      </button>
                    )}
                  </div>

                  {values.prefrences.map((preference, index) => (
                    <>
                      {iid ? (
                        <>
                          <div classes="p-4 pt-3 mb-4">
                            <form style={{ width: "100%" }}>
                              <div
                                className="row g-3"
                                style={{ width: "100%" }}
                              >
                                <InputLayout style={{ width: "33%" }}>
                                  <label
                                    className="required-field"
                                    htmlFor="Product Type"
                                  >
                                    Product Type
                                  </label>
                                  <SelectDropdown
                                    data={allTypes}
                                    onChange={async (option) => {
                                      findStructure({
                                        value: option?.label,
                                        setFieldValue,
                                        index,
                                      });

                                      setFieldValue(
                                        `prefrences[${index}].product_strategy`,
                                        null
                                      );

                                      setFieldValue(
                                        `prefrences[${index}].product_type`,
                                        option?.value
                                      );

                                      fetchStrategy(option?.value, index);
                                    }}
                                    onClear={() =>
                                      setFieldValue(
                                        `prefrences[${index}].product_type`,
                                        ""
                                      )
                                    }
                                    placeholder="Select Product Type"
                                    selectedValue={
                                      values.prefrences[index]?.product_type
                                    }
                                    format="all"
                                  />
                                </InputLayout>

                                <InputLayout style={{ width: "33%" }}>
                                  <label
                                    className="required-field"
                                    htmlFor="Product Structure"
                                  >
                                    Product Structure
                                  </label>
                                  <SelectDropdown
                                    data={structure[index]}
                                    onChange={async (option) => {
                                      setFieldValue(
                                        `prefrences[${index}].product_structure`,
                                        option
                                      );
                                      // setFieldValue(
                                      //   `prefrences[${index}].product_type`,
                                      //   null
                                      // );
                                      // setFieldValue(
                                      //   `prefrences[${index}].product_strategy`,
                                      //   null
                                      // );
                                      // fetchType(option, index);
                                    }}
                                    onClear={() => {
                                      setFieldValue(
                                        `prefrences[${index}].product_structure`,
                                        ""
                                      );
                                    }}
                                    placeholder="Select Product Structure"
                                    selectedValue={
                                      values.prefrences[index]
                                        ?.product_structure
                                    }
                                    multiSelect
                                  />
                                </InputLayout>

                                <InputLayout style={{ width: "33%" }}>
                                  <label htmlFor="Product Strategy">
                                    Product Strategy
                                  </label>
                                  <SelectDropdown
                                    data={strategy[index] || []}
                                    onChange={async (option) => {
                                      setFieldValue(
                                        `prefrences[${index}].product_strategy`,
                                        option
                                      );
                                    }}
                                    multiSelect={true}
                                    onClear={() =>
                                      setFieldValue(
                                        `prefrences[${index}].product_strategy`,
                                        ""
                                      )
                                    }
                                    placeholder="Select Product Strategy"
                                    selectedValue={
                                      values.prefrences[index]?.product_strategy
                                    }
                                  />
                                </InputLayout>
                              </div>

                              <div
                                className="row g-3 mt-2"
                                style={{ width: "100%" }}
                              >
                                <InputLayout style={{ width: "33%" }}>
                                  <label htmlFor="Product Geography">
                                    Product Geography
                                  </label>
                                  <SelectDropdown
                                    data={geography}
                                    onChange={async (option) => {
                                      setFieldValue(
                                        `prefrences[${index}].product_geography`,
                                        option
                                      );
                                    }}
                                    multiSelect={true}
                                    onClear={() =>
                                      setFieldValue(
                                        `prefrences[${index}].product_geography`
                                      )
                                    }
                                    selectedValue={investmentData?.geography}
                                    placeholder="Select Geography"
                                  />
                                </InputLayout>

                                <InputLayout style={{ width: "33%" }}>
                                  <label htmlFor="Product Sector">
                                    Product Sector
                                  </label>
                                  <SelectDropdown
                                    data={sector}
                                    onChange={(option) =>
                                      setFieldValue(
                                        `prefrences[${index}].product_sector`,
                                        option
                                      )
                                    }
                                    onClear={() =>
                                      setFieldValue(
                                        `prefrences[${index}].product_sector`,
                                        ""
                                      )
                                    }
                                    placeholder="Select Sector"
                                    multiSelect={true}
                                    selectedValue={investmentData?.sector}
                                  />
                                </InputLayout>

                                <InputLayout style={{ width: "33%" }}>
                                  <label htmlFor="AUM">
                                    AUM Threshold ($mm)
                                  </label>
                                  <div
                                    className="row g-3"
                                    style={{ width: "100%" }}
                                  >
                                    <InputLayout classes="col-6">
                                      <Field
                                        type="number"
                                        name={`prefrences[${index}].min_aum`}
                                        placeholder="Min AUM ($mm)"
                                        step="1"
                                      />
                                      {touched?.prefrences?.[index]?.min_aum &&
                                        errors?.prefrences?.[index]
                                          ?.min_aum && (
                                          <Error
                                            error={
                                              errors.prefrences[index].min_aum
                                            }
                                          />
                                        )}
                                    </InputLayout>
                                    <InputLayout
                                      style={{
                                        width: "50%",
                                        paddingRight: "0",
                                      }}
                                      classes="col-6"
                                    >
                                      <Field
                                        type="number"
                                        name={`prefrences[${index}].max_aum`}
                                        placeholder="Max AUM ($mm)"
                                        step="1"
                                      />
                                      {touched?.prefrences?.[index]?.max_aum &&
                                        errors?.prefrences?.[index]
                                          ?.max_aum && (
                                          <Error
                                            error={
                                              errors.prefrences[index].max_aum
                                            }
                                          />
                                        )}
                                    </InputLayout>
                                  </div>
                                </InputLayout>
                              </div>

                              <div
                                className="row g-3 mt-2"
                                style={{ width: "100%" }}
                              >
                                <InputLayout style={{ width: "33%" }}>
                                  <label htmlFor="Track Record">
                                    Track Record
                                  </label>
                                  <div
                                    className="row g-3"
                                    style={{ width: "100%" }}
                                  >
                                    <InputLayout classes="col-6">
                                      <Field
                                        type="number"
                                        name={`prefrences[${index}].min_track_record`}
                                        placeholder="Min track record"
                                        step="1"
                                      />
                                      {errors?.prefrences?.[index]
                                        ?.min_track_record && (
                                        <Error
                                          error={
                                            errors.prefrences[index]
                                              .min_track_record
                                          }
                                        />
                                      )}
                                    </InputLayout>
                                    <InputLayout classes="col-6">
                                      <Field
                                        type="number"
                                        name={`prefrences[${index}].max_track_record`}
                                        placeholder="Max Track Record"
                                        step="1"
                                      />
                                      {errors?.prefrences?.[index]
                                        ?.max_track_record && (
                                        <Error
                                          error={
                                            errors.prefrences[index]
                                              .max_track_record
                                          }
                                        />
                                      )}
                                    </InputLayout>
                                  </div>
                                </InputLayout>

                                <InputLayout style={{ width: "33%" }}>
                                  <label>Average Ticket Size (US$MM)</label>
                                  <Field
                                    type="number"
                                    name={`prefrences[${index}].average_ticket_size`}
                                    placeholder="Average Ticket Size (US$MM)"
                                    step="1"
                                  />
                                  {errors?.prefrences?.[index]
                                    ?.average_ticket_size && (
                                    <Error
                                      error={
                                        errors.prefrences[index]
                                          .average_ticket_size
                                      }
                                    />
                                  )}
                                </InputLayout>

                                <div
                                  className="col-md"
                                  style={{ width: "33%" }}
                                ></div>
                              </div>

                              <div className="row g-3 mt-2">
                                <div
                                  className="col-md mt-0"
                                  style={{ width: "33%" }}
                                >
                                  <div className="form-group">
                                    <label htmlFor="switch"></label>
                                    <div className="add-page-switch mt-3">
                                      <label htmlFor="Sedd" className="mb-0">
                                        Seed
                                      </label>
                                      <div className="form-switch">
                                        {values.prefrences[index].seed
                                          ? "Yes"
                                          : "No"}
                                        <input
                                          className="form-check-input ms-3"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                          name={`prefrences[${index}].seed`}
                                          checked={
                                            values.prefrences[index].seed
                                          }
                                          value={values.prefrences[index].seed}
                                          onChange={(e) => {
                                            values.prefrences[index].seed =
                                              !values.prefrences[index].seed;
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md mt-0"
                                  style={{ width: "33%" }}
                                >
                                  <div className="form-group">
                                    <label htmlFor="pre-launch switch"> </label>
                                    <div className="add-page-switch mt-3">
                                      <label
                                        htmlFor="Pre-Launch"
                                        className="mb-0"
                                      >
                                        Will look at pre-launch funds?
                                      </label>
                                      <div className="form-switch">
                                        {values.prefrences[index]
                                          .look_at_prelaunch_fund
                                          ? "Yes"
                                          : "No"}
                                        <input
                                          className="form-check-input ms-3"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                          name={`prefrences[${index}].look_at_prelaunch_fund`}
                                          checked={
                                            values.prefrences[index]
                                              .look_at_prelaunch_fund
                                          }
                                          value={
                                            values.prefrences[index]
                                              .look_at_prelaunch_fund
                                          }
                                          onChange={(e) => {
                                            values.prefrences[
                                              index
                                            ].look_at_prelaunch_fund =
                                              !values.prefrences[index]
                                                .look_at_prelaunch_fund;
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md mt-0"
                                  style={{ width: "33%" }}
                                >
                                  <div className="form-group">
                                    <label htmlFor=""></label>
                                    <div className="add-page-switch mt-3">
                                      <label htmlFor="" className="mb-0">
                                        First Loss Capital
                                      </label>
                                      <div className="form-switch">
                                        {values.prefrences[index]
                                          .first_loss_capital
                                          ? "Yes"
                                          : "No"}
                                        <input
                                          className="form-check-input ms-3"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                          name={`prefrences[${index}].first_loss_capital`}
                                          checked={
                                            values.prefrences[index]
                                              .first_loss_capital
                                          }
                                          value={
                                            values.prefrences[index]
                                              .first_loss_capital
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `prefrences[${index}].first_loss_capital`,
                                              !values.prefrences[index]
                                                .first_loss_capital
                                            );
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={index}>
                            <div
                              className="accordion "
                              id={`accordionExample${index}`}
                            >
                              <div
                                className="accordion-item"
                                style={{ position: "relative" }}
                              >
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}
                                  style={{ display: "flex" }}
                                >
                                  {!iid && (
                                    <>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4
                                          className="normal-heading"
                                          style={{
                                            border: "none",
                                            marginBottom: "0",
                                            alignItems: "center",
                                            marginLeft: "25px",
                                            minWidth: "max-content",
                                          }}
                                        >
                                          {`Investment Preference ${index + 1}`}
                                        </h4>
                                        {index !== 0 && (
                                          <span
                                            className="cursor-pointer error-div remove-feild inv-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              remove(index);
                                            }}
                                          >
                                            Remove
                                          </span>
                                        )}
                                      </div>
                                      {/* <hr className="mt-0" /> */}
                                    </>
                                  )}
                                  <button
                                    className={`accordion-button ${
                                      index === 0 ? "" : "collapsed"
                                    }`}
                                    // style={{width:'max-content'}}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse${index}`}
                                  ></button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className={`accordion-collapse collapse ${
                                    index === 0 ? "show" : ""
                                  }`}
                                  aria-labelledby={`heading${index}`}
                                >
                                  <div className="accordion-body">
                                    <div className="p-1 mb-4">
                                      <form style={{ width: "100%" }}>
                                        <div
                                          className="row g-3"
                                          style={{ width: "100%" }}
                                        >
                                          <InputLayout style={{ width: "33%" }}>
                                            <label className="required-field">
                                              Product Type
                                            </label>
                                            <SelectDropdown
                                              data={allTypes}
                                              onChange={async (option) => {
                                                // if(!values.prefrences[index].product_structure){
                                                findStructure({
                                                  value: option?.label,
                                                  setFieldValue,
                                                  index,
                                                });
                                                // }
                                                setFieldValue(
                                                  `prefrences[${index}].product_strategy`,
                                                  null
                                                );

                                                setFieldValue(
                                                  `prefrences[${index}].product_type`,
                                                  option?.value
                                                );

                                                fetchStrategy(
                                                  option?.value,
                                                  index
                                                );
                                              }}
                                              onClear={() =>
                                                setFieldValue(
                                                  `prefrences[${index}].product_type`,
                                                  ""
                                                )
                                              }
                                              placeholder="Select Product Type"
                                              selectedValue={
                                                values.prefrences[index]
                                                  ?.product_type
                                              }
                                              format="all"
                                            />
                                          </InputLayout>

                                          <InputLayout style={{ width: "33%" }}>
                                            <label>Product Structure</label>
                                            <SelectDropdown
                                              data={structure[index]}
                                              onChange={async (option) => {
                                                setFieldValue(
                                                  `prefrences[${index}].product_structure`,
                                                  option
                                                );
                                                // setFieldValue(
                                                //   `prefrences[${index}].product_type`,
                                                //   null
                                                // );
                                                // setFieldValue(
                                                //   `prefrences[${index}].product_strategy`,
                                                //   null
                                                // );
                                                // fetchType(option, index);
                                              }}
                                              onClear={() => {
                                                setFieldValue(
                                                  `prefrences[${index}].product_structure`,
                                                  ""
                                                );
                                              }}
                                              placeholder="Select Product Structure"
                                              selectedValue={
                                                values.prefrences[index]
                                                  ?.product_structure
                                              }
                                              multiSelect
                                            />
                                          </InputLayout>

                                          <InputLayout style={{ width: "33%" }}>
                                            <label>Product Strategy</label>
                                            <SelectDropdown
                                              data={strategy[index] || []}
                                              onChange={async (option) => {
                                                setFieldValue(
                                                  `prefrences[${index}].product_strategy`,
                                                  option
                                                );
                                              }}
                                              multiSelect={true}
                                              onClear={() =>
                                                setFieldValue(
                                                  `prefrences[${index}].product_strategy`,
                                                  ""
                                                )
                                              }
                                              placeholder="Select Product Strategy"
                                              selectedValue={
                                                values.prefrences[index]
                                                  ?.product_strategy
                                              }
                                            />
                                          </InputLayout>
                                        </div>

                                        <div
                                          className="row g-3 mt-2"
                                          style={{ width: "100%" }}
                                        >
                                          <InputLayout style={{ width: "33%" }}>
                                            <label>Product Geography</label>
                                            <SelectDropdown
                                              data={geography}
                                              onChange={async (option) => {
                                                setFieldValue(
                                                  `prefrences[${index}].product_geography`,
                                                  option
                                                );
                                              }}
                                              multiSelect={true}
                                              onClear={() =>
                                                setFieldValue(
                                                  `prefrences[${index}].product_geography`
                                                )
                                              }
                                              selectedValue={
                                                investmentData?.geography
                                              }
                                              placeholder="Select Geography"
                                            />
                                          </InputLayout>

                                          <InputLayout style={{ width: "33%" }}>
                                            <label>Product Sector</label>
                                            <SelectDropdown
                                              data={sector}
                                              onChange={(option) =>
                                                setFieldValue(
                                                  `prefrences[${index}].product_sector`,
                                                  option
                                                )
                                              }
                                              onClear={() =>
                                                setFieldValue(
                                                  `prefrences[${index}].product_sector`,
                                                  ""
                                                )
                                              }
                                              placeholder="Select Sector"
                                              multiSelect={true}
                                              selectedValue={
                                                investmentData?.sector
                                              }
                                            />
                                          </InputLayout>

                                          <InputLayout style={{ width: "33%" }}>
                                            <label>AUM Threshold ($mm)</label>
                                            <div
                                              className="row g-3"
                                              style={{ width: "101.5%" }}
                                            >
                                              <InputLayout
                                                style={{ width: "50%" }}
                                              >
                                                <Field
                                                  type="number"
                                                  name={`prefrences[${index}].min_aum`}
                                                  placeholder="Min AUM ($mm)"
                                                  step="1"
                                                />
                                                {touched?.prefrences?.[index]
                                                  ?.min_aum &&
                                                  errors?.prefrences?.[index]
                                                    ?.min_aum && (
                                                    <Error
                                                      error={
                                                        errors.prefrences[index]
                                                          .min_aum
                                                      }
                                                    />
                                                  )}
                                              </InputLayout>
                                              <InputLayout
                                                style={{
                                                  width: "50%",
                                                  paddingRight: "0",
                                                }}
                                              >
                                                <Field
                                                  type="number"
                                                  name={`prefrences[${index}].max_aum`}
                                                  placeholder="Max AUM ($mm)"
                                                  step="1"
                                                />
                                                {touched?.prefrences?.[index]
                                                  ?.max_aum &&
                                                  errors?.prefrences?.[index]
                                                    ?.max_aum && (
                                                    <Error
                                                      error={
                                                        errors.prefrences[index]
                                                          .max_aum
                                                      }
                                                    />
                                                  )}
                                              </InputLayout>
                                            </div>
                                          </InputLayout>
                                        </div>

                                        <div
                                          className="row g-3 mt-2"
                                          style={{ width: "100%" }}
                                        >
                                          <InputLayout style={{ width: "33%" }}>
                                            <label>Track Record</label>
                                            <div
                                              className="row g-3"
                                              style={{ width: "101.5%" }}
                                            >
                                              <InputLayout
                                                style={{ width: "50%" }}
                                              >
                                                <Field
                                                  type="number"
                                                  name={`prefrences[${index}].min_track_record`}
                                                  placeholder="Min track record"
                                                  step="1"
                                                />
                                                {errors?.prefrences?.[index]
                                                  ?.min_track_record && (
                                                  <Error
                                                    error={
                                                      errors.prefrences[index]
                                                        .min_track_record
                                                    }
                                                  />
                                                )}
                                              </InputLayout>
                                              <InputLayout
                                                style={{
                                                  width: "50%",
                                                  paddingRight: "0",
                                                }}
                                              >
                                                <Field
                                                  type="number"
                                                  name={`prefrences[${index}].max_track_record`}
                                                  placeholder="Max Track Record"
                                                  step="1"
                                                />
                                                {errors?.prefrences?.[index]
                                                  ?.max_track_record && (
                                                  <Error
                                                    error={
                                                      errors.prefrences[index]
                                                        .max_track_record
                                                    }
                                                  />
                                                )}
                                              </InputLayout>
                                            </div>
                                          </InputLayout>

                                          <InputLayout style={{ width: "33%" }}>
                                            <label>
                                              Average Ticket Size (US$MM)
                                            </label>
                                            <Field
                                              type="number"
                                              name={`prefrences[${index}].average_ticket_size`}
                                              placeholder="Average Ticket Size (US$MM)"
                                              step="1"
                                            />
                                            {errors?.prefrences?.[index]
                                              ?.average_ticket_size && (
                                              <Error
                                                error={
                                                  errors.prefrences[index]
                                                    .average_ticket_size
                                                }
                                              />
                                            )}
                                          </InputLayout>

                                          <div
                                            className="col-md"
                                            style={{ width: "33%" }}
                                          ></div>
                                        </div>

                                        <div
                                          className="row g-3 mt-0"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="col-md mt-0"
                                            style={{ width: "33%" }}
                                          >
                                            <div className="form-group">
                                              <label htmlFor=""></label>
                                              <div className="add-page-switch mt-3">
                                                <label
                                                  htmlFor=""
                                                  className="mb-0"
                                                >
                                                  Seed
                                                </label>
                                                <div className="form-switch">
                                                  {values.prefrences[index].seed
                                                    ? "Yes"
                                                    : "No"}
                                                  <input
                                                    className="form-check-input ms-3"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                    name={`prefrences[${index}].seed`}
                                                    checked={
                                                      values.prefrences[index]
                                                        .seed
                                                    }
                                                    value={
                                                      values.prefrences[index]
                                                        .seed
                                                    }
                                                    onChange={(e) => {
                                                      values.prefrences[
                                                        index
                                                      ].seed =
                                                        !values.prefrences[
                                                          index
                                                        ].seed;
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="col-md mt-0"
                                            style={{ width: "33%" }}
                                          >
                                            <div className="form-group">
                                              <label htmlFor=""> </label>
                                              <div className="add-page-switch mt-3">
                                                <label
                                                  htmlFor=""
                                                  className="mb-0"
                                                >
                                                  Will look at pre-launch funds?
                                                </label>
                                                <div className="form-switch">
                                                  {values.prefrences[index]
                                                    .look_at_prelaunch_fund
                                                    ? "Yes"
                                                    : "No"}
                                                  <input
                                                    className="form-check-input ms-3"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                    name={`prefrences[${index}].look_at_prelaunch_fund`}
                                                    checked={
                                                      values.prefrences[index]
                                                        .look_at_prelaunch_fund
                                                    }
                                                    value={
                                                      values.prefrences[index]
                                                        .look_at_prelaunch_fund
                                                    }
                                                    onChange={(e) => {
                                                      values.prefrences[
                                                        index
                                                      ].look_at_prelaunch_fund =
                                                        !values.prefrences[
                                                          index
                                                        ]
                                                          .look_at_prelaunch_fund;
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="col-md mt-0"
                                            style={{ width: "33%" }}
                                          >
                                            <div className="form-group">
                                              <label htmlFor=""></label>
                                              <div className="add-page-switch mt-3">
                                                <label
                                                  htmlFor=""
                                                  className="mb-0"
                                                >
                                                  First Loss Capital
                                                </label>
                                                <div className="form-switch">
                                                  {values.prefrences[index]
                                                    .first_loss_capital
                                                    ? "Yes"
                                                    : "No"}
                                                  <input
                                                    className="form-check-input ms-3"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                    name={`prefrences[${index}].first_loss_capital`}
                                                    checked={
                                                      values.prefrences[index]
                                                        .first_loss_capital
                                                    }
                                                    value={
                                                      values.prefrences[index]
                                                        .first_loss_capital
                                                    }
                                                    onChange={(e) => {
                                                      values.prefrences[
                                                        index
                                                      ].first_loss_capital =
                                                        !values.prefrences[
                                                          index
                                                        ].first_loss_capital;
                                                      handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                  <div style={{ marginTop: "auto" }}>
                    <footer className="footer">
                      <CustomButton
                        text="Back"
                        handleClick={() => {
                          if (dirty) {
                            const userConfirmed = window.confirm(
                              "You have unsaved changes. Are you sure you want to leave?"
                            );
                            if (!userConfirmed) {
                              return;
                            } else {
                              navigate(-1);
                            }
                          } else {
                            navigate(-1);
                          }
                        }}
                      />

                      <button
                        type="submit"
                        className={`btn btn-primary px-4 mt-0 `}
                        onClick={() => {
                          handleSubmit(values);
                        }}
                        disabled={iid ? !isValid : !isValid || !dirty}
                      >
                        {iid ? "Update" : "Add"}
                      </button>
                    </footer>
                  </div>
                </>
              )}
            </FieldArray>
          </div>
        </>
      )}
    </Formik>
  );
};

const dataObj = {
  product_structure: "",
  product_type: "",
  product_strategy: "",
  product_sector: "",
  product_geography: "",
  min_track_record: "",
  max_track_record: "",
  min_aum: "",
  max_aum: "",
  seed: false,
  look_at_prelaunch_fund: false,
};

export default InvestorPrefrence;
