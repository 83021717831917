import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import DeleteRed from "../../../../../Icons/DeleteRed";
import EditBlue from "../../../../../Icons/EditBlue";
import Location from "../../../../../Icons/Location";
import CustomButton from "../../../../../atoms/CustomeButton";
import IconCircle from "../../../../../atoms/IconCircle";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import AddAddress from "./AddAddress";
import "./styles.scss";

const Address = ({ type }) => {
  const [cookies] = useCookies(["cid", "t"]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const [addressData, setAddressData] = useState();

  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    formData: null, // Set initial state to null
  });

  const fetchAddressData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Company/getAllCompanyAddress",
      params: { company_id: cookies.cid },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    if (res) {
      setAddressData(res);
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);

  const handleView = () => {};

  const openModal = (title, formData, id) => {
    const modalId = id !== undefined ? id : uuidv4();
    setModalState({
      modalId: modalId,
      modalTitle: title,
      isModalOpen: true,
      formData: formData,
    });
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const deleteAddress = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.address_id,
      name: data.title,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteAddress("Company/deleteCompanyAddress", "Address", {
        address_id: modalState.modalId,
        user_role: "Customer",
      });
      fetchAddressData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (data) => {
    openModal("Edit Address", data, data.address_id);
  };

  // PAgination MaNAGEMENT
  const totalItems = addressData?.total_records;

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <div>
      {modalState.isModalOpen && (
        <Modal
          modalId={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.modalTitle}
          width="642px"
        >
          <AddAddress
            onClose={closeModal}
            id={modalState.modalTitle === "Add Address"}
            initialData={modalState.formData}
            onUpdate={() => fetchAddressData()}
          />
        </Modal>
      )}

      {addressData?.data.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bolder">Address</h4>
            {type != "view" && (
              <CustomButton
                text="Add Address"
                icon={<Add fill="white" />}
                handleClick={() => openModal("Add address")}
                // handleClick={() => {}}
                type="btn-primary"
              />
            )}
          </div>

          {addressData?.data.map((addres) => (
            <div className="address-info-div" key={addres.address_id}>
              <div className="d-flex align-items-start w-60">
                <IconCircle color="#B5E4CA" icon={<Location />} size="48px" />
                <div className="d-flex flex-column">
                  <span className="ms-3">{addres.title}</span>
                  <p className="p ms-3 text-muted mb-0">
                    {addres.address}&nbsp;
                    {addres?.address2 && `, ${addres.address2}`}
                    {addres.landmark && `, ${addres.landmark}`}
                    {addres.city && `, ${addres.city}`}
                    {addres.state_region && `, ${addres.state_region}`}
                    {addres.zip_code && `, ${addres.zip_code}`}
                    {addres.country && `, ${addres.country}`}
                  </p>
                </div>
              </div>

              {type !== "view" && (
                <div>
                  <IconButton type="button" onClick={() => handleEdit(addres)}>
                    <i className="actions-icon-custom">
                      <EditBlue className="edit-blue-icon" />
                    </i>
                  </IconButton>

                  <IconButton
                    type="button"
                    onClick={() => handleDelete(addres)}
                    className="ms-2"
                  >
                    <i className="actions-icon-custom">
                      <DeleteRed className="edit-blue-icon" />
                    </i>
                  </IconButton>
                </div>
              )}
            </div>
          ))}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Address Added Yet!"
          icon={<Location className="loaction-company-icon" />}
        />
      ) : (
        <NoContentCard
          small={true}
          subtitle="To add address, click on the add address button."
          title="No Address Added Yet!"
          icon={<Location className="loaction-company-icon" />}
          handleClick={() => openModal("Add Address")}
          // handleClick={() => {}}
          tag="Add Address"
          // color = 'white'
        />
      )}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Address"
          onClick={handleDeletion}
        />
      )}
    </div>
  );
};

export default Address;
