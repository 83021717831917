export const requestInfoData = [
  { value: "About company", label: "About company" },
  { value: "About product in company", label: "About product in company" },
  { value: "About people in company", label: "About people in company" },
  { value: "Other", label: "Other" },
];

export const reportData = [
  { value: "Report company", label: "Report company" },
  { value: "Report product in company", label: "Report product in company" },
  { value: "Report people in company", label: "Report people in company" },
  { value: "Other", label: "Other" },
];

export const RoleType = [
  { label: "Investor", value: "Investor" },
  { label: "Manager/Sponsor", value: "Manager/Sponsor" },
];

export const dataRoomFilterData = [
  { value: "Video", label: "Video" },
  { value: "Document", label: "Document" },
  { value: "Podcast", label: "Podcast" },
];

export const statusOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Reviewing", label: "Reviewing" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Closed", label: "Closed" },
];

export const fundTypeOptions = [
  { label: "Open", value: "Open" },
  { label: "Closed Ended", value: "Closed Ended" },
  { label: "Not Applicable", value: "Not Applicable" },
];
export const fundStatus = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];

export const eligibleInvestors = [
  { label: "Accredited", value: "Accredited" },
  { label: " Qualified", value: "Qualified" },
  { label: " Non-Accredited", value: "Non-Accredited" },
];

export const binary = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const statusData = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const subscriptionFrequencyOptions = [
  { value: "Daily", label: "Daily" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" }, // Assuming 'Quatlery' is a correct spelling, otherwise, consider 'Quarterly'
  { value: "One-Time", label: "One-Time" },
  { value: "Capital Call", label: "Capital Call" },
];

export const redemptionFrequencyOptions = [
  { value: "Daily", label: "Daily" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" }, // Assuming 'Quatlery' is a correct spelling, otherwise, consider 'Quarterly'
  { value: "Semi-Annually", label: "Semi-Annually" },
  { value: "Annually", label: "Annually" },
];

export const subTypeData = [
  { label: "PDF", value: "Pdf" },
  { label: "Excel", value: "Excel" },
  { label: "Others", value: "Others" },
];

export const TypeData = [
  { label: "Investor", value: 1 },
  { label: "Manager/Sponsor", value: 2 },
];

export const TeamType = [
  { label: "Investment Team", value: "Investment Team" },
  { label: "Operations Team", value: "Operations Team" },
  { label: "Marketing Team", value: "Marketing Team" },
];

export const protectedRoleType = {
  ISADMIN: "is_admin",
  MANAGER: "manager_database",
  INVESTOR: "investor_database",
  RESOURCES: "resource",
  LIBRARY: "investment_library_education",
};

export const allPlatforms = [
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter-X", label: "Twitter-X" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Pitchbook", label: "Pitchbook" },
  { value: "Other", label: "Other" },
];
