import { useEffect, useRef, useState } from "react";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import FilterIcon from "../../../../../../Icons/FilterIcon";
import CustomButton from "../../../../../../atoms/CustomeButton";
import Error from "../../../../../../atoms/Error";
import CloseBtnIcom from "../../../../../../molecules/Modal/CloseBtnIcon";
import DireactDealsFunds from "./DireactDealFunction";

const DireactDeal = ({
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  dateRangeCount = 1,
  dateRangeNames = ["Date range"],
  headingMobile,
  disableKeysUntilCondition,
  setOpen,
  type,
  reset,
  id,
  className,
  headingtext,
  dropdownPosition,
  handleAum,
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const [filterData, setFilterData] = useState({});
  const [updatedFilterData, setUpdatedFilterData] = useState({});
  const [aum, setAum] = useState({ min: null, max: null });
  const [errors, setErrors] = useState({});
  const [confirmAum, setConfirmAum] = useState({ min: "", max: "" });
  const [isConfirmedAum, setIsAumConfirm] = useState(false);
  const screenWidth = useScreenWidth();

  const { direactDealData, SPVData } = DireactDealsFunds();

  const updated = dateRangeNames?.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  useEffect(() => {
    // if (reset) {
    handleReset();
    // }
  }, [reset]);

  useEffect(() => {
    const filterData = {
      "Direct Deals": direactDealData || [],
      "Specialty Purpose Vehicle": SPVData || [],
    };
    const updatedFilterData = isDateRange && { ...filterData, ...updated };

    setUpdatedFilterData(updatedFilterData);
  }, [direactDealData, SPVData]);

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const keys = Object.keys(updatedFilterData);
    setSelectedKey(keys[0]);
  }, []);

  const toggleFilterBox = () => {
    const keys = Object.keys(updatedFilterData);
    setIsOpen(!isOpen);
    setSelectedKey(keys[0]);
    setSearchQuery("");
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
    setSearchQuery("");
  };

  // Function to recursively delete dependent keys
  const deleteDependentKeys = (updatedOptionsMap, deletedKey) => {
    for (const key in disableConditions) {
      const condition = disableConditions[key];
      if (typeof condition === "function" && condition(updatedOptionsMap)) {
        if (updatedOptionsMap[key]) {
          delete updatedOptionsMap[key];
          deleteDependentKeys(updatedOptionsMap, key); // Recursively delete dependent keys
        }
      }
    }
  };

  const handleOptionChange = (selectedValue) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      // debugger;
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      const optionExists = updatedOptionsMap[selectedKey].some(
        (option) => option.value === selectedValue
      );

      if (optionExists) {
        // If the selected value already exists, remove it
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (option) => option.value !== selectedValue
        );
      } else {
        // If the selected value doesn't exist, add it
        const selectedOption = updatedFilterData[selectedKey].find(
          (option) => option.value === selectedValue
        );
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          selectedOption,
        ];
      }

      // Remove the key if its array is empty
      if (updatedOptionsMap[selectedKey].length === 0) {
        delete updatedOptionsMap[selectedKey];
        deleteDependentKeys(updatedOptionsMap, selectedKey);
      }
      return updatedOptionsMap;
    });
  };

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      ...dateRange,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
    setAum({ min: null, max: null });
    handleAum({});
  };

  const disableConditions = {
    "Product Type": [], // Define disabling conditions for Product Type
    Geography: [], // Define disabling conditions for Geography
    Sector: [], // Define disabling conditions for Sector
    Country: [], // Define disabling conditions for Country
    State: (selectedOptionsMap) => !selectedOptionsMap["Country"], // Define disabling conditions for State
    City: (selectedOptionsMap) => !selectedOptionsMap["State"], // Define disabling conditions for City
    "Product Strategy": (selectedOptionsMap) =>
      !selectedOptionsMap["Product Type"],
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const excludedClassesRegex = /^Mui.*/; // Regular expression to match classes starting with "Mui"

      const isExcluded = Array.from(event.target.classList).some((className) =>
        excludedClassesRegex.test(className)
      );

      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !isExcluded &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, isOpen]);

  const handleChecked = (value) => {
    const val =
      selectedOptionsMap[selectedKey] &&
      selectedOptionsMap[selectedKey].some(
        (selectedOption) => selectedOption.value === value
      );

    if (!val) {
      return false;
    }

    return val;
  };
  const handleConfirm = (type) => {
    if (aum) {
      // if (!aum.min) {
      //   setErrors((prev) => ({ ...prev, min: "Min is Required" }));
      //   return;
      // } else if (!aum.max) {
      //   setErrors((prev) => ({ ...prev, max: "Max is Required" }));
      //   return;
      // } else {
      //   // If both min and max are present, clear any previous errors
      //   setErrors({});
      // }

      // If all validations pass, proceed with confirming AUM
      setConfirmAum({ ...aum });
      setIsAumConfirm(true);

      handleAum({ ...aum });
    }
  };

  const validate = (name, value) => {
    let error = "";

    // Update the aum state temporarily to validate the current field
    const updatedAum = { ...aum, [name]: value };

    if (name === "min" && value.trim() === "") {
      error = "Min Aum is required";
    } else if (name === "max" && value.trim() === "") {
      error = "Max Aum is required";
    } else if (parseFloat(updatedAum.max) <= parseFloat(updatedAum.min)) {
      error = "Max Aum must be greater than Min Aum";
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    setAum((prevAum) => ({ ...prevAum, [name]: value }));
    // validate(name, value);
  };

  useEffect(() => {
    if (
      containerRef &&
      containerRef?.current &&
      dropdownRef &&
      dropdownRef.current &&
      screenWidth < 900
    ) {
      dropdownRef.current.style.visibility = "hidden";
      // console.log(dropdownRef?.current?.style.visibility="hidden");
      const positions = containerRef?.current?.getBoundingClientRect();
      const dropPosition = dropdownRef?.current?.getBoundingClientRect();
      const totalWidth = (dropPosition.width * 100) / 80;
      const width_20_percent = (totalWidth * 20) / 100;
      console.log(
        "position :",
        positions.x + (screenWidth < 500 && width_20_percent)
      );
      dropdownRef.current.style.left = `-${
        positions.x + (screenWidth < 500 && width_20_percent)
      }px`;
      dropdownRef.current.style.right = `auto`;
      dropdownRef.current.style.visibility = "visible";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`${className} ${
          headingMobile
            ? "filter-container filter-mobile-container"
            : "filter-container"
        }`}
        ref={containerRef}
        id={id}
      >
        <CustomButton
          handleClick={toggleFilterBox}
          icon={<FilterIcon />}
          text={
            headingMobile ? "" : type === "product" ? headingtext : headingtext
          }
          classes={`${
            headingMobile
              ? Object.keys(selectedOptionsMap)?.length > 0 ||
                (isDateRange && (aum.min || aum.max))
                ? "multi-dot-indicator filter-mobile-container-btn"
                : "filter-mobile-container-btn"
              : Object.keys(selectedOptionsMap)?.length > 0 ||
                (isDateRange && (aum.min || aum.max))
              ? "multi-dot-indicator filterbtn"
              : "filterbtn"
          } ${
            (Array.isArray(selectedOptionsMap) &&
              selectedOptionsMap.length > 0) ||
            (selectedOptionsMap &&
              Object.keys(selectedOptionsMap)?.length > 0) ||
            (isDateRange &&
              Object.values(dateRange).some((value) => value !== null)) ||
            isOpen
              ? "active-filter"
              : "test"
          }`}
          style={{ height: "40px" }}
        />
        {isOpen && (
          <div className={`filter-box`} ref={dropdownRef}>
            <div className="filter-header">
              <p className="header">Filters</p>

              <span
                className="header"
                style={{ cursor: "pointer" }}
                onClick={toggleFilterBox}
              >
                <i className="d-flex">
                  <CloseBtnIcom />
                </i>
              </span>
            </div>
            <div className="filter-data-box">
              <div className="filter-key-box">
                {Object.keys(updatedFilterData).map((key) => (
                  <div
                    key={key}
                    onClick={() => {
                      // Check if the key should be disabled based on the disableConditions
                      if (
                        disableConditions[key] &&
                        typeof disableConditions[key] === "function"
                      ) {
                        if (disableConditions[key](selectedOptionsMap)) {
                          // If the condition is met, return early without changing the selected key
                          return;
                        }
                      }
                      // If the key is not disabled, handle the click event as usual
                      handleKeyClick(key);
                    }}
                    className={`filter-key selected ${
                      selectedKey === key ? "active-key" : ""
                    } ${
                      // Apply disabled class if the key is disabled
                      disableConditions[key] &&
                      typeof disableConditions[key] === "function" &&
                      disableConditions[key](selectedOptionsMap) &&
                      "disabled-key"
                    }`}
                  >
                    {key}

                    {selectedOptionsMap[key] ||
                    (isDateRange &&
                      (dateRange[`startDate${dateRangeNames.indexOf(key)}`] ||
                        dateRange[`endDate${dateRangeNames.indexOf(key)}`] ||
                        (key === "Funding Goal($mm)" &&
                          (aum.min || aum.max)))) ? (
                      <div className="dot-blue"></div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>

              <div className="filters-column">
                {dateRangeNames?.includes(selectedKey) ? (
                  <div>
                    <form className="formConatiner">
                      <div className="formInput">
                        <label htmlFor="startDate">Min Funding Goal</label>
                        <input
                          type="number"
                          placeholder="Enter Min Aum"
                          name="min"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setAum({ ...aum, min: e.target.value })
                          }
                          value={aum.min}
                        />
                        {errors.min && <Error error={errors.min} />}
                      </div>
                      <div className="formInput">
                        <label htmlFor="endDate">Max Funding Goal</label>
                        <input
                          type="number"
                          name="max"
                          placeholder="Enter Max Aum"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setAum({ ...aum, max: e.target.value })
                          }
                          value={aum.max}
                        />
                        {errors.max && <Error error={errors.max} />}
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <input
                      key={selectedKey}
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      style={{
                        height: "35px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="filters-options">
                      <div className="filters-options">
                        {[
                          // First, filter and display selected options
                          ...updatedFilterData[selectedKey]
                            .filter((option) =>
                              selectedOptionsMap[selectedKey]?.some(
                                (selectedOption) =>
                                  selectedOption.value === option.value
                              )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ),
                          // Then, filter and display unselected options
                          ...updatedFilterData[selectedKey]
                            .filter(
                              (option) =>
                                !selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ),
                        ].map((option) => (
                          <div key={option.value} className="mb-1">
                            <label
                              className="check-container"
                              htmlFor={option.value}
                            >
                              {option.label}
                              <input
                                type="checkbox"
                                id={option.value}
                                checked={handleChecked(option.value)}
                                onChange={() =>
                                  handleOptionChange(option.value)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr className="my-0" />
            <div className="d-flex justify-content-end gap-3 p-3">
              <button
                className="btn btn-outline-dark mt-0"
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary mt-0"
                onClick={() => {
                  handleConfirm();
                  handleApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DireactDeal;
