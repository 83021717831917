// import { useEffect } from "react";
// import * as yup from "yup";
// import Heading from "../../../atoms/Heading";

// const ContactUsForm = () => {
//   const initialValues = {
//     email: null,
//     firstname: null,
//     lastname: null,
//     company: null,
//     message_help: "",
//   };

//   const validationSchema = yup.object().shape({
//     email: yup
//       .string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     firstname: yup.string().required("Please complete this required field."),
//     lastname: yup.string().required("Please complete this required field."),
//     company: yup.string().required("Please complete this required field."),
//   });

//   useEffect(() => {
//     // Load the HubSpot Forms library asynchronously
//     const script = document.createElement("script");
//     script.src = "//js.hsforms.net/forms/embed/v2.js";
//     script.async = true;
//     document.body.appendChild(script);

//     // Initialize the HubSpot form
//     script.onload = () => {
//       if (window.hbspt) {
//         window.hbspt.forms.create({
//           region: "na1",
//           portalId: "4436350",
//           formId: "c83b91fb-7f8e-43c0-8368-97be3df4ffbe",
//           target: "#helpFormContainer", // Specify the container where you want to render the form
//         });
//       }
//     };

//     // Clean up the script tag on component unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handleFormSubmit = (isValid, values) => {
//     console.log("values", values);
//   };
//   return (
//     <div>
//       <Heading type="sub-title" title="Have a Question?" />

//       <div id="#helpFormContainer"></div>

//       {/* <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={(values) => {
//           console.log(values);
//         }}
//       >
//         {({
//           isValid,
//           dirty,
//           handleSubmit,
//           isSubmitting,
//           touched,
//           errors,
//           values,
//           setFieldValue,
//           handleBlur,
//         }) => (
//           <div id="helpFormContainer">
//             <div className="row g-3">
//               <div className="col-md">
//                 <InputField
//                   label="First Name"
//                   type="text"
//                   id="firstname"
//                   name="firstname"
//                   value={values.firstname}
//                   onChange={(e) => setFieldValue("firstname", e.target.value)}
//                   placeholder="Enter First Name"
//                   onBlur={handleBlur}
//                   className={`${
//                     touched.firstname && errors.firstname ? "error-input" : ""
//                   }`}
//                 />
//                 {touched.firstname && errors.firstname && (
//                   <Error error={errors.firstname} />
//                 )}
//               </div>
//               <div className="col-md">
//                 <InputField
//                   label="Last Name"
//                   type="text"
//                   id="lastname"
//                   name="lastname"
//                   value={values.lastname}
//                   onChange={(e) => setFieldValue("lastname", e.target.value)}
//                   placeholder="Enter Last Name"
//                   onBlur={handleBlur}
//                   className={`${
//                     touched.lastname && errors.lastname ? "error-input" : ""
//                   }`}
//                 />
//                 {touched.lastname && errors.lastname && (
//                   <Error error={errors.lastname} />
//                 )}
//               </div>
//             </div>
//             <div className="row g-3 mt-0 mt-sm-2 ">
//               <div className="col-md">
//                 <InputField
//                   label="Email"
//                   type="text"
//                   id="email"
//                   onBlur={handleBlur}
//                   name="email"
//                   value={values.email}
//                   onChange={(e) => setFieldValue("email", e.target.value)}
//                   placeholder="Enter Email"
//                   className={`${
//                     touched.email && errors.email ? "error-input" : ""
//                   }`}
//                 />
//                 {touched.email && errors.email && (
//                   <Error error={errors.email} />
//                 )}
//               </div>
//               <div className="col-md">
//                 <InputField
//                   label="Company Name"
//                   type="text"
//                   id="company"
//                   onBlur={handleBlur}
//                   name="company"
//                   value={values.company}
//                   onChange={(e) => setFieldValue("company", e.target.value)}
//                   placeholder="Enter Company Name"
//                   className={`${
//                     touched.company && errors.company ? "error-input" : ""
//                   }`}
//                 />
//                 {touched.company && errors.company && (
//                   <Error error={errors.company} />
//                 )}
//               </div>
//             </div>
//             <div className="row g-3 mt-0 mt-sm-2">
//               <TextArea
//                 label="Message"
//                 id="message_help"
//                 name="message_help"
//                 onBlur={handleBlur}
//                 value={values.message_help}
//                 onChange={(e) => setFieldValue("message_help", e.target.value)}
//                 placeholder="Enter Your Message"
//               />
//             </div>
//             {console.log(errors)}
//             <div className="d-flex justify-content-end my-3">
//               <button
//                 className="btn btn-primary responsive-btn"
//                 type="submit"
//                 // disabled={!is}
//                 onClick={() => handleFormSubmit(isValid, values)}
//               >
//                 Submit
//               </button>
//             </div>
//           </div>
//         )}
//       </Formik> */}
//     </div>
//   );
// };

// export default ContactUsForm;

import { useEffect } from "react";
import Heading from "../../../atoms/Heading";

const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "4436350",
          formId: "c83b91fb-7f8e-43c0-8368-97be3df4ffbe",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div>
      <Heading type="sub-title" title="Have a Question?" />
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
