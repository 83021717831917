import ImageCompress from "quill-image-compress";
import React, { useEffect, useState } from "react";
import { Quill } from "react-quill";

var Parchment = Quill.import("parchment");

const { config } = Quill;

// var config = Quill.import('config')

Quill.register("modules/imageCompress", ImageCompress);

let LineHeightClass = new Parchment.Attributor.Class(
  "line-height",
  "line-height",
  config
);
Quill.register(LineHeightClass, true);

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const CustomLineHeight = () => {
  <svg
    className="lineHeightSvg ql-fill ql-stroke"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="ql-stroke"
      d="M6 10V5m0 0L4 7m2-2 2 2m-2 7v5m0 0 2-2m-2 2-2-2m8-10h8m0 5h-8m0 5h8"
    />
  </svg>;
};

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = [
  "13px",
  "14px",
  "16px",
  "18px",
  "20px",
  "25px",
  "30px",
  "35px",
  "38px",
];
Quill.register(Size, true);

// Add fonts to whitelist and register them
// const Font = Quill.import("formats/font");
// Font.whitelist = [
//   "arial",
//   "comic-sans",
//   "courier-new",
//   "georgia",
//   "helvetica",
//   "Inter",
//   "lucida"
// ];
// Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = (props) => ({
  toolbar: {
    container: "#" + props,
    handlers: {
      undo: undoChange,
      redo: redoChange,
      lineHeight: handleLineHeightChange,
    },
  },
  imageCompress: {
    quality: 0.5,
    maxWidth: 1000,
    maxHeight: 1000,
    imageType: "image/jpeg",
    debug: true,
    suppressErrorLogging: false,
    insertIntoEditor: undefined,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// line height handler
function handleLineHeightChange(value) {
  let range = this.quill.getSelection();
  let format = this.quill.getFormat(range);

  Object.keys(format).forEach((key) => {
    if (key.startsWith("line-height")) {
      this.quill.format(key, false);
    }
  });
  this.quill.format("line-height", value);
}

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
  "line-height",
];

const lineHeightOptions = [
  { value: "normal", label: "Normal" },
  { value: "tight", label: "1" },
  { value: "comfortable", label: "2" },
  { value: "spacious", label: "2.5" },
];

// Quill Toolbar component
export const QuillToolbar = (props) => {

  const [formats, setFormats] = useState()


  // useEffect(() => {
  //   if(props?.formats){
  //     setFormats(props?.formats)

  //     if(formats?.['line-height']){
  //       quill.root.querySelectorAll('.ql-lineHeight .ql-picker-item').forEach(option => {
  //         option.classList.remove('active');
  //       });

  //       // Add active class to the selected span
  //       const selectedOption = quill.root.querySelector(`.ql-lineHeight .ql-picker-item[data-value="${lineHeight}"]`);
  //       if (selectedOption) {
  //         selectedOption.classList.add('active');
  //       }
  //     }
  //   } 

  // },[props])

  useEffect(() => {
    const qlLineHeights = document.querySelectorAll(`.ql-lineHeight .ql-picker-item`);
  
    // Function to remove 'active' class from each ql-lineHeight element
    const removeActive = () => {
      qlLineHeights.forEach(item => {
        if (item.classList.contains('active')) {
          item.classList.remove('active');
        }
      });
    };
  
    // Call removeActive function initially
    removeActive();
  
    if (props?.formats && props.formats['line-height']) {
      removeActive();
      setFormats(props.formats);
      const selectedOption = document.querySelector(
        `.ql-lineHeight .ql-picker-item[data-value="${props.formats['line-height']}"]`
      );
      if (selectedOption) {
        selectedOption.classList.add('active');
      }
    } else {
      removeActive();
      // If props.formats is not provided or 'line-height' is not set, set the default value as 'normal'
      const defaultOption = document.querySelector(`.ql-lineHeight .ql-picker-item[data-value="normal"]`);
      if (defaultOption) {
        defaultOption.classList.add('active');
      }
    }
  }, [props?.formats]);
  
  

  // useEffect(() => {
  //   console.log('props', props)
  // },[props])
  

  // useEffect(() => {
  //   console.log('formats' , formats)
  // }, [formats ])

  return (
    <>
      {props.toolbarId !== undefined && (
        <div id={props.toolbarId} className={props.className}>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            {/* <button className="ql-strike" /> */}
          </span>
          <span className="ql-formats">
            <select className="ql-size" defaultValue="none">
              <option value="none">Normal</option>
              <option value="13px">13px</option>
              <option value="14px">14px</option>
              <option value="16px">16px</option>
              <option value="18px">18px</option>
              <option value="20px">20px</option>
              <option value="25px">25px</option>
              <option value="30px">30px</option>
              <option value="35px">35px</option>
              <option value="38px">38px</option>
            </select>
            <select className="ql-header" defaultValue="7">
              <option value="1">Heading 1</option>
              <option value="2">Heading 2</option>
              <option value="3">Heading 3</option>
              <option value="4">Heading 4</option>
              <option value="5">Heading 5</option>
              <option value="6">Heading 6</option>
              <option value="7" selected>
                Normal
              </option>
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </span>
          <span className="ql-formats">
            <button className="ql-blockquote" />
            <button className="ql-direction" />
          </span>
          <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
          </span>
          <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
          </span>

          <span className="ql-formats">
            {/* <CustomLineHeight /> */}
            <svg
              className="lineHeightSvg ql-stroke"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              width='19'
              height='19'
            >
              <path
                stroke="#444"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 10V5m0 0L4 7m2-2 2 2m-2 7v5m0 0 2-2m-2 2-2-2m8-10h8m0 5h-8m0 5h8"
              />
            </svg>
            <select className="ql-lineHeight">
              <option value="normal">Normal</option>
              <option value="tight" >1</option>
              <option value="normalTight">1.5</option>
              <option value="comfortable" >2</option>
              <option value="spacious" >2.5</option>
              <option value="extraSpacious">3</option>
            </select>
          </span>
          {/* <span className="ql-formats">
      <button className="ql-code-block" /> 
    </span> */}
          <span className="ql-formats">
            <button className="ql-undo">
              <CustomUndo />
            </button>
            <button className="ql-redo">
              <CustomRedo />
            </button>
          </span>
        </div>
      )}
    </>
  );
};
export default QuillToolbar;
