import "./shimmerPhone.scss";
const ContentSharingPhoneShimmer = ({ type = "" }) => {
  return (
    <div className="content-sharing-phone-container-sh">
      {Array(10)
        .fill()
        .map((_, index) => (
          <div className="sharing-details-phone-wrapper-sh">
            {type === "Database" ? (
              <>
                <div className="sharing-details-content-database-conatiner-sh">
                  <div className="image-database-sh-conatiner">
                    <div className="image-sh-container"></div>
                    <div className="image-heading-sh-conatiner"></div>
                  </div>
                  <div className="content-database-sh-item-lg"></div>
                  <div className="content-database-sh-item-md"></div>

                  <div className="content-database-sh-item-lg"></div>
                </div>
              </>
            ) : (
              <div className="sharing-details-content-conatiner-sh">
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
                <div className="detail-contet-item-phone-sh">
                  <div className="p-dark"></div>
                  <div className="p-light-small"></div>
                </div>
              </div>
            )}

            <div className="sharing-details-phone-btns-sh">
              <div className="btn btn-outline-dark "></div>
              <div className="btn btn-primary"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContentSharingPhoneShimmer;
