import { useSubscription } from ".";
import CircleAvatar from "../components/atoms/CircleAvatar";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import { useSelector } from "react-redux";

const SubscriptionBanner = () => {
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  const { isSubscriptionExpired, isShow, setIsShow } = useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  if (!isSubscriptionExpired) return null;

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  const localMode =
    userInfo?.is_last_subs_mode_trial || trial_data?.is_last_subs_mode_trial;

  return (
    <div
      className={`modal ${isSubscriptionExpired && isShow ? "active" : ""}`}
      style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            />
            <p className="modal-heading mb-0 mt-2">
              {/* Your {trial_data?.is_trial ? "Free Trial" : "Subscription"} has
              expired. */}

              {localMode
                ? "Upgrade Your Subscription"
                : "Renew Your Subscription"}
            </p>
          </div>

          {/* {trial_data?.is_trial && ( */}
          <p className="modal-description mt-2" style={{ textAlign: "center" }}>
            {/* Your {userInfo?.subs_period_count} {userInfo?.subs_timespan} Free
              Trial has Expired. <br /> */}

            {localMode
              ? "Your Free Trial Has Ended"
              : "Your Subscription Has Ended"}
          </p>
          {/* )} */}
          <p
            className={`modal-description ${localMode ? "mt-0" : "mt-2"}`}
            style={{ textAlign: "center" }}
          >
            {localMode
              ? `Thank you for exploring PrimeAlpha. To continue accessing our investor database and powerful marketing tools, upgrade now and unlock the full potential of our platform. Leverage our features to supercharge your investor engagement.
`
              : "Thank you for using PrimeAlpha! Ready to continue growing your investor connections and insights? Subscribe now to regain full access to our investor database, thought leadership features, and advanced tools for expanding your network and boosting engagement. Don’t miss out—reactivate your membership today!"}
          </p>
          <div className="d-flex w-100 mt-3">
            <button
              className="btn btn-outline-dark"
              onClick={() => setIsShow(false)}
              style={{ flex: "1 0 45%" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mt-0"
              onClick={() => window.open(upgrade_link, "_blank")}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              {localMode ? "Upgrade Now" : "Renew Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
