import React from "react";
import Other from "../../../assets/images/socials/OtherSocial.png";
import facebook from "../../../assets/images/socials/facebook.png";
import LinkedIn from "../../../assets/images/socials/linkedin.png";
import pitchbook from "../../../assets/images/socials/pitchbook.jpeg";
import twitter from "../../../assets/images/socials/twitter.png";
import "./image_icon.scss";

interface ImageIconProps {
  type: "LinkedIn" | "Facebook" | "Twitter-X" | "Other";
  link?: string;
}

const ImageIcon: React.FC<ImageIconProps> = ({ type, link }) => {
  const imgUrl = {
    LinkedIn: LinkedIn,
    Facebook: facebook,
    "Twitter-X": twitter,
    Pitchbook: pitchbook,
    Other: Other,
    Others: Other,
  };

  const handleClick = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <div
      className="image-icon"
      onClick={handleClick}
      style={{ cursor: link ? "pointer" : "default" }}
    >
      <img src={imgUrl[type]} alt={`${type} Icon`} height="30px" />
    </div>
  );
};

export default ImageIcon;
