import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { dashboardRoute } from "../../../routes/index";
import { useSubscription } from "../../../SubscriptionContext";
import SubscriptionBanner from "../../../SubscriptionContext/SubscriptionBanner";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import Sidebar from "../../molecules/Sidebar";
import TrialBanner from "../../molecules/TrialBanner";
import styles from "./dashboard_layout.module.scss";

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  const screenWidth = useScreenWidth();

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const location = useLocation();

  const toggleActiveState = () => {
    setIsActive(!isActive);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const dashboardContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };
    const handleScroll = () => {
      if (screenWidth < 900) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  useEffect(() => {
    const handleClickInside = (event: MouseEvent) => {
      if (
        dashboardContentRef.current &&
        dashboardContentRef.current.contains(event.target as Node)
      ) {
        // Call handleUserClick only if isSubscriptionExpired is true
        if (isSubscriptionExpired) {
          const targetElement = event.target as HTMLElement;
          // Check if the clicked element is a NavLink or its parent element is a NavLink
          const navLinkElement = targetElement.closest(
            "a"
          ) as HTMLAnchorElement;

          const faqContainerElement = targetElement.closest("#FAQ-Container");

          const helpCenterContainerElement = targetElement.closest(
            "#Help-Center-Container"
          );

          const editCompanyContainerElement =
            targetElement.closest("#Company-Edit");

          const editInvestmentPreferenceContainerElement =
            targetElement.closest("#Investment-Preference-Container");

          const companyContainerElement =
            targetElement.closest("#Company-Container");

          const myBlogsContainer = targetElement.closest("#MyBlogs-Container");

          const productContainerElement = targetElement.closest(
            "#Product-Info-Containter"
          );

          const productFormContainerElement = targetElement.closest(
            "#Product-Form-Conatiner"
          );

          const peopleFormContainerElement = targetElement.closest(
            "#People-Edit-Container"
          );

          const workFormContainerElement = targetElement.closest(
            "#Work-Form-Container"
          );

          const workViewContainerElement = targetElement.closest(
            "#Work-View-Container"
          );

          const educationFormContainerElement = targetElement.closest(
            "#Education-Form-Container"
          );

          const educationViewContainerElement = targetElement.closest(
            "#Education-View-Container"
          );

          const profileContainerElement =
            targetElement.closest("#Profile-Container");

          if (
            (navLinkElement &&
              (navLinkElement.textContent?.includes("Help Center") ||
                navLinkElement.textContent?.includes("FAQ") ||
                navLinkElement.id === "FAQ-link" ||
                navLinkElement.id === "Home-link" ||
                navLinkElement.id === "My Blogs-link" ||
                navLinkElement.id === "Fund Directory-link" ||
                navLinkElement.id === "Investors-link")) ||
            faqContainerElement ||
            helpCenterContainerElement ||
            // requestsContainerElement ||
            myBlogsContainer ||
            companyContainerElement ||
            profileContainerElement ||
            productContainerElement ||
            editCompanyContainerElement ||
            editInvestmentPreferenceContainerElement ||
            productFormContainerElement ||
            peopleFormContainerElement ||
            workFormContainerElement ||
            workViewContainerElement ||
            educationFormContainerElement ||
            educationViewContainerElement
            // ||
            // fundDirctoryContainerElement ||
            // investorContainerElement
          ) {
            return; // Exit early, do not call handleUserClick
          } else {
            handleUserClick(event);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickInside);

    return () => {
      document.removeEventListener("mousedown", handleClickInside);
    };
  }, [isSubscriptionExpired, handleUserClick]);

  return (
    <>
      <TrialBanner />

      <SubscriptionBanner />
      <Navbar
        isActive={isActive}
        setIsActive={setIsActive}
        toggleActive={toggleActiveState}
        searchRequired={false}
        navbarRef={screenWidth < 900 ? navbarRef : null}
      />
      <div
        className={styles.dashboard_container}
        id="dashboard-content-container"
        ref={dashboardContentRef}
      >
        <Sidebar
          isActive={isActive}
          setIsActive={setIsActive}
          sidebarRef={screenWidth < 900 ? sidebarRef : null}
        />
        <div
          className={styles.dashboard_content_container}
          style={{
            minHeight:
              location.pathname ===
              "/dashboard/investors/investordatabase/Roadshow"
                ? "100vh"
                : "130vh",
          }}
        >
          <Outlet />
          {dashboardRoute}
        </div>
      </div>

      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </>
  );
};

export default DashboardLayout;
