import { FieldArray, Formik, Form as FormikForm } from "formik";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";
import withUnsavedChangesWarning from "../../../organisms/withUnsavedChangesWarning/WithUnsavedChangesWarning";
import "../editProfile.scss";
import "./edit.scss";

import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confimationStyles } from "../../../../assets/styles/toast";
import { countryCodeData } from "../../../../helpers/countryCodes";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { getData, patchFormData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { useSubscription } from "../../../../SubscriptionContext";
import SelectDropdown from "../../../atoms/Dropdown";
import Error from "../../../atoms/Error";
import FixedPositionButton from "../../../atoms/FixedPositionButton";
import InputField from "../../../atoms/InputField";
import InputLayout from "../../../atoms/InputLayout";
import TextArea from "../../../atoms/TextArea";
import {
  DateSelector,
  MonthYearSelector,
} from "../../../molecules/MonthYearSelector";
import QuillEditor from "../../../molecules/QuillEditor/QuillEditor";
import UserTeams from "./UserTeams";

const Edit = ({ dirty }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t", "email", "cuid"]);
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  const { isSubscriptionExpired } = useSubscription();

  const [profileData, setProfileData] = useState([]);

  const fetchProfileInfo = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/customer/getUserProfileDetails",
      params: { id: cookies.cuid },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setProfileData(res);
    }
  };
  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const initialValues = {
    people_id: cookies.cuid,
    first_name: profileData?.first_name || null,
    last_name: profileData?.last_name || null,
    email: profileData?.email || "",
    about: profileData?.about || "",
    experience: profileData?.experience || null,
    country_code: profileData?.country_code || null,
    mobile: profileData?.mobile || null,
    ext_1: profileData?.ext_1 || "",

    country_code_2: profileData.country_code_2 || "",
    phone_2: profileData?.phone_2 || "",
    ext_2: profileData?.ext_2 || "",

    country_code_3: profileData.country_code_3 || "",
    phone_3: profileData?.phone_3 || "",
    profile_photo: profileData?.profile_photo || null,
    certificates: (profileData?.certificates || [null]).map((certificate) => ({
      id: certificate?.id || null,
      title: certificate?.title || null,
      about: certificate?.about || null,
      status: true,
    })),
    education: (profileData?.education || [null]).map((edu) => ({
      id: edu?.id || null,
      field_of_study: edu?.field_of_study || null,
      institute_name: edu?.institute_name || null,
      start_date: edu?.start_date || null,
      end_date: edu?.end_date || null,
      about: edu?.about || null,
      degree: edu?.degree || null,
      status: true,
    })),
    work_detail: (profileData?.work_details || [null]).map((work) => ({
      id: work?.id || null,
      company_name: work?.company_name || null,
      email: work?.email || null,
      start_date: work?.start_date || null,
      end_date: work?.end_date || null,
      title: work?.title || null,
      work_description: work?.description || null,
      currently_working_here: work?.currently_working_here || false,
      status: true,
    })),
    remove_work: [],
    remove_education: [],
    remove_certificate: [],
  };

  const getModifiedFields = (initialValues, currentValues, prefix = "") => {
    let modifiedFields = [];

    for (const key in currentValues) {
      const currentKeyPath = prefix ? `${prefix}.${key}` : key;

      // If it's an array, handle the comparison recursively
      if (Array.isArray(currentValues[key])) {
        currentValues[key].forEach((item, index) => {
          modifiedFields = modifiedFields.concat(
            getModifiedFields(
              initialValues[key][index] || {},
              item,
              `${currentKeyPath}[${index}]`
            )
          );
        });
      } else if (
        typeof currentValues[key] === "object" &&
        currentValues[key] !== null
      ) {
        // If it's an object, handle nested comparison recursively
        modifiedFields = modifiedFields.concat(
          getModifiedFields(
            initialValues[key] || {},
            currentValues[key],
            currentKeyPath
          )
        );
      } else if (initialValues[key] !== currentValues[key]) {
        // If it's a simple value, compare directly
        modifiedFields.push(currentKeyPath);
      }
    }

    return modifiedFields;
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    experience: yup
      .number()
      .typeError("Experience must be a number")
      .positive("Experience must be a positive number")
      .integer("Experience must be an integer")
      .nullable(),
    country_code: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Phone should be provided, or neither",
        function (value) {
          const { country_code, mobile } = this.parent;
          if ((country_code && !mobile) || (!country_code && mobile)) {
            return false;
          }
          return true;
        }
      ),
    mobile: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code, mobile } = this.parent;
          if ((country_code && !mobile) || (!country_code && mobile)) {
            return false;
          }
          return true;
        }
      ),
    ext_1: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number-ext1",
        "Ext. 1 can only be added when Phone is provided.",
        function (value) {
          const { country_code, mobile } = this.parent;
          if (value && (!country_code || !mobile)) {
            return false;
          }
          return true;
        }
      ),
    country_code_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if ((country_code_2 && !phone_2) || (!country_code_2 && phone_2)) {
            return false;
          }
          return true;
        }
      ),
    phone_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if ((country_code_2 && !phone_2) || (!country_code_2 && phone_2)) {
            return false;
          }
          return true;
        }
      ),
    ext_2: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number-ext1",
        "Ext. 2 can only be added when Secondary Phone is provided",
        function (value) {
          const { country_code_2, phone_2 } = this.parent;
          if (value && (!country_code_2 || !phone_2)) {
            return false;
          }
          return true;
        }
      ),
    country_code_3: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_3, phone_3 } = this.parent;
          if ((country_code_3 && !phone_3) || (!country_code_3 && phone_3)) {
            return false;
          }
          return true;
        }
      ),
    phone_3: yup
      .string()
      .nullable()
      .test(
        "country-code-and-phone-number",
        "Either both Country Code and Contact Number should be provided, or neither",
        function (value) {
          const { country_code_3, phone_3 } = this.parent;
          if ((country_code_3 && !phone_3) || (!country_code_3 && phone_3)) {
            return false;
          }
          return true;
        }
      ),
    about: yup.string().nullable(),
    profile_photo: yup.mixed().nullable(),
    work_detail: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable(),
        company_name: yup.string().required("Company name is required"),
        title: yup.string().required("Title is required"),
        start_date: yup.date().when("currently_working_here", {
          is: true,
          then: () => yup.date().required("Start Date is required"),
          otherwise: () => yup.date().notRequired(),
        }),
        email: yup
          .string()
          .email()
          .when("currently_working_here", {
            is: true,
            then: () => yup.string().email().required("Email is Required"),
            otherwise: () => yup.string().email().notRequired(),
          }),
        currently_working_here: yup.boolean().nullable(),
        work_description: yup.string().nullable(),
      })
    ),
    education: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable(),
        // field_of_study: yup.string().nullable().notRequired(),
        institute_name: yup.string().required("Institute name is required"),
        // degree: yup.string().required("Degree is required"),
        // start_date: yup.date().nullable(),
        // end_date: yup
        //   .date()
        //   .when("currently_studying", (currentlyStudying, schema) =>
        //     currentlyStudying
        //       ? schema.nullable().notRequired()
        //       : schema.nullable().required("End date is required")
        //   ),
        currently_studying: yup.boolean().nullable(),
        about: yup.string().nullable(),
      })
    ),
    certificates: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable(),
        title: yup.string().required("Title is required"),
        about: yup.string().nullable(),
      })
    ),
  });

  const handleEdit = useCallback(async (values) => {
    const dataToSend = {
      ...values,
      work_detail: JSON.stringify(values.work_detail),
      education: JSON.stringify(values.education),
    };
    dispatch(setLoading(true));
    const res = await patchFormData({
      endpoint: "People/customer/editPeopleProfile",
      data: values,
      params: {},
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      toast.success("Profile details updated", {
        duration: 1000,
        style: confimationStyles,
      });
      fetchProfileInfo();
    }
  }, []);

  return (
    <>
      <div className="h1-i">Edit Profile</div>
      <div className="p">Change your profile details</div>

      <div className="edit-form-div">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleEdit}
          key={111}
        >
          {({
            isValid,
            dirty,
            handleSubmit,
            touched,
            errors,
            values,
            isSubmitting,
            setFieldValue,
            setErrors,
            setFieldError,
          }) => {
            return (
              <>
                <FormikForm onSubmit={handleSubmit}>
                  <FixedPositionButton
                    onClick={() => handleEdit(values)}
                    disabled={!isValid || isSubmitting}
                  />
                  <ImageSelector
                    description="Logo image should be 512X512px and size is 1Mb "
                    handleFile={(file) => {
                      setFieldValue("profile_photo", file);
                      setFieldValue("remove_profile_photo", false);
                    }}
                    selectedImage={values.profile_photo}
                    onRemove={() => setFieldValue("remove_profile_photo", true)}
                  />

                  {/* Basic Info */}
                  <div className="basic-info">
                    <div className="row mt-3">
                      <InputLayout>
                        <div className="row">
                          <div className="col-6">
                            <InputField
                              type="text"
                              name="first_name"
                              label="First Name"
                              placeholder="Enter First Name"
                              labelClassName="required-field"
                              onChange={(e) => {
                                setFieldValue("first_name", e.target.value);
                              }}
                              className={`w-100 h-100 ${
                                touched.first_name && errors.first_name
                                  ? "error-input"
                                  : ""
                              }`}
                              value={values.first_name}
                            />
                          </div>
                          <div className="col-6">
                            <InputField
                              type="text"
                              name="last_name"
                              label="Last Name"
                              labelClassName="required-field"
                              placeholder="Enter Last Name"
                              onChange={(e) => {
                                setFieldValue("last_name", e.target.value);
                              }}
                              className={`w-100 h-100 ${
                                touched.last_name && errors.last_name
                                  ? "error-input"
                                  : ""
                              }`}
                              value={values.last_name}
                            />
                          </div>
                        </div>
                      </InputLayout>

                      <InputLayout>
                        <InputField
                          type="text"
                          label="Email"
                          name="email"
                          id="email"
                          labelClassName="required-field"
                          placeholder="Enter Email"
                          value={values.email}
                          disabled={true}
                          // onChange={(e) => {
                          //   setFieldValue("last_name", e.target.value);
                          // }}
                        />
                      </InputLayout>
                    </div>

                    <div className="row mt-3">
                      <div className="col">
                        <div className="row">
                          <label htmlFor="">Phone Number 1</label>
                          <div className="col-4">
                            <SelectDropdown
                              data={countryCodeData}
                              placeholder="Code"
                              onChange={(option) => {
                                setFieldValue("country_code", option);
                              }}
                              isClearable={true}
                              onClear={() => setFieldValue("country_code", "")}
                              type="phoneNumber"
                              selectedValue={values?.country_code}
                            />
                          </div>
                          <div className="col-8">
                            <InputField
                              type="text"
                              name="mobile"
                              placeholder="Enter Contact Number"
                              onChange={(e) => {
                                const numericInput = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFieldValue("mobile", numericInput);
                              }}
                              className={`w-100 h-100 ${
                                touched.mobile && errors.mobile
                                  ? "error-input"
                                  : ""
                              }`}
                              value={values?.mobile}
                            />
                          </div>
                          {(errors.country_code || errors.mobile) && (
                            <Error
                              error={errors.country_code || errors.mobile}
                            />
                          )}
                        </div>
                      </div>

                      <div className="col">
                        <InputLayout>
                          <InputField
                            type="text"
                            label="Ext. 1"
                            name="ext_1"
                            id="ext_1"
                            placeholder="Enter Ext. 1"
                            value={values.ext_1}
                            onChange={(e) => {
                              setFieldValue("ext_1", e.target.value);
                            }}
                          />
                        </InputLayout>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col">
                        <div className="row">
                          <label htmlFor="">Phone Number 2</label>
                          <div className="col-4">
                            <SelectDropdown
                              data={countryCodeData}
                              placeholder="Code"
                              onChange={(option) => {
                                setFieldValue("country_code_2", option);
                              }}
                              selectedValue={values?.country_code_2}
                              isClearable={true}
                              type="phoneNumber"
                              onClear={() =>
                                setFieldValue("country_code_2", "")
                              }
                            />
                          </div>
                          <div className="col-8">
                            <InputField
                              type="text"
                              name="mobile"
                              placeholder="Enter Phone Number 2"
                              onChange={(e) => {
                                const numericInput = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFieldValue("phone_2", numericInput);
                              }}
                              className={`w-100 h-100 ${
                                touched.phone_2 && errors.phone_2
                                  ? "error-input"
                                  : ""
                              }`}
                              value={values?.phone_2}
                            />
                          </div>
                          {(errors.country_code_2 || errors.phone_2) && (
                            <Error
                              error={errors.country_code_2 || errors.phone_2}
                            />
                          )}
                        </div>
                      </div>

                      <div className="col">
                        <InputLayout>
                          <InputField
                            type="text"
                            label="Ext. 2"
                            name="ext_2"
                            id="ext_2"
                            placeholder="Enter Ext. 2"
                            value={values.ext_2}
                            onChange={(e) => {
                              setFieldValue("ext_2", e.target.value);
                            }}
                          />
                        </InputLayout>
                      </div>
                    </div>

                    {/* ----------------------------------------------------  */}

                    <div className="row mt-3">
                      <div className="col">
                        <div className="row">
                          <label htmlFor="">Phone Number 3</label>
                          <div className="col-4">
                            <SelectDropdown
                              data={countryCodeData}
                              placeholder="Code"
                              onChange={(option) => {
                                setFieldValue("country_code_3", option);
                              }}
                              isClearable={true}
                              type="phoneNumber"
                              onClear={() =>
                                setFieldValue("country_code_3", "")
                              }
                              selectedValue={values?.country_code_3}
                            />
                          </div>
                          <div className="col-8">
                            <InputField
                              type="text"
                              name="phone_3"
                              placeholder="Enter Phone Number 3"
                              onChange={(e) => {
                                const numericInput = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setFieldValue("phone_3", numericInput);
                              }}
                              className={`w-100 h-100 ${
                                touched.phone_3 && errors.phone_3
                                  ? "error-input"
                                  : ""
                              }`}
                              value={values?.phone_3}
                            />
                          </div>
                          {(errors.country_code_3 || errors.phone_3) && (
                            <Error
                              error={errors.country_code_3 || errors.phone_3}
                            />
                          )}
                        </div>

                        <div className="row mt-3">
                          <InputLayout>
                            <InputField
                              label="Experience (In Years)"
                              name="experience"
                              id="experience"
                              onChange={(e) => {
                                setFieldValue("experience", e.target.value);
                              }}
                              placeholder="Experience (In Years)"
                              value={values?.experience}
                              type="number"
                            ></InputField>
                          </InputLayout>
                        </div>
                      </div>
                      <div className="col">
                        <InputLayout>
                          {/* <TextArea
                          label="About Me"
                          placeholder="About Me"
                          height="149px"
                          value={values.about}
                          onChange={(e) => {
                            setFieldValue("about", e.target.value);
                          }}
                        /> */}
                          <QuillEditor
                            label="About Me"
                            placeholder="About Me"
                            value={values.about}
                            onChange={(content) =>
                              setFieldValue("about", content)
                            }
                            type=""
                            height="149px"
                          />
                        </InputLayout>
                      </div>
                    </div>
                  </div>
                  {screenWidth > 900 && (
                    <>
                      {/* Company Details  */}
                      <div className="companies">
                        <FieldArray name="work_detail">
                          {({ push, remove }) => (
                            <>
                              <div className="company-header">
                                <div className="h2-i">Companies Details</div>
                                <button
                                  type="button"
                                  className="btn btn-outline-dark"
                                  style={{ minWidth: "180.76px" }}
                                  onClick={() =>
                                    push({
                                      id: null,
                                      company_name: null,
                                      start_date: null,
                                      end_date: null,
                                      title: null,
                                      work_description: null,
                                      currently_working_here: null,
                                      status: true,
                                    })
                                  }
                                >
                                  Add Company
                                </button>
                              </div>

                              {values.work_detail?.map((company, index) => (
                                <>
                                  <div key={index}>
                                    <div className="company-detail">
                                      <p className="p semi-light">
                                        COMPANY {index + 1}
                                      </p>
                                      <span
                                        className="remove"
                                        onClick={() => {
                                          // Push the id to remove_work array before removing the item
                                          const removedId =
                                            values.work_detail[index].id;
                                          if (removedId) {
                                            setFieldValue("remove_work", [
                                              ...values.remove_work,
                                              removedId,
                                            ]);
                                          }
                                          remove(index);
                                        }}
                                      >
                                        REMOVE
                                      </span>
                                    </div>

                                    <div className="row">
                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Company Name"
                                          labelClassName="required-field"
                                          name={`work_detail[${index}].company_name`}
                                          placeholder="Enter Company Name"
                                          onChange={(e) => {
                                            setFieldValue(
                                              `work_detail[${index}].company_name`,
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            values.work_detail[index]
                                              .company_name
                                          }
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Title"
                                          labelClassName="required-field"
                                          name={`work_detail[${index}].title`}
                                          placeholder="Enter Title"
                                          onChange={(e) => {
                                            setFieldValue(
                                              `work_detail[${index}].title`,
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            values.work_detail[index].title
                                          }
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Email"
                                          name={`work_detail[${index}].email`}
                                          labelClassName={
                                            values?.work_detail?.[index]
                                              ?.currently_working_here
                                              ? "required-field"
                                              : ""
                                          }
                                          placeholder="Enter Company Email"
                                          onChange={(e) => {
                                            setFieldValue(
                                              `work_detail[${index}].email`,
                                              e.target.value
                                            );
                                          }}
                                          value={
                                            values.work_detail[index].email
                                          }
                                        />
                                      </InputLayout>
                                    </div>

                                    <div className="row mt-3">
                                      <div className="col-4 form-group">
                                        <label
                                          htmlFor=""
                                          className={
                                            values?.work_detail?.[index]
                                              ?.currently_working_here
                                              ? "required-field"
                                              : ""
                                          }
                                        >
                                          Start Date
                                        </label>
                                        <DateSelector
                                          placeholder="Start Date"
                                          onChange={(value) => {
                                            setFieldValue(
                                              `work_detail[${index}].start_date`,
                                              value
                                            );
                                          }}
                                          maxDateData={
                                            values.work_detail[index].end_date
                                          }
                                          selectedDate={
                                            values.work_detail[index].start_date
                                          }
                                        />
                                      </div>

                                      <div className="col-4">
                                        <label htmlFor="">
                                          Currently Working Here
                                        </label>
                                        <div
                                          className="d-flex align-items-center justify-content-between"
                                          style={{
                                            width: "70%",
                                            height: "40px",
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <label className="radioContainer">
                                              <input
                                                type="radio"
                                                name={`work_detail[${index}].currently_working_here`}
                                                value={true}
                                                checked={
                                                  values.work_detail[index]
                                                    .currently_working_here ===
                                                  true
                                                }
                                                onChange={() => {
                                                  setFieldValue(
                                                    `work_detail[${index}].currently_working_here`,
                                                    true
                                                  );
                                                }}
                                              />

                                              <span className="radio"></span>
                                              <span className="label">Yes</span>
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <label className="radioContainer">
                                              <input
                                                type="radio"
                                                name={`work_detail[${index}].currently_working_here`}
                                                value={false}
                                                checked={
                                                  values.work_detail[index]
                                                    .currently_working_here ===
                                                  false
                                                }
                                                onChange={() => {
                                                  setFieldValue(
                                                    `work_detail[${index}].currently_working_here`,
                                                    false
                                                  );
                                                }}
                                              />

                                              <span className="radio"></span>
                                              <span className="label">No</span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-4 form-group">
                                        <label htmlFor="">End Date</label>
                                        <DateSelector
                                          placeholder="End Date"
                                          onChange={(value) => {
                                            setFieldValue(
                                              `work_detail[${index}].end_date`,
                                              value
                                            );
                                          }}
                                          minDateData={
                                            values.work_detail[index].start_date
                                          }
                                          disabled={
                                            values.work_detail[index]
                                              .currently_working_here
                                          }
                                          selectedDate={
                                            !values.work_detail[index]
                                              .currently_working_here &&
                                            values.work_detail[index].end_date
                                          }
                                        />
                                      </div>
                                    </div>

                                    <InputLayout classes="mt-3">
                                      <TextArea
                                        label="About"
                                        name={`work_detail[${index}].about`}
                                        placeholder="Enter About"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `work_detail[${index}].work_description`,
                                            e.target.value
                                          );
                                        }}
                                        value={
                                          values.work_detail[index]
                                            .work_description
                                        }
                                      />
                                    </InputLayout>
                                  </div>
                                  <hr
                                    className="mt-4"
                                    style={{ color: "#ced5de" }}
                                  />
                                </>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>

                      {/* Education */}
                      <div className="educations">
                        <FieldArray name="education">
                          {({ push, remove }) => (
                            <>
                              <div className="education-header">
                                <div className="h2-i">Education</div>
                                <button
                                  type="button"
                                  className="btn btn-outline-dark"
                                  style={{ minWidth: "180.76px" }}
                                  onClick={() =>
                                    push({
                                      id: null,
                                      field_of_study: null,
                                      institute_name: null,
                                      start_date: null,
                                      end_date: null,
                                      about: null,
                                      degree: null,
                                    })
                                  }
                                >
                                  Add Education
                                </button>
                              </div>

                              {values.education?.map((edu, index) => (
                                <>
                                  <div key={index}>
                                    <div className="education-detail">
                                      <p className="p semi-light">
                                        EDUCATION {index + 1}
                                      </p>
                                      <span
                                        className="remove"
                                        onClick={() => {
                                          const removedId =
                                            values.education[index].id;
                                          if (removedId) {
                                            setFieldValue("remove_education", [
                                              ...values.remove_education,
                                              removedId,
                                            ]);
                                          }
                                          remove(index);
                                        }}
                                      >
                                        REMOVE
                                      </span>
                                    </div>

                                    <div className="row">
                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Name of Institution
                                      "
                                          labelClassName="required-field"
                                          name={`education[${index}].institute_name`}
                                          placeholder="Enter Name of Institution
                                      "
                                          onChange={(e) =>
                                            setFieldValue(
                                              `education[${index}].institute_name`,
                                              e.target.value
                                            )
                                          }
                                          value={
                                            values.education[index]
                                              .institute_name
                                          }
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Degree"
                                          name={`education[${index}].degree`}
                                          placeholder="Enter Degree"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `education[${index}].degree`,
                                              e.target.value
                                            )
                                          }
                                          value={values.education[index].degree}
                                        />
                                      </InputLayout>
                                    </div>

                                    <div className="row mt-3">
                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Field of Study"
                                          name={`education[${index}].field_of_study`}
                                          placeholder="Enter Field of Study"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `education[${index}].field_of_study`,
                                              e.target.value
                                            )
                                          }
                                          value={
                                            values.education[index]
                                              .field_of_study
                                          }
                                        />
                                      </InputLayout>

                                      <InputLayout>
                                        <div className="row">
                                          <label htmlFor="">Period</label>
                                          <div className="col-6">
                                            <MonthYearSelector
                                              placeholder="Start Date"
                                              onChange={(value) => {
                                                setFieldValue(
                                                  `education[${index}].start_date`,
                                                  moment(
                                                    `${value}-10`
                                                  ).toISOString()
                                                );
                                                setFieldValue(
                                                  `education[${index}].min_date`,
                                                  value
                                                );
                                              }}
                                              selectedDate={
                                                values.education[index]
                                                  .start_date
                                              }
                                              maxDateData={
                                                values.education[index].max_date
                                              }
                                            />
                                          </div>
                                          <div className="col-6">
                                            <MonthYearSelector
                                              placeholder="End Date"
                                              onChange={(value) => {
                                                setFieldValue(
                                                  `education[${index}].end_date`,
                                                  moment(
                                                    `${value}-10`
                                                  ).toISOString()
                                                );
                                                setFieldValue(
                                                  `education[${index}].max_date`,
                                                  value
                                                );
                                              }}
                                              minDateData={
                                                values.education[index].min_date
                                              }
                                              selectedDate={
                                                values.education[index].end_date
                                              }
                                            />
                                          </div>
                                        </div>
                                      </InputLayout>
                                    </div>

                                    <div className="row mt-3">
                                      <TextArea
                                        label="Additional Information
                                    "
                                        placeholder="Enter Additional Information
                                    "
                                        onChange={(e) =>
                                          setFieldValue(
                                            `education[${index}].about`,
                                            e.target.value
                                          )
                                        }
                                        value={values.education[index].about}
                                      />
                                    </div>
                                  </div>
                                  <hr
                                    className="mt-4"
                                    style={{ color: "#ced5de" }}
                                  />
                                </>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </div>

                      {/* Certification */}
                      <div className="certificates">
                        <FieldArray name="certificates">
                          {({ push, remove }) => (
                            <>
                              <div className="certification-header">
                                <div className="h2-i">Certifications</div>

                                <button
                                  className="btn btn-outline-dark"
                                  onClick={() => push({ title: "", about: "" })}
                                >
                                  Add Certification
                                </button>
                              </div>

                              {values.certificates?.map(
                                (certificate, index) => (
                                  <>
                                    <div key={index}>
                                      <div className="certification-detail">
                                        <p className="p semi-light">
                                          CERTIFICATE {index + 1}
                                        </p>

                                        <span
                                          className="remove"
                                          onClick={() => {
                                            const removedId =
                                              values.certificates[index].id;
                                            if (removedId) {
                                              setFieldValue(
                                                "remove_certificate",
                                                [
                                                  ...values.remove_certificate,
                                                  removedId,
                                                ]
                                              );
                                            }
                                            remove(index);
                                          }}
                                        >
                                          REMOVE
                                        </span>
                                      </div>
                                      <InputLayout>
                                        <InputField
                                          type="text"
                                          label="Title"
                                          labelClassName="required-field"
                                          name={`certificates[${index}].title`}
                                          placeholder="Enter Certification Title"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `certificates[${index}].title`,
                                              e.target.value
                                            )
                                          }
                                          value={
                                            values.certificates[index].title
                                          }
                                        />
                                      </InputLayout>

                                      <InputLayout classes="mt-3">
                                        <TextArea
                                          label="About"
                                          name={`certificates[${index}].about`}
                                          placeholder="About Certification"
                                          height="100px"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `certificates[${index}].about`,
                                              e.target.value
                                            )
                                          }
                                          value={
                                            values.certificates[index].about
                                          }
                                        />
                                      </InputLayout>
                                    </div>
                                    <hr
                                      className="mt-4"
                                      style={{ color: "#ced5de" }}
                                    />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>

                      {!isSubscriptionExpired && <UserTeams />}
                    </>
                  )}
                  <div className="d-flex gap-3 justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!isValid || isSubmitting}
                      // onClick={() => handleEdit(values)}
                    >
                      {isSubmitting ? "Updating" : "Update"}
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </FormikForm>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withUnsavedChangesWarning(Edit);
