import "./CompanyStats.scss";
export const StatsConatiner = ({ count, category }) => {
  return (
    <div className="Investor-DataBase-Statistics">
      <span className="Investor-DataBase-Statistics-Number">{count}</span>
      <span className="Investor-DataBase-Statistics-cateegory">{category}</span>
    </div>
  );
};
const ComapanyStats = ({ children }) => {
  return (
    <div className="Investor-DataBase-Statistics-container">{children}</div>
  );
};

export default ComapanyStats;
