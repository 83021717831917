import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../../services";

const DireactDealsFunds = () => {
  const [cookies] = useCookies(["t"]);
  const [direactDealData, setDirectDealData] = useState();
  const [SPVData, setSPVData] = useState();

  const isMounted = useRef(true);

  const fetchDireactDeals = async () => {
    const res = await getData({
      endpoint: "Taxonomy/getStrategyByTypeName",
      params: {
        type_name: "Direct Deal",
      },
      token: cookies.t,
    });
    setDirectDealData(res);
  };
  const fetchSPVData = async () => {
    const res = await getData({
      endpoint: "Taxonomy/getStrategyByTypeName",
      params: {
        type_name: "Specialty Purpose Vehicle",
      },
      token: cookies.t,
    });
    setSPVData(res);
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchDireactDeals();
      fetchSPVData();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    direactDealData,
    SPVData,
  };
};
export default DireactDealsFunds;
