import React, { FC } from "react";
import "./type_div.scss";

interface TypeDivProps {
  data?: boolean | string;
  color?: string;
  classes?: string;
  style?: React.CSSProperties;
}

const TypeDiv: FC<TypeDivProps> = ({
  data,
  color: givenColor,
  classes,
  style,
}) => {
  // Define a function to map data values to colors

  const getColor = (data: any) => {
    let trimmedData;

    if (typeof data === "string") {
      trimmedData = data.replace(/\s/g, "");
    } else {
      trimmedData = data;
    }

    switch (trimmedData) {
      case "Active":
      case "Yes":
      case "true":
      case true:
        return "#B5E4CA";
      case "Inactive":
        return "#EFEFEF";
      case "No":
      case "Closed":
      case false:
        return "#FFB7B7";
      case "Reviewing":
        return "#75D4FE";
      case "Pending":
        return "#FEC556";
      default:
        return "#FFFFFF"; // Default color, you can change this to your preferred default color
    }
  };

  const color = givenColor || getColor(data);

  let styles: React.CSSProperties = {
    backgroundColor: color,
    ...style,
  };

  return (
    <div className={`type-div ${classes}`} style={styles}>
      <span>
        {typeof data === "boolean" ? (data ? "Active" : "Inactive") : data}
      </span>
    </div>
  );
};

export default TypeDiv;
