import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import PageCard from "../../../../atoms/PageCard";

const CompanyBlogs = () => {
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const [cookies] = useCookies(["cid"]);
  const { id } = useParams();
  const isMounted = useRef(true);

  const [blogData, setBlogData] = useState([]);

  const fetchData = async () => {
    // const res = await getData("/");
    const result = await getData({
      endpoint: "Blog/customer/getAllLibraryBlogs",
      params: { company_id: id || cookies.cid },
      token: cookies.t,
    });

    // console.log("ress", result);

    if (result) {
      setBlogData(result);
    }
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchData();
      isMounted.current = false;
    }
  }, []);

  return (
    <>
      {blogData?.total_record > 0 && (
        <div className="mb-3">
          <h4 className="h4-j">Company Blogs</h4>
          <div className="address-card-container-wrapper">
            <PageCard
              customStyles={{ backgroundColor: "#F6F6F6", padding: "20px" }}
              classes="address-card-container"
            >
              <div className="company-blog-card">
                {blogData?.data.slice(0, 5).map((blog) => (
                  <>
                    <div
                      key={blog.id}
                      className="d-flex gap-3  single-card cursor-pointer"
                      onClick={() => {
                        if (
                          userInfo?.has_master_access ||
                          userInfo?.investment_library_education
                        ) {
                          window.open(
                            `/dashboard/library/view/${blog?.id}`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            userInfo?.investor_learn_more_link,
                            "_blank"
                          );
                        }
                      }}
                    >
                      <img
                        src={blog?.cover_photo || blog?.thumbnail}
                        alt=""
                        className="company-blog-image"
                      />
                      <div>
                        <div className="view-more mb-1">{blog?.category}</div>
                        <div className="h4">{blog?.header}</div>
                        <div className="p-grey light">
                          Updated On :{" "}
                          {moment
                            .utc(blog?.date || blog?.modified_date)
                            .format("MMM D, YYYY")}
                        </div>
                      </div>
                    </div>
                    <hr style={{ borderTop: "1px solid #dbdbdb" }} />
                  </>
                ))}
              </div>
            </PageCard>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyBlogs;
