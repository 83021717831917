export const formatNumber = (num) => {
  if (num >= 1000000) {
    return Math.floor(num / 1000000) + "M+"; // Format millions without decimals
  } else if (num >= 1000) {
    return Math.floor(num / 1000) + "K+"; // Format thousands without decimals
  } else if (num >= 50) {
    let rounded = Math.floor(num / 50) * 50; // Round down to nearest multiple of 50
    return rounded + "+"; // Return rounded number with '+'
  } else {
    let roundedToFive = Math.ceil(num / 5) * 5;
    return roundedToFive + "+"; // Return rounded number with '+'
  }
};
