// Import necessary components and hooks
import { Box, Fade } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { deleteData, getData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import Loader from "../../../atoms/Loader";
import Search from "../../../atoms/Search";
import Pagination from "../../../molecules/Pagination";
import TabStrip from "../../../molecules/TabStrip";
import SavedCompanies from "./components/SavedCompanies";
import SavedPeople from "./components/SavedPeople";
import SavedProduct from "./components/SavedProduct";
import "./saved_list.scss";

// Convert array items to { value, label } format
export const convertToValueLabel = (array) => {
  return array
    ?.filter((item) => item !== null && item.trim() !== "")
    .map((item) => ({ value: item, label: item }));
};

const SavedList = () => {
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["t", "cuid"]);
  const dispatch = useDispatch();
  const loadingDataValue = useSelector(loaderData);

  // State hooks for component
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Company");
  const [totalActiveItem, setTotalActiveItem] = useState();
  const [savedData, setSavedData] = useState();
  const [isSearchedLib, setIsSearchedLib] = useState(false);
  const [searchedValueLib, setSearchedValueLib] = useState("");
  const [removeIcon, setRemoveIcon] = useState(false);
  const [loadShimmer, setLoadShimmer] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filters, setFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePageChange = useCallback((page) => setCurrentPage(page), []);
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // Set shimmer effect when loading data initially
  useEffect(() => {
    setTimeout(() => setLoadShimmer(false), 1000);
  }, []);

  const getSavedData = useCallback(async () => {
    setSavedData(null);
    setTotalActiveItem(null);
    setLoadShimmer(true);
    try {
      const res = await getData({
        endpoint: "SavedList/getAllSavedList",
        params: {
          search_query: searchedValueLib,
          type: activeTab,
          page: currentPage,
          page_limit: itemsPerPage,
          group: selectedFilter === "All" ? null : selectedFilter,
        },
        token: cookies.t,
      });

      setLoadShimmer(false);
      setSavedData(res);
      setTotalActiveItem(res?.total_record);

      if (selectedFilter && selectedFilter !== "All" && !res.total_record) {
        setSelectedFilter(null);
      }

      // Transform and set filters only on successful data fetch
      setFilters(convertToValueLabel(res?.distinct_groups || []));
    } catch (error) {
      console.error(error);
    }
  }, [
    searchedValueLib,
    cookies.t,
    activeTab,
    currentPage,
    itemsPerPage,
    selectedFilter,
  ]);

  // Re-fetch saved data when dependencies change
  useEffect(() => {
    getSavedData();
  }, [getSavedData]);

  // Handle screen width changes for icons
  useEffect(() => {
    setRemoveIcon(screenWidth < 765);
  }, [screenWidth]);

  // Remove item from saved data list
  const handleRemove = async (id) => {
    const res = await deleteData({
      endpoint: "SavedList/removeFromList",
      params: { id },
      token: cookies.t,
    });

    if (res) {
      getSavedData();
    }
  };

  const dataArray = useMemo(
    () => [
      {
        id: 0,
        label: "Company",
        view: (
          <>
            <SavedCompanies
              removeIcon={removeIcon}
              data={savedData}
              handleDelete={handleRemove}
              isSearched={searchedValueLib.length > 0}
              // filter={filters}
              selectedFilter={selectedFilter}
              onFilterChange={setSelectedFilter}
              loadShimmer={loadShimmer}
            />
            {totalActiveItem >= 10 && (
              <div className="mt-4 mb-4">
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalActiveItem}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            )}
          </>
        ),
      },
      {
        id: 1,
        label: "People",
        view: (
          <>
            <SavedPeople
              removeIcon={removeIcon}
              data={savedData}
              handleDelete={handleRemove}
              isSearched={searchedValueLib.length > 0}
              // filter={filters}
              selectedFilter={selectedFilter}
              onFilterChange={setSelectedFilter}
              loadShimmer={loadShimmer}
            />
            {totalActiveItem >= 10 && (
              <div className="mt-4 mb-4">
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalActiveItem}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            )}
          </>
        ),
      },
      {
        id: 2,
        label: "Product",
        view: (
          <>
            <SavedProduct
              removeIcon={removeIcon}
              data={savedData}
              handleDelete={handleRemove}
              isSearched={searchedValueLib.length > 0}
              // filter={filters}
              selectedFilter={selectedFilter}
              onFilterChange={setSelectedFilter}
              loadShimmer={loadShimmer}
            />
            {totalActiveItem >= 10 && (
              <div className="mt-4 mb-4">
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalActiveItem}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            )}
          </>
        ),
      },
    ],
    [
      savedData,
      selectedFilter,
      filters,
      currentPage,
      itemsPerPage,
      totalActiveItem,
    ]
  );

  const _getComponent = (tabs) => {
    return dataArray.find((item) => item.label === tabs)?.view;
  };

  const onTabChange = (event, value) => {
    setCurrentPage(1);
    setItemsPerPage(10);
    setActiveTab(value);
    setSelectedFilter(null);
  };

  const handleSearchLibrary = (searchedValueLib) => {
    setIsSearchedLib(true);
    setSearchedValueLib(searchedValueLib);
  };

  return (
    <div id="Saved-List-Container">
      {isLoading && <Loader />}
      <div className="saved-list-container">
        <div className="saved-list-header">
          <div className="h1-i mb-2">Saved List</div>
          <Search
            onSearchEmit={handleSearchLibrary}
            placeholder="Search by Name, Company, Type"
            style={{ maxWidth: "355px" }}
            phonestyle={{ minWidth: "92vw", maxWidth: "92vw" }}
          />
        </div>

        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={dataArray}
        />

        {/* Use Fade for smooth transition */}
        <Fade in={true} timeout={1200} key={activeTab}>
          <Box sx={{ width: "100%" }}>
            <Box>{_getComponent(activeTab)}</Box>
          </Box>
        </Fade>
      </div>
    </div>
  );
};

export default SavedList;
