import ArrowLeft from "../../Icons/ArrowLeft";
import ArrowRigth from "../../Icons/ArrowRigth";
import "./styles.scss";

const Crausel = ({ index, setIndex, dataLength, setIsClicked }) => {
  return (
    <div
      style={{ display: "flex", gap: "10px" }}
      className="carusel-atom-container"
    >
      <span
        onClick={() => {
          if (index - 1 >= 0) setIndex(index - 1);
          setIsClicked(true);
        }}
        className={
          index - 1 >= 0 ? "crausel-arrow-right" : "diable-arrow-carusel"
        }
      >
        <ArrowLeft />
      </span>
      <span
        onClick={() => {
          if (index + 1 <= dataLength - 4) setIndex(index + 1);
          setIsClicked(true);
        }}
        className={
          index + 1 <= dataLength - 4
            ? "crausel-arrow-right"
            : "diable-arrow-carusel"
        }
      >
        <ArrowRigth />
      </span>
    </div>
  );
};
export default Crausel;
