import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputLayout from "../../../../../atoms/InputLayout";

const ServiceProviderForm = ({setFieldValue, values,initial}) => {
  return (
    <Formik>
      <Form>
        <div className="row g-3 mt-1">
          <InputLayout>
            <label htmlFor="primeBroker">Prime Broker</label>
            <input type="text" id="add_details.prime_broker" name="add_details.prime_broker" placeholder="Enter Prime Broker" onChange={(e) => setFieldValue('add_details.prime_broker',e.target.value)} value={values.add_details.prime_broker}/>
            <ErrorMessage name="values.add_details.prime_broker" component="div" />
          </InputLayout>

          <InputLayout>
            <label htmlFor="administrator">Administrator</label>
            <input type="text" id="administrator" name="administrator"  placeholder="Administrator" onChange={(e) => setFieldValue('add_details.administrator',e.target.value)} value={values.add_details.administrator}/>
            <ErrorMessage name="administrator" component="div" />
          </InputLayout>

          <InputLayout>
            <label htmlFor="bankCustodian">Bank / Custodian</label>
            <input type="text" id="bankCustodian" name="bankCustodian" placeholder="Enter Bank / Custodian" onChange={(e) => setFieldValue('add_details.bank_custodian',e.target.value)} value={values.add_details.bank_custodian}/>
            <ErrorMessage name="bankCustodian" component="div" />
          </InputLayout>
        </div>

        <div className="row g-3 mt-2">
          <InputLayout>
            <label htmlFor="auditor">Auditor</label>
            <input type="text" id="auditor" name="auditor" placeholder="Enter Auditor" onChange={(e) => setFieldValue('add_details.auditor',e.target.value)} value={values.add_details.auditor}/>
            <ErrorMessage name="auditor" component="div" />
          </InputLayout>

          <InputLayout>
            <label htmlFor="legalCounsel">Legal Counsel</label>
            <input type="text" id="legalCounsel" name="legalCounsel" placeholder="Enter Legal Counsel" onChange={(e) => setFieldValue('add_details.legal_counsel',e.target.value)} value={values.add_details.legal_counsel}/>
            <ErrorMessage name="legalCounsel" component="div" />
          </InputLayout>
        </div>
      </Form>
    </Formik>
  );
};

export default ServiceProviderForm;
