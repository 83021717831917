import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { reverseTransformData } from "../../../../../../helpers/transformData";
import ExcelParser from "../../../../../organisms/ExcelParser";

const TrackAum = ({ data }: any) => {
  const [activeTab, setActiveTab] = useState("netAum");

  useEffect(() => {
    if (data?.net_aum && data?.show_net_aum) {
      setActiveTab("netAum");
    } else if (data?.monthly_aum && data?.show_monthly_aum) {
      setActiveTab("monthlyAum");
    }
  }, [data]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const createExportFile = (data: any, name: any) => {
    if (data) {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${name}.xlsx`);
    }
  };

  const handleExport = async () => {
    // }

    if (activeTab === "netAum" && data?.net_aum) {
      createExportFile(
        reverseTransformData(data?.net_aum, "download", "net"),
        "Net_aum"
      );
    } else if (activeTab === "monthlyAum" && data?.monthly_aum) {
      createExportFile(
        reverseTransformData(data?.monthly_aum, "download", "monthly"),
        "Monthly_aum"
      );
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4 me-2">
        <h1 className="h1-j">Track Record/AUM</h1>

        {/* {(data?.net_aum.length > 0 || data?.monthly_aum.length > 0) && (
          <button
            className="btn btn-outline-dark"
            style={{ marginRight: "10px" }}
            onClick={() => handleExport()}
          >
            Export
          </button>
        )} */}
      </div>

      <div
        className="d-flex gap-3 mb-4 hor-scroll-none"
        style={{ overflow: "scroll" }}
      >
        {data?.net_aum.length > 0 && data?.show_net_aum && (
          <button
            onClick={() => handleTabClick("netAum")}
            className={`btn  ${
              activeTab === "netAum" ? "btn-dark" : "btn-outline-dark"
            }`}
          >
            Show Net Returns
          </button>
        )}

        {data?.monthly_aum.length > 0 && data?.show_monthly_aum && (
          <button
            onClick={() => handleTabClick("monthlyAum")}
            className={`btn  ${
              activeTab === "monthlyAum" ? "btn-dark" : "btn-outline-dark"
            }`}
          >
            Show Monthly AUM
          </button>
        )}
      </div>

      {activeTab === "netAum" && data?.net_aum && data?.show_net_aum && (
        <>
          <h2 className="h3-j mb-small">Net Returns (%)</h2>
          <ExcelParser fileData={data?.net_aum} req_type="net" />
        </>
      )}

      {activeTab === "monthlyAum" &&
        data?.monthly_aum &&
        data?.show_monthly_aum && (
          <>
            <h2 className="h3-j mb-small">Monthly AUM ($MM)</h2>
            <ExcelParser fileData={data?.monthly_aum} req_type="monthly" />
          </>
        )}
    </div>
  );
};

export default TrackAum;
