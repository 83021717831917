import { useNavigate } from "react-router-dom";
import Brand from "../../../assets/brand/White.png";
import Logo from "../../../assets/brand/favicon.png";
import Mail from "../../Icons/Mail";
import "./footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="site-footer">
      <div className="footer-columns">
        <div className="footer-column">
          <div className="brand">
            <img id="img-1" src={Logo} alt="Logo" />
            <img src={Brand} alt="Logo" />
          </div>
          <div className="brand-info">
            {/* <div className="info">
              <i className="d-flex"><Phone/></i>
              <span>+1 212-960-3316</span>
            </div> */}
            <div className="info">
              <i className="d-flex">
                <Mail />
              </i>
              <span>info@primealpha.com</span>
            </div>
          </div>
        </div>
        <div className="footer-column">
          {/* <h3>Contact Us</h3> */}
          <ul>
            {/* <li>About Us</li>
            <li>Why PrimeAlpha</li>
            <li>Visionaries Podcast</li> */}
            {/* <li onClick={() => navigate("/help-center")}>Help Center</li>
            <li onClick={() => navigate("/faq")}>FAQs</li> */}
          </ul>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li onClick={() => navigate("/terms")}>Terms of Use</li>
            <li onClick={() => navigate("/policy")}>Privacy Policy</li>
            <li onClick={() => navigate("/eula")}>
              End User License Agreement
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright-info">
        <p>Copyright © PrimeAlpha, LLC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
