import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../SubscriptionContext";
import Default from "../../../assets/images/default.png";
import useScreenWidth from "../../../hooks/useScreenwidth";
import EditBlue from "../../Icons/EditBlue";
import View from "../../Icons/View";
import IconCircle from "../../atoms/IconCircle";
import TypeDiv from "../../atoms/TypeDiv";
import "./blog-card.scss";

const BlogCardWithEdit = ({ data, type }) => {
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const navigate = useNavigate();
  const handleView = () => {
    if (type === "Library") {
      navigate(`/dashboard/my-blogs/view/${data?.id}`);
    } else {
      navigate(`/dashboard/resources/view/${data?.id}/${btoa(data?.category)}`);
    }
  };

  const handleEdit = () => {
    if (type === "Library") {
      navigate(`/dashboard/my-blogs/edit/${data?.id}`);
    } else {
    }
  };

  const screenWidth = useScreenWidth();

  return (
    <div className="blog-card">
      <div className="blog-image">
        <img
          src={data?.thumbnail || data?.cover_photo || Default}
          alt="blog"
          className="image"
        />
        <span className="catregory-name-pic-container">{data?.category}</span>
        <div className="category-status-div">
          <TypeDiv
            classes="mt-1"
            data={data.status}
            color={
              data.status === "Rejected"
                ? "#FFB7B7"
                : data.status === "Published"
                ? "#B5E4CA"
                : "#FFD88D"
            }
            style={{ borderRadius: "4px" }}
          />
        </div>
        <div className="blog-card-btns">
          <div className="card-view-btn" onClick={() => handleView()}>
            {/* <CustomButton
              icon={
                <View className="Lock-white-container" width="17" height="17" />
              }
              classes="btn-xs"
              type="btn-primary"
              text={screenWidth > 480 ? "View" : ""}
              forMobile={screenWidth < 480 ? true : false}
            /> */}
            <IconCircle
              icon={
                <View className="Lock-white-container" width="17" height="17" />
              }
              size={40}
              color="#3d9be9"
              isClickable
            />
          </div>
          <div
            className="card-edit-btn"
            onClick={(e) =>
              isSubscriptionExpired ? handleUserClick(e) : handleEdit()
            }
          >
            {/* <CustomButton
              icon={<EditBlue className="black-fill" width="16" height="15" />}
              classes="btn-xs w-100 color-grey"
              text={screenWidth > 480 ? "Edit" : ""}
              forMobile={screenWidth < 480 ? true : false}
            /> */}{" "}
            <IconCircle
              icon={<EditBlue className="black-fill" width="17" height="17" />}
              size={40}
              color="#f4f4f4"
              border="1px solid #ced5de"
              isClickable
            />
          </div>
        </div>
      </div>
      <div className="h4-i semi-light mb-2 font-20">{data.header}</div>

      <div className="p-grey mb-1">
        {moment.utc(data?.updated_date).format("MMM D, YYYY")}
      </div>
      <div className="text-color">
        {data?.sub_header?.slice(0, 100)}
        {data?.sub_header?.length > 100 && <>...</>}
      </div>
    </div>
  );
};

export default BlogCardWithEdit;
