import React from "react";
import ProfilePhoto from "../../../assets/images/ProfilPhoto.png";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";

interface CardProps {
  profilePicture?: string;
  profileName: string;
  profilePosition?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const UserProfileCardRoad: React.FC<CardProps> = ({
  profilePicture,
  profileName,
  profilePosition,
  style,
  onClick,
}) => {
  const handleViewProfile = () => {
    // Add logic for viewing the profile
    console.log(`Viewing profile of ${profileName}`);
  };

  return (
    <div className={styles.cardRoad} style={style}>
      {profilePicture ? (
        <img
          src={profilePicture || ProfilePhoto}
          alt="Profile"
          className={styles.cardImgMain}
          width={40}
          height={40}
          style={{ width: "40px", height: "40px" }}
        />
      ) : (
        <CircleAvatar
          name={profileName}
          size="40px"
          fontSize="14px"
          color="#F4F4F4"
          iconColor="#3D9BE9"
          border="1px solid #efefef"
        />
      )}
      <div className={styles.profileName}>{profileName}</div>
      <div className={styles.profilePosition} title={profilePosition}>
        {profilePosition}
      </div>
      <div className="w-100">
        <button className={`${styles.viewProfileBtn}`} onClick={onClick}>
          View
        </button>
      </div>
    </div>
  );
};

export default UserProfileCardRoad;
