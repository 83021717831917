import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../assets/styles/toast";
import { requestInfoData } from "../../../../helpers/staticData";
import { postData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../atoms/Dropdown";
import TextArea from "../../../atoms/TextArea";
import Modal from "../../../molecules/Modal/Normal";
import style from "../forms.module.scss";

const RequestInfoForm = ({ type, mapType, mapId }: any) => {
  const dispatch = useDispatch();

  const [name, setName] = useState<any>();
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [cookies] = useCookies(["t", "cuid"]);
  const modalId = uuidv4(); // Generate a unique ID for each modal

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleOpenModal = () => {
    setIsActive(true);
  };

  const handleCloseModal = () => {
    setIsActive(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const res = await postData({
      endpoint: "RequestReport/customer/sendRequestOrReport",
      data: {
        type: type,
        category: name,
        comment: description,
        map_id: mapId,
        map_type: mapType,
        user_id: cookies.cuid,
      },
      params: {},
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      toast.success("Requested Successfully", {
        duration: 1000,
        style: confimationStyles,
      });
      handleCloseModal();
    }
    setName("");
    setDescription("");
  };

  return (
    <>
      <div className={style.inline_display}>
        <button className="btn btn-primary" onClick={handleOpenModal}>
          Request Info
        </button>
      </div>
      <Modal
        id={modalId}
        title="Request Info"
        onClose={handleCloseModal}
        width="430px"
        isActive={isActive}
      >
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="Category">Category</label>
              <SelectDropdown
                placeholder="Select Category"
                data={requestInfoData}
                onChange={(info) => setName(info)}
                selectedValue={name}
              />
            </div>
            <div className="mt-3 mb-4">
              <TextArea
                id="description"
                name="description"
                placeholder="Enter Description"
                value={description}
                onChange={handleDescriptionChange}
                label="Description"
              ></TextArea>
            </div>
            <button type="submit" className="btn btn-primary w-100 mb-3">
              {" "}
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default RequestInfoForm;
