import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { postData } from '../../../../services';

import toast from 'react-hot-toast';
import { confimationStyles, errorStyles } from '../../../../assets/styles/toast';
import Error from '../../../atoms/Error';
import InputField from '../../../atoms/InputField';

interface FormValues {
  email: string;
}

const ForgotPasswordForm: FC = () => {
//   const { mutate, isLoading } = useForgotPassword();
  const [pageError, setPageError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const emailLink = <a href="mailto:help@primealpha.com" style={{ textDecoration: 'underline' }}>help@primealpha.com</a>;

  const initialValues: FormValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
  });

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>): Promise<void> => {
    try {
      const lowercaseEmail = values.email.toLowerCase();
      const updatedValues = { ...values, email: lowercaseEmail };
      const { data: resultData, isLoading } = await postData({
        endpoint: '/CustomerAuth/forgotPassword',
        data: {},
        params: updatedValues,
        // token: 'your_token',
      });

      if(resultData.status){
        toast.success(resultData.message,{duration : 1000 , style : confimationStyles})
        navigate('/auth/login')
      } else {
        toast.error(resultData.message, {duration : 1000 , style : errorStyles})
        setPageError('This email is not registered with us. Please enter your registered email. If your email has changed, please email us at help@primealpha.com.')
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isValid, dirty, handleSubmit, isSubmitting, touched, errors, setFieldValue,handleBlur, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group">
            <InputField
            label="Email"
            type="text"
            id="email"
            name="email"
            value={values.email}
            onChange={(e : any) => setFieldValue("email", e.target.value)}
            onBlur={handleBlur}
            placeholder="Enter Email Address"
            autoComplete="off"
            className={`${
              (touched.email || isSubmitting) && errors.email || pageError
                ? "error-input"
                : ""
            }`}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\s/g, "");
            }}
          />
            {touched.email && errors.email && <Error error={errors.email} />}
            {pageError && values.email && !errors.email && <Error error={pageError} />}
          </div>

          <button className="btn btn-primary w-100 mt-4" disabled={isSubmitting} type="submit">
            Send Link
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
            )}
          </button>

          <button
            className="btn btn-outline-dark w-100 mt-4"
            type="button"
            onClick={() => navigate('/auth/login')}
          >
            Back to login
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
