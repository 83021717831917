import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../../services";
import Add from "../../../Icons/Add";
import EditBlue from "../../../Icons/EditBlue";
import View from "../../../Icons/View";
import Heading from "../../../atoms/Heading";
import ImageViewer from "../../../atoms/ImageViewer";
import Search from "../../../atoms/Search";
import NoContentCard from "../../../molecules/NoContentCard";
// import FilterComponent from "../../../molecules/filters/FilterComponent";
import { useSubscription } from "../../../../SubscriptionContext";
import AdvancedFilterComponent from "../../../molecules/filters/AdvanceFilterComponent";
import BlogTemplate from "../../../templates/BlogTemplate";
import "./thought.scss";

const ThoughtLeadership = () => {
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [categoryData, setCategoryData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const [blogData, setBlogData] = useState();
  const [cookies] = useCookies(["t", "cid"]);

  const mappedLibraryCategory = async () => {
    const res = await getData({
      endpoint: "/Blog/getMappedCategory",
      params: { type: "Library" },
      token: cookies.t,
    });
    setCategoryData(res);
  };

  const getBlogData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "Blog/getAllCompanyAddedBlogs",
        params: {
          search_query: searchValue,
          // categories: JSON.stringify(categories),
          type: "Library",
          company_id: cookies.cid,
          page: 1,
          page_limit: itemsPerPage,
          created_start_date: filters?.startDate0,
          created_end_date: filters?.endDate0,
          updated_start_date: filters?.startDate1,
          updated_end_date: filters?.endDate1,
          categories:
            filters?.Category && filters?.Category.length > 0
              ? JSON.stringify(filters?.Category)
              : null,
        },
        token: cookies.t,
      });

      if (res) {
        setBlogData(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [searchValue, cookies.t, itemsPerPage, filters]);

  useEffect(() => {
    getBlogData();
    mappedLibraryCategory();
  }, [getBlogData]);

  // handle filters

  // useEffect(() => {
  //   console.log('filters')
  // })

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const data = [];
  const navigate = useNavigate();

  const filteredCatData = Array.isArray(categoryData)
    ? categoryData?.map((item) => item.label)
    : [];
  const filterData = {
    Category: filteredCatData ? filteredCatData : [],
  };

  const handleFilterChange = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
      setIsSearched(true);
    } else {
      setFilters({});
      setIsSearched(false);
    }
  };

  const CustomNoRow = () => {
    return (
      <div className="noContentContainer">
        {Object.keys(filters).length || searchValue ? (
          <NoContentCard
            title="No post Found!"
            containerClass="pt-medium pb-0"
          />
        ) : (
          <NoContentCard
            title="No post added yet!"
            subtitle="Click on Add Post button to create post"
            tag="Add Post"
            handleClick={() => navigate("/dashboard/my-blogs/add")}
            containerClass="pt-medium pb-0"
          />
        )}
      </div>
    );
  };

  const handleSearchEmit = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleView = (data) => {
    navigate(`/dashboard/my-blogs/view/${data?.id}`);
  };

  const handleEdit = (data) => {
    navigate(`/dashboard/my-blogs/edit/${data?.id}`);
  };

  const columns = [
    {
      field: "header",
      headerName: "Title",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <ImageViewer imageUrl={params.row.cover_photo} altText="text" />
            <div className="d-flex flex-column gap-1">
              <span className="detail-main">{params.row.header}</span>
              <span className="detail-date">
                Created On:{" "}
                {moment
                  .utc(params.row.date || params.row.modified_date)
                  .format("MMM D, YYYY")}
              </span>
            </div>
          </div>
        );
      },
    },
    { field: "category", headerName: "Category", flex: 1, sortable: true },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <span type="button" onClick={() => handleView(params.row)}>
              <i className="fi fi-rr-eye actions-icon-custom"></i>
              <View />
            </span>

            <span
              type="button"
              onClick={() => handleEdit(params.row)}
              className="ms-3"
            >
              <EditBlue />
            </span>

            {/* <span
              type="button"
              onClick={() => handleDelete(params.row)}
              className="ms-3"
            >
              <i className="fi fi-rr-trash actions-icon-custom"></i>
            </span> */}
          </>
        );
      },
    },
  ];

  const loadMore = () => {
    setItemsPerPage((prev) => prev + 9);
  };

  return (
    <div className="thought-container" id="MyBlogs-Container">
      <Heading title="My Blogs" type="main" classes="mt-2" />

      <div>
        <div className="d-flex justify-content-between pt-3 main-blog-responsive">
          <Search
            onSearchEmit={(value) => {
              handleSearchEmit(value);
              setIsSearched(true);
            }}
            placeholder="Search by name, description"
          />

          <div className="d-flex gap-3 filter-blog">
            <AdvancedFilterComponent
              filterData={filterData}
              onFilterChange={handleFilterChange}
              isDateRange
              dateRangeCount={2}
              dateRangeNames={["Create Date", "Update Date"]}
              type="right"
            />
            <button
              className="btn btn-primary d-flex gap-2"
              onClick={(e) =>
                isSubscriptionExpired
                  ? handleUserClick(e)
                  : navigate("/dashboard/my-blogs/add")
              }
            >
              <Add /> Add Post
            </button>
          </div>
        </div>

        {/* <CustomDatagrid
          rows={blogData?.data || []}
          columns={columns}
          getRowId={(row) => row.id}
          height={() => 90}
          CustomNoRowsOverlay={CustomNoRow}
          hoverColor="ffffff"
        /> */}

        <>
          <BlogTemplate
            data={blogData?.data}
            type="Library"
            isSearched={isSearched}
            blogReq="edit"
          />

          <div className="load-more">
            {blogData?.total_record >= itemsPerPage && (
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={() => loadMore()}
              >
                Load More
              </button>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default ThoughtLeadership;
