import React from "react";
import defaultCoverPhoto from "../../../assets/images/default.png";
import style from "./profile_card.module.scss";
interface ProfileCardProps {
  coverPhoto?: string;
  logo?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ coverPhoto, logo }) => {
  // return (
  //   <div className='parent'>
  //     <div className={style.cover_photo}>
  //       <img src={coverPhoto} alt="Cover" />
  //     </div>
  //     <div className={style.profile_photo}>
  //       <img src={logo} alt="Profile" />
  //     </div>
  //   </div>
  // );

  return (
    <div className={style.parent}>
      <img
        src={coverPhoto ? coverPhoto : defaultCoverPhoto}
        alt="Cover"
        style={{ width: "100%", height: "200px", objectFit: "cover" }}
      />

      {/* Profile Picture */}
      <img
        src={logo || defaultCoverPhoto}
        alt="Profile"
        style={{
          width: "120px",
          height: "120px",
          objectFit: "cover",
          borderRadius: "25%",
          position: "relative",
          top: "-50px",
          left: "10%",
          transform: "translateX(-50%)",
          border: "2px solid #efefef",
          backgroundColor: "#fffff",
        }}
      />
    </div>
  );
};

export default ProfileCard;
