import { useEffect, useRef, useState } from "react";
import CustomButton from "../../atoms/CustomeButton";
import SortIcon from "../../Icons/Sort";
import "./styles.scss";

const SortFunctionality = ({
  headingMobile,
  headingText = "Sort",
  reset,
  handleSort,
  // Provide a default value for containerRef
}) => {
  const [selectedSort, setSelectedSort] = useState(null); // State to manage selected sort option
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown open/close

  const handleSortClick = (sortOption) => {
    setSelectedSort(sortOption); // Update the selected sort state
    // Add any additional logic for sorting here
    handleSort(sortOption);
  };

  useEffect(() => {
    if (reset) {
      setSelectedSort(null);
      handleSort(null);
    }
  }, [reset]);
  const containerRef = useRef(null);

  const sortOptions = [
    { label: "Name A-Z", value: "AtoZ", key: "1" },
    { label: "Name Z-A", value: "ZtoA", key: "2" },
    { label: "Created Date", value: "Created", key: "3" },
    { label: "Updated Date", value: null, key: "4" },
  ];

  const toggleFilterBox = (e) => {
    e.stopPropagation(); // Prevent event bubbling to avoid closing the dropdown immediately
    setIsOpen((prev) => !prev);
  };

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Stop propagation to avoid toggling dropdown when clicking inside
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if containerRef and current exist
      if (!containerRef || !containerRef.current) return;

      const targetClassList = event.target.classList;
      const excludedClassesRegex = /^Mui.*/; // Regular expression to match classes starting with "Mui"

      const isExcluded =
        targetClassList &&
        Array.from(targetClassList).some((className) =>
          excludedClassesRegex.test(className)
        );

      if (
        !containerRef.current.contains(event.target) &&
        !isExcluded &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, isOpen]);

  return (
    <div
      className={`sort-func-conatiner ${
        headingMobile
          ? "filter-container filter-mobile-container"
          : "filter-container"
      }`}
      ref={containerRef}
      onClick={handleContainerClick} // Handle clicks inside the container
    >
      <CustomButton
        handleClick={toggleFilterBox} // Only handle button clicks to toggle
        icon={<SortIcon />}
        text={headingMobile ? "" : headingText}
        classes={
          headingMobile
            ? `${
                selectedSort ? "multi-dot-indicator active-filter" : ""
              } filter-mobile-container-btn`
            : `${
                selectedSort ? "multi-dot-indicator active-filter" : ""
              } filterbtn`
        }
      />

      {isOpen && (
        <div
          className="positionStyles dropdown-content"
          onClick={handleContainerClick}
        >
          <ul>
            {sortOptions.map(({ label, value, key }) => (
              <li
                key={key}
                className={selectedSort === value ? "selected" : ""}
                onClick={() => handleSortClick(value)}
              >
                {label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SortFunctionality;
