import React from 'react'
import FormHeading from '../../atoms/FormHeading'
import ForgotPasswordForm from '../../organisms/forms/authForms/ForgorPasswordForm'

const Verification = () => {
  return (
   <div>
        <FormHeading title='Forgot Password'  description='Enter the email address you used when you joined, and we will send you a password reset link.'  type='center'/>
        <ForgotPasswordForm/>
   </div>
  )
}

export default Verification