import moment from "moment";
import { useNavigate } from "react-router-dom";
import { convertToValueLabel } from "..";
import ProductImg from "../../../../../assets/images/Product.png";
import { useSubscription } from "../../../../../SubscriptionContext";
import TypeDiv from "../../../../atoms/TypeDiv";
import DeleteRed from "../../../../Icons/DeleteRed";
import NoContentCard from "../../../../molecules/NoContentCard";
import BlogFilters from "../../../../organisms/blogFilters/SaveListFilters";
import "../saved_list.scss";
import Shimmer from "../Shimmer/shimmer";

const SavedProducts = ({
  removeIcon,
  data,
  handleDelete,
  isSearched,
  filter,
  onFilterChange,
  loadShimmer,
  selectedFilter,
}: any) => {
  const navigate = useNavigate();
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  return (
    <div className="saved-data-container">
      {loadShimmer ? (
        <Shimmer />
      ) : (
        <>
          <div className="mt-3">
            <BlogFilters
              data={convertToValueLabel(data?.distinct_groups)}
              onFilterSelect={(option: any) => onFilterChange(option)}
              showDeleteIcon
              type="Product"
              alreadySelected={selectedFilter}
            />
          </div>

          {data?.data?.length > 0 ? (
            <>
              {data?.data?.map((item: any) => (
                <div className="saved-item-container">
                  <div
                    className="saved-item cursor-pointer"
                    key={item.product_id}
                    onClick={(e) => {
                      isSubscriptionExpired
                        ? handleUserClick(e)
                        : window.open(
                            `/dashboard/product/${item.product_id}`,
                            "_blank"
                          );
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-start gap-4">
                        <img
                          src={ProductImg}
                          alt=""
                          className="product-image"
                        />
                        <div>
                          <div className="d-flex align-items-center gap-3 mb-2">
                            <h4 className="h4-i semi-light mb-0">
                              {item?.name}
                            </h4>
                            {(!selectedFilter || selectedFilter === "All") && (
                              <TypeDiv
                                data={item?.group_name}
                                color="#FFD88D"
                              />
                            )}
                          </div>
                          <p className="p mb-1">{item.product_category}</p>
                          <p className="p mb-1">
                            {moment
                              .utc(item.updated_date)
                              .format("MMM D, YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className={
                      !removeIcon ? "btn btn-outline-danger" : "manage-icon"
                    }
                    onClick={(e) => {
                      isSubscriptionExpired
                        ? handleUserClick(e)
                        : handleDelete(item.saved_list);
                    }}
                  >
                    {!removeIcon ? (
                      "Remove"
                    ) : (
                      <i className="d-flex">
                        <DeleteRed className="Saved-delete-icon-container" />
                      </i>
                    )}
                  </button>
                </div>
              ))}
            </>
          ) : (
            <div className="non-saved">
              {isSearched ? (
                <NoContentCard title="Sorry! No Result Found" type="Search" />
              ) : (
                <NoContentCard title="No Product Saved Yet !" type="" />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SavedProducts;
