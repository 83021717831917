const ViewData = ({ data }) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="name" className="mb-0">
          Name
        </label>
        <p className="view-muted">{data?.title || "--"}</p>
      </div>

      <div className="form-group mt-3">
        <label htmlFor="url" className="mb-0">
          URL
        </label>
        <p className="view-muted">{data?.link || "--"}</p>
      </div>

      <div className="form-group mt-3">
        <label htmlFor="status" className="mb-0">
          Status
        </label>
        <p className="view-muted">
          {data?.status && data?.status ? "Active" : "Inactive"}
        </p>
      </div>
      <div className="form-group mt-3">
        <label htmlFor="url" className="mb-0">
          Description
        </label>
        <p className="view-muted mb-2">{data?.description}</p>
      </div>
    </>
  );
};

export default ViewData;
