const Library = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_6917_20057)">
        <path
          d="M12.7501 0H5.25012C4.25593 0.00119089 3.30279 0.396661 2.59979 1.09966C1.89678 1.80267 1.50131 2.7558 1.50012 3.75V15C1.50012 15.7956 1.81619 16.5587 2.3788 17.1213C2.94141 17.6839 3.70447 18 4.50012 18H12.7501C13.7443 17.9988 14.6975 17.6033 15.4005 16.9003C16.1035 16.1973 16.4989 15.2442 16.5001 14.25V3.75C16.4989 2.7558 16.1035 1.80267 15.4005 1.09966C14.6975 0.396661 13.7443 0.00119089 12.7501 0V0ZM15.0001 3.75V12H6.00012V1.5H12.7501C13.3469 1.5 13.9192 1.73705 14.3411 2.15901C14.7631 2.58097 15.0001 3.15326 15.0001 3.75ZM4.50012 1.629V12C3.97334 11.9995 3.45583 12.1385 3.00012 12.4028V3.75C3.00019 3.28477 3.14447 2.83099 3.4131 2.45115C3.68173 2.07131 4.0615 1.78408 4.50012 1.629ZM12.7501 16.5H4.50012C4.1023 16.5 3.72077 16.342 3.43946 16.0607C3.15816 15.7794 3.00012 15.3978 3.00012 15C3.00012 14.6022 3.15816 14.2206 3.43946 13.9393C3.72077 13.658 4.1023 13.5 4.50012 13.5H15.0001V14.25C15.0001 14.8467 14.7631 15.419 14.3411 15.841C13.9192 16.2629 13.3469 16.5 12.7501 16.5Z"
          fill="#4E5660"
        />
      </g>
      <defs>
        <clipPath id="clip0_6917_20057">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Library;
