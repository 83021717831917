import React, { ChangeEvent, FocusEvent, useEffect, useRef } from "react";

interface InputFieldProps {
  label: string;
  type: string;
  fieldType?: string;
  id: string;
  name: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void; // New onInput prop
  placeholder: string;
  className?: string; // New className prop
  autoComplete?: string;
  disabled?: any;
  inputmode?: any;
  pattern?: any;
  onKeyDown?: any;
  autoFocus?: boolean;
  touched?: boolean;
  labelClassName?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  id,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  onInput, // Include onInput prop
  placeholder,
  className,
  autoComplete,
  disabled,
  inputmode,
  pattern,
  onKeyDown,
  fieldType,
  autoFocus = false,
  touched,
  labelClassName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fieldType === "pass" && touched) {
      if (value === "" || !value) {
        inputRef?.current?.focus();
      }
    }
  }, [value]);

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      )}

      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput} // Pass onInput prop to the input element
        placeholder={placeholder}
        className={className}
        autoComplete={autoComplete}
        disabled={disabled}
        inputMode={inputmode}
        pattern={pattern}
        onKeyDown={onKeyDown}
        ref={inputRef}
      />
    </div>
  );
};

export default InputField;
