import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import topLoader from "./slices/loaderSlice";
import peopleData from "./slices/peopleSlice";
import savedGroups from "./slices/savedSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  peopleData: peopleData,
  topLoader: topLoader,
  savedGroups: savedGroups,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const persistor = persistStore(store);
