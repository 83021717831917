import { useEffect, useState } from "react";
import CloseBtnIcom from "../../molecules/Modal/CloseBtnIcon";
import "./tag.scss"; // Import your CSS file

const TagInput = ({ placeholder, selectedTags, onChange, id }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // console.log("useEffect - selectedTags:", selectedTags);

    // Check if selectedTags is truthy and non-empty before updating
    if (selectedTags && selectedTags.length > 0) {
      // console.log("Setting tags from selectedTags");
      setTags(selectedTags);
    }
  }, [selectedTags]); // Include selectedTags in the dependency array

  const handleInputChange = (e) => {
    // console.log("Input value changed:", e.target.value);
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault(); // Prevent default behavior

      const newTag = inputValue.trim();
      if (!tags?.includes(newTag)) {
        setTags((prevTags) => {
          const updatedTags = [...prevTags, newTag];
          setInputValue("");
          onChange(updatedTags);
          // console.log("Tags after adding:", updatedTags);
          return updatedTags;
        });
      } else {
        setInputValue("");
      }
    }
  };

  const handleTagRemove = (tagToRemove) => {
    // console.log('remoev -called')
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    onChange(newTags);
    // console.log("Tags after removal:", newTags);
  };

  // console.log("Render - tags:", tags);

  return (
    <div className="tag-input-container" key={id}>
      <div className="tag-input">
        {tags.length > 0 &&
          tags?.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
              <button
                className="remove-button"
                onClick={() => handleTagRemove(tag)}
              >
                <i className="">
                  <CloseBtnIcom className="tag-close-btn-container" />
                </i>
              </button>
            </div>
          ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={placeholder || "Add tags..."}
          className="tag-input-feild"
          // autoFocus
        />
      </div>
    </div>
  );
};

export default TagInput;
