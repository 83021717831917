import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { patchData } from "../../../../../services";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import RevokeIcon from "../../../../Icons/Revoke";
import View from "../../../../Icons/View";
import TypeDiv from "../../../../atoms/TypeDiv";
import DeleteModal from "../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../molecules/Modal/Normal";
import SharedView from "../sharedView";
import "./sharingPhone.scss";
const ContentSharingPhone = ({
  data,
  modalStateRevoke,
  setModalStateRevoke,
}) => {
  const [contentSharedData, setContentSharedData] = useState([]);
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    isViewModalOpen: false,
    modalId: "",
    name: "",
    formData: null,
  });

  const handleClickRevoke = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      name: `View Request Information`,
      formData: data,
    }));
  };
  const handleUpdate = async () => {
    dispatch(setLoading(true));
    try {
      const res = await patchData({
        endpoint: "ContentSharing/revokeLink",
        params: {
          link_id: modalStateRevoke?.formData?.id,
        },
      });
      if (res) {
        toast.success("Status Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        dispatch(setLoading(false));
        setModalStateRevoke((prev) => ({
          ...prev,
          isViewModalOpen: false,
        }));
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="content-sharing-phone-container">
        {data?.map((item, index) => {
          return (
            <div className="sharing-details-phone-wrapper">
              <div className="sharing-details-content-conatiner">
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Module</div>
                  <div className="p-light-small">{item?.module}</div>
                </div>
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Type</div>
                  <div className="p-light-small">{item?.type}</div>
                </div>
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Shared</div>
                  <div className="p-light-small">
                    {moment(item?.data).format("MMM D, YYYY hh:mm a")}
                  </div>
                </div>
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Expiry</div>
                  <div className="p-light-small">
                    {moment(item?.expiry).format("MMM D, YYYY hh:mm a")}
                  </div>
                </div>
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Status</div>
                  <div className="p-light-small">
                    <TypeDiv
                      data={item?.status}
                      color={item?.status === "Revoked" ? "#FFB7B7" : "#B5E4CA"}
                      style={{ color: "black" }}
                    />
                  </div>
                </div>
                <div className="detail-contet-item-phone">
                  <div className="p-dark">Email</div>
                  <div className="p-light-small">
                    {item.email ? item.email : "--"}
                  </div>
                </div>
              </div>
              <div className="sharing-details-phone-btns">
                {item?.status === "Shared" ? (
                  <button
                    className="btn btn-outline-dark "
                    onClick={() => {
                      // console.log("data[index]?.id", data[index]?.id);
                      setModalStateRevoke((prev) => ({
                        ...prev,
                        isViewModalOpen: true,
                        modalId: data[index]?.id,
                        formData: item,
                      }));
                    }}
                  >
                    <span className="mb-1">
                      <RevokeIcon />
                    </span>{" "}
                    Revoke
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => handleClickRevoke(data[index])}
                >
                  <View className="sharing-view-icon" /> View
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          title={`View Shared content`}
          width="642px"
          overflowStyle={{ overflow: "visible" }}
        >
          <SharedView
            data={modalState.formData}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            // onUpdate={() => getRequestData()}
          />
        </Modal>
      )}
      {modalStateRevoke?.isViewModalOpen && (
        <DeleteModal
          isActive={modalStateRevoke?.isViewModalOpen}
          onClose={() => {
            setModalStateRevoke((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }));
          }}
          onClick={handleUpdate}
          title="Shared Content"
          name="revoke"
          type="Revoke"
          description="Are you sure need to revoke the shared content?"
        ></DeleteModal>
      )}
    </>
  );
};
export default ContentSharingPhone;
