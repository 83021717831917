import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";
import TypeDiv from "../../../atoms/TypeDiv";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import NoContentCard from "../../../molecules/NoContentCard";
import Pagination from "../../../molecules/Pagination";

const BlogRequests = ({ searchedValue, filters }) => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tableData, setTableData] = useState({});

  const getBlogRequests = useCallback(async () => {
    const res = await getData({
      endpoint: "Blog/customer/getCustomerSentRequest",
      token: cookies.t,
      params: {
        user_id: cookies.cuid,
        page: currentPage,
        page_limit: itemsPerPage,
        search_query: searchedValue,
        status:
          filters?.Status?.length > 0 ? JSON.stringify(filters?.Status) : null,
        start_date: filters?.startDate,
        end_date: filters?.endDate,
      },
    });

    setTableData(res);
  }, [cookies.t, searchedValue, filters, itemsPerPage, currentPage]);

  useEffect(() => {
    getBlogRequests();
  }, [getBlogRequests]);
  const columns = [
    {
      field: "header",
      headerName: "Blog Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Blog Category",
      flex: 1,
    },
    {
      field: "created_date",
      headerName: "Request Date",
      renderCell: (params) => {
        return (
          <span>
            {moment(params.row.created_date).format("MMM D, YYYY hh:mm a")}
          </span>
        );
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <TypeDiv
            data={params.row.status}
            color={
              params.row.status === "Pending"
                ? "#FFD88D"
                : params.row.status === "Published"
                ? "#B5E4CA"
                : "#FFB7B7"
            }
          />
        );
      },
      flex: 0.5,
    },
  ];

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard title="No Record Found" />
      </>
    );
  });
  return (
    <>
      <CustomDatagrid
        columns={columns}
        rows={tableData?.data || []}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />

      {tableData?.total_record >= 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.total_record}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default BlogRequests;
