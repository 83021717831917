const Investors = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00267 16.2965L1.42368 16.2974C1.37929 16.2974 1.33658 16.2785 1.3056 16.2468C1.27377 16.2143 1.25619 16.1714 1.25619 16.126C1.25619 16.1251 1.25619 14.8831 1.25619 14.8831C1.25619 14.1708 1.7528 13.5434 2.51824 13.0103C3.88497 12.0554 6.09669 11.4537 8.58394 11.4537C8.9943 11.4537 9.39711 11.4708 9.79072 11.5025C10.1358 11.5308 10.4389 11.2668 10.4665 10.9128C10.4933 10.5588 10.2354 10.2494 9.88954 10.2211C9.46411 10.186 9.02863 10.168 8.58394 10.168C5.80191 10.168 3.33894 10.8803 1.80975 11.9474C0.643168 12.7617 0 13.7963 0 14.8823V16.126C0 16.5117 0.149905 16.8828 0.417054 17.1563C0.684203 17.4288 1.04598 17.5831 1.42368 17.5823C1.42368 17.5831 9.00267 17.5823 9.00267 17.5823C9.34938 17.5823 9.63076 17.2943 9.63076 16.9394C9.63076 16.5854 9.34938 16.2965 9.00267 16.2965Z"
        fill="#4E5660"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58392 0C6.0414 0 3.97791 2.112 3.97791 4.71429C3.97791 7.31657 6.0414 9.42857 8.58392 9.42857C11.1264 9.42857 13.1899 7.31657 13.1899 4.71429C13.1899 2.112 11.1264 0 8.58392 0ZM8.58392 1.28571C10.433 1.28571 11.9338 2.82171 11.9338 4.71429C11.9338 6.60686 10.433 8.14286 8.58392 8.14286C6.73482 8.14286 5.23409 6.60686 5.23409 4.71429C5.23409 2.82171 6.73482 1.28571 8.58392 1.28571Z"
        fill="#4E5660"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9204 11.2773C11.8475 11.3159 11.783 11.369 11.7303 11.4341C10.8133 12.551 10.2605 13.5153 10.0219 14.3236C9.83259 14.9621 9.82924 15.5236 9.97412 16.0104C10.212 16.8076 10.8568 17.4427 11.9346 17.8576C12.1758 17.9501 12.4304 17.9981 12.6875 17.9981C13.2511 17.9999 14.6455 17.9999 15.2124 17.9999C15.4737 17.9999 15.7325 17.951 15.977 17.855C17.1017 17.4144 17.7231 16.7399 17.93 15.917C18.2072 14.8113 17.6695 13.2856 16.173 11.4401C16.1202 11.3741 16.0541 11.3201 15.9804 11.2807L16.3715 10.1793C16.4929 9.80643 16.4318 9.39672 16.2073 9.07872C15.9829 8.75986 15.6228 8.57129 15.2392 8.57129H12.6624C12.2788 8.57129 11.9187 8.75986 11.6943 9.07872C11.4698 9.39672 11.4095 9.80643 11.5301 10.1793L11.9204 11.2773ZM15.1496 9.857L14.6002 11.4033C14.4872 11.7204 14.6337 12.0719 14.9344 12.2099L15.2987 12.3761C15.9569 13.2024 16.4033 13.9336 16.6177 14.5747C16.7894 15.0899 16.8069 15.527 16.5959 15.8819C16.3999 16.211 16.0356 16.4553 15.5281 16.6541C15.5273 16.6541 15.5273 16.6541 15.5273 16.655C15.4268 16.6936 15.3204 16.7141 15.2124 16.7141C14.6463 16.7141 13.2544 16.7141 12.69 16.7124C12.6892 16.7124 12.6883 16.7124 12.6875 16.7124C12.582 16.7124 12.4773 16.6927 12.3776 16.6541C12.3776 16.6541 12.3776 16.6541 12.3768 16.6541C11.7462 16.4116 11.3149 16.1021 11.1759 15.6359C11.0712 15.2819 11.1382 14.8636 11.3383 14.3707C11.5753 13.7879 11.9941 13.1261 12.5987 12.3753C12.5995 12.3744 12.9647 12.209 12.9647 12.209C13.267 12.0719 13.4144 11.7187 13.3013 11.4016L12.7528 9.857H15.1496Z"
        fill="#4E5660"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8355 10.9287H12.0669C11.7202 10.9287 11.4388 11.2167 11.4388 11.5716C11.4388 11.9264 11.7202 12.2144 12.0669 12.2144H15.8355C16.1822 12.2144 16.4636 11.9264 16.4636 11.5716C16.4636 11.2167 16.1822 10.9287 15.8355 10.9287Z"
        fill="#4E5660"
      />
    </svg>
  );
};

export default Investors;
