import { Formik, Form as FormikForm } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import useRecaptcha from "../../../../hooks/useRecaptcha";
import useTimer from "../../../../hooks/useTimer";
import { getData, postData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Error from "../../../atoms/Error";
import InputField from "../../../atoms/InputField";
import PasswordField from "../../../atoms/PasswordField";
import ReCaptchaTerms from "../../../atoms/RecaptchaTerms";
import styles from "../forms.module.scss";
import { commonLogicForLoginAndVerification } from "./commonLogic";

interface FormValues {
  email: string;
  password: string;
}

const Form: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cookie, setCookie] = useCookies(["cuid", "email", "role"]);
  const [pageError, setPageError] = useState<string | undefined>(undefined);

  // State to track reCAPTCHA token refresh
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);

  // Call the useRecaptcha hook and pass the `refreshRecaptcha` state to regenerate the token when needed
  const recaptchaToken = useRecaptcha("LOGIN", refreshRecaptcha);

  useTimer();

  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const numberToNumeric: any = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const recaptchaResponse = await getData({
      endpoint: "Wrapper/verifyRecaptcha",
      params: { token: recaptchaToken, expected_action: "LOGIN" },
    });

    if (recaptchaResponse?.riskScore > 0.5) {
      try {
        const lowercaseEmail = values.email.toLowerCase();
        const updatedValues = { ...values, email: lowercaseEmail };
        dispatch(setLoading(true));

        const { data: resultData } = await postData({
          endpoint: "/CustomerAuth/customerLogin",
          data: {},
          params: updatedValues,
        });

        if (resultData.status && resultData.response === 200) {
          setCookie("cuid", resultData.data.user_id, {
            path: "/",
            expires: expitationTime(),
          });

          if (resultData?.data?.token) {
            commonLogicForLoginAndVerification(
              resultData?.data,
              cookie,
              setCookie
            );
            navigate("/dashboard");
          } else {
            localStorage.setItem("countdownStartTime", "30");
            navigate("/auth/verify");
            toast.success(resultData.message, {
              duration: 1000,
              style: confimationStyles,
            });
          }

          dispatch(setLoading(false));
        } else {
          toast.error(resultData.message, {
            duration: 1000,
            style: errorStyles,
          });

          setRefreshRecaptcha((prev) => !prev);

          // When the password is incorrect, refresh the recaptcha token
          if (resultData.response === 401) {
            if (resultData?.data?.limit_left > 0) {
              const triesLeft = numberToNumeric[resultData?.data?.limit_left];
              setPageError(
                `You've only ${triesLeft} tr${
                  resultData?.data?.limit_left === 1 ? "y" : "ies"
                } remaining`
              );
            } else if (resultData?.data?.limit_left === 0) {
              setPageError("No attempts left");
            } else {
              setPageError(undefined);
            }
          } else {
            setPageError(undefined);
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("Error Occurred", {
          duration: 1000,
          style: errorStyles,
        });
        setRefreshRecaptcha((prev) => !prev);
      } finally {
        setSubmitting(false);
      }
    } else {
      toast.error("Recaptcha Not Verified", {
        duration: 1000,
        style: errorStyles,
      });

      // If recaptcha is not verified, refresh the token
      setRefreshRecaptcha((prev) => !prev); // Toggle to regenerate token

      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <InputField
            label="Email"
            type="text"
            id="email"
            name="email"
            value={values.email}
            onChange={(e) => setFieldValue("email", e.target.value)}
            onBlur={handleBlur}
            placeholder="Enter Email Address"
            autoComplete="off"
            className={`${
              (touched.email || isSubmitting) && errors.email
                ? "error-input"
                : ""
            }`}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\s/g, "");
            }}
          />
          {(touched.email || isSubmitting) && errors.email && (
            <Error error={errors.email} />
          )}

          <div className="form-group mt-4">
            <PasswordField
              label="Password"
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.password || isSubmitting) && errors.password
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.password || isSubmitting) && errors.password && (
              <Error error={errors.password} />
            )}
          </div>

          {pageError && <Error error={pageError} />}

          <button
            className="btn btn-primary w-100 mt-4"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Loading" : "Login"}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className={`${styles.btn} btn btn-outline-dark w-100 mt-4`}
            onClick={() => navigate("/pass/forgot-password")}
            type="button"
          >
            Forgot Password?
          </button>

          <ReCaptchaTerms classes="mt-3 mb-3" />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
