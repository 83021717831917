import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSubscription } from "../../../../SubscriptionContext";
import Default1 from "../../../../assets/images/NoCompany.png";
import Default from "../../../../assets/images/default.png";
import { roles } from "../../../../helpers/roles";
import { scrollToTop } from "../../../../helpers/scrollToTop";
import { getData } from "../../../../services/index";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import DotsIcon from "../../../Icons/Dots";
import CircularDropdown from "../../../atoms/CircularDropdown";
import Heading from "../../../atoms/Heading";
import ProfileCard from "../../../atoms/ProfileCard";
import TypeDiv from "../../../atoms/TypeDiv";
import SaveComponent from "../../../molecules/SaveComponent";
import ReportForm from "../../../organisms/forms/ReportForm";
import RequestInfoForm from "../../../organisms/forms/RequestInfoForm";
import Shimmer from "./Shimmer/shimmer";
import "./company.scss";
import Address from "./components/Address";
import CompanyBlogs from "./components/CompanyBlogs";
import Data from "./components/Data";

interface CompanyData {
  company_overview: {
    cover_photo?: string;
    profile_photo?: string;
    company_name?: string;
    role?: string;
    company_id?: string;
    saved_list?: string;
  };
  address: [
    {
      state_region?: string;
      country?: string;
    }
  ];
}

const Company: React.FC = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cid", "t", "is_admin", "role"]);
  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const loadingDataValue = useSelector(loaderData);

  window.scrollTo({ top: 0, behavior: "auto" });

  const [data, setData] = useState<CompanyData>({
    company_overview: {},
    address: [{}],
  });
  const [reportShow, setReportShow] = useState(false);
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const result = await getData({
          endpoint: "Company/customer/getCompanyProfileDetails",
          params: { company_id: id || cookies.cid },
          token: cookies.t,
        });
        dispatch(setLoading(false));
        setData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    scrollToTop();
  }, [id, cookies.cid]);

  useEffect(() => {
    sessionStorage.setItem("companyTab", "Overview");
  }, [id]);

  return (
    <div id="Company-Container">
      {loadingDataValue ? (
        <Shimmer />
      ) : (
        <div className="parent">
          {/* Child 1 */}
          <div className="company-info-container">
            <ProfileCard
              coverPhoto={data?.company_overview?.cover_photo || Default}
              logo={data?.company_overview?.profile_photo || Default1}
            />

            <div className="detail-container mt-2">
              <Heading
                type="smaller"
                title={data?.company_overview?.company_name}
                classes="mt-2 mb-2"
              />
              <div className="d-flex align-items-center gap-3 mt-2 company-location-header">
                {data?.address?.length > 0 && (
                  <p className="detail-address mb-0">
                    <>
                      {data?.address[0]?.state_region},{" "}
                      {data?.address[0]?.country}
                    </>
                  </p>
                )}

                <TypeDiv data={data?.company_overview?.role} color="#B5E4CA" />
              </div>

              {id && id !== cookies.cid && userInfo.role === roles.manager ? (
                <></>
              ) : (
                <div className="d-flex gap-3 mt-3 mb-3">
                  {(!id || id === cookies.cid) && userInfo?.is_admin && (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        // isSubscriptionExpired
                        //   ? handleUserClick(e)
                        //   :
                        navigate("/dashboard/edit-company");
                      }}
                    >
                      Edit Company
                    </button>
                  )}
                  {id && id !== cookies.cid && (
                    <RequestInfoForm
                      type="Request"
                      mapType="Company"
                      mapId={cookies.cid}
                    />
                  )}
                  {id && id !== cookies.cid && (
                    <SaveComponent
                      type="company"
                      id={data?.company_overview?.company_id}
                      saved_id={data?.company_overview?.saved_list}
                      key={data?.company_overview?.saved_list}
                    />
                  )}

                  {!id ||
                    (id !== cookies.cid && (
                      <CircularDropdown
                        icon={<DotsIcon />}
                        border="1px solid #1A1D1F"
                        size="50px"
                        bgColor="#F4F4F4"
                        direction="right"
                      >
                        <div
                          className="d-flex gap-3 flex-column"
                          style={{ lineHeight: "normal", cursor: "pointer" }}
                        >
                          {(id === cookies.cid || !id) && (
                            <span
                              className="p-dark"
                              onClick={() =>
                                navigate("/dashboard/edit-company")
                              }
                            >
                              Edit Company
                            </span>
                          )}

                          {id && id !== cookies.cid && (
                            <span
                              className="error-text"
                              onClick={() => setReportShow(true)}
                            >
                              Report
                            </span>
                          )}
                        </div>
                      </CircularDropdown>
                    ))}
                </div>
              )}

              <Data data={data} />

              {reportShow && (
                <ReportForm
                  type="Report"
                  mapType="Company"
                  isReportActive={reportShow}
                  handleClose={() => setReportShow(false)}
                  mapId={id || cookies.cid}
                />
              )}
            </div>
          </div>

          {/* {((id !== cookies.cid || !id)  )  && (
            <div className="d-flex gap-3 mt-3 mb-3">
              {(!id || id === cookies.cid) && userInfo?.is_admin && (
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/dashboard/edit-company")}
                >
                  Edit Company
                </button>
              )}
              {id && id !== cookies.cid && (
                <RequestInfoForm
                  type="Request"
                  mapType="Company"
                  mapId={cookies.cid}
                />
              )}
              {id && id !== cookies.cid && (
                <SaveComponent
                  type="company"
                  id={data?.company_overview?.company_id}
                  saved_id={data?.company_overview?.saved_list}
                  key={data?.company_overview?.saved_list}
                />
              )}

              {!id ||
                (id !== cookies.cid && (
                  <CircularDropdown
                    icon="fi-rr-menu-dots"
                    border="1px solid #1A1D1F"
                    size="50px"
                    bgColor="#F4F4F4"
                    direction="right"
                  >
                    <div
                      className="d-flex gap-3 flex-column"
                      style={{ lineHeight: "normal", cursor: "pointer" }}
                    >
                      {(id === cookies.cid || !id) && (
                        <span
                          className="p-dark"
                          onClick={() => navigate("/dashboard/edit-company")}
                        >
                          Edit Company
                        </span>
                      )}

                      {id && id !== cookies.cid && (
                        <span
                          className="error-text"
                          onClick={() => setReportShow(true)}
                        >
                          Report
                        </span>
                      )}
                    </div>
                  </CircularDropdown>
                ))}
            </div>
          )} */}

          {/* child 2 */}

          <div className="additional">
            <CompanyBlogs />

            {data?.address?.length > 0 && (
              <>
                <h4 className="h4-j">Office Location</h4>
                <Address data={data} />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Company;
