const renderSection = (title: string, content: string) => (
  <>
    <div className="h3-j semi pro-info-heading">{title}</div>
    <div className="quill quill-change">
      <div
        className="ql-container ql-snow ql-no-border"
        style={{ background: "white" }}
      >
        <div
          className="ql-editor blog-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  </>
);

const InvestmentProcess = ({ data }: any) => {
  return (
    <>
      <h1 className="h1-j mb-large">Investment Process</h1>

      {data?.idea_generation &&
        renderSection("Idea Generation", data?.idea_generation)}
      {data?.analysis && renderSection("Analysis", data?.analysis)}
      {data?.portfolio_management &&
        renderSection("Portfolio Management", data?.portfolio_management)}
      {data?.risk_management &&
        renderSection("Risk Management", data?.risk_management)}
      {data?.key_man_clause &&
        renderSection("Key Man Clause", data?.key_man_clause)}
      {data?.notes && renderSection("Notes", data?.notes)}
    </>
  );
};

export default InvestmentProcess;
