import { Box } from "@mui/material";
import { useState } from "react";
import TabStrip from "../../../molecules/TabStrip";

import DataRoom from "./components/Dataroom/DataRoom";
import Disclaimer from "./components/Disclaimer";
import ProductOverview from "./components/ProductOverview";
import Video from "./components/Video";

const Data = ({ data, company_id, product_id, type }: any) => {
  const [activeTab, setActiveTab] = useState("Product Overview");

  let dataArray = [
    {
      id: 0,
      label: "Product Overview",
      view: <ProductOverview data={data} type={type} />,
    },
  ];

  if (data?.video?.length > 0) {
    dataArray.push({
      id: 1,
      label: "Video",
      view: (
        <Video
          data={data?.video}
          visibility_type={type}
          prod_name={data?.product_configuration[0]?.name}
          company_id={data?.company_id}
        />
      ),
    });
  }

  if (data?.data_room?.length > 0) {
    dataArray.push({
      id: 2,
      label: "Data Room",
      view: <DataRoom company_id={company_id} product_id={product_id} />,
    });
  }

  if (data?.disclaimer) {
    dataArray.push({
      id: 2,
      label: "Disclaimer",
      view: <Disclaimer data={data} />,
    });
  }

  const _getComponent = (tabs: any) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event: any, value: any) => {
    setActiveTab(value);
  };

  return (
    <>
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
      />
      <div className="main-box-product">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ pt: 2 }}>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </>
  );
};

export default Data;
