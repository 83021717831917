// src/redux/dataSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../services";

// Generic async thunk for fetching data
export const fetchGroupData = createAsyncThunk(
  "data/fetchData",
  async ({ type, userId }) => {
    const response = await getData({
      endpoint: `SavedList/getGroupList`,
      params: {
        user_id: userId,
        type: type,
      },
    });
    return { type, data: response };
  }
);

const savedSlice = createSlice({
  name: "savedGroups",
  initialState: {
    company: null,
    people: null,
    product: null,
    loading: false,
    error: null,
  },
  reducers: {
    removeItem: (state, action) => {
      const { type, item } = action.payload;
      if (state[type]) {
        state[type] = state[type].filter((i) => i !== item);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroupData.fulfilled, (state, action) => {
        state.loading = false;
        // Update the state based on the type in action.payload
        state[action.payload.type] = action.payload.data;
      })
      .addCase(fetchGroupData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { removeItem } = savedSlice.actions;

export default savedSlice.reducer;
