const Thought = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
    >
      <path d="M3,22.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Zm3-5.5c-1.105,0-2,.895-2,2s.895,2,2,2,2-.895,2-2-.895-2-2-2Zm18-7c0,3.309-2.691,6-6,6-.733,0-1.456-.141-2.155-.42-1.078,.921-2.419,1.42-3.845,1.42-2.166,0-4.137-1.158-5.198-3.003-3.217-.105-5.802-2.755-5.802-5.997C1,4.691,3.691,2,7,2c.553,0,1.096,.075,1.62,.223,1.228-1.403,3.001-2.223,4.88-2.223,2.728,0,5.146,1.71,6.084,4.228,2.593,.703,4.416,3.039,4.416,5.772Zm-2,0c0-1.96-1.41-3.614-3.354-3.932l-.639-.104-.167-.626c-.522-1.965-2.308-3.338-4.341-3.338-1.479,0-2.864,.736-3.707,1.97l-.464,.68-.757-.325c-.499-.215-1.028-.324-1.572-.324-2.206,0-4,1.794-4,4s1.794,4,4,4c.037,0,1.038-.094,1.038-.094l.282,.661c.63,1.478,2.074,2.433,3.68,2.433,1.108,0,2.143-.459,2.91-1.292l.525-.57,.684,.367c.611,.328,1.244,.495,1.881,.495,2.206,0,4-1.794,4-4Z" />
    </svg>
  );
};

export default Thought;
