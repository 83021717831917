const KeyLinks = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1804_28587)">
        <path
          d="M18.461 23.0226L14.1117 27.3719C12.8489 28.6009 11.153 29.2834 9.39094 29.2715C7.62885 29.2597 5.94227 28.5546 4.69608 27.3088C3.4499 26.063 2.74433 24.3766 2.73202 22.6145C2.71972 20.8524 3.40167 19.1564 4.63034 17.8933L8.97967 13.5399C9.22968 13.2897 9.37007 12.9505 9.36994 12.5968C9.36982 12.2431 9.22919 11.904 8.979 11.6539C8.72882 11.4039 8.38956 11.2635 8.03587 11.2637C7.68217 11.2638 7.34301 11.4044 7.093 11.6546L2.745 16.0079C0.988033 17.7658 0.00132828 20.1496 0.00195342 22.6349C0.00257856 25.1203 0.990483 27.5036 2.74834 29.2606C4.50619 31.0175 6.89 32.0042 9.37536 32.0036C11.8607 32.003 14.244 31.0151 16.001 29.2572L20.3503 24.9079C20.5932 24.6564 20.7276 24.3196 20.7246 23.97C20.7215 23.6205 20.5813 23.286 20.3341 23.0388C20.0869 22.7916 19.7525 22.6514 19.4029 22.6484C19.0533 22.6453 18.7165 22.7797 18.465 23.0226H18.461Z"
          fill="#1A1D1F"
        />
        <path
          d="M29.258 2.74803C28.3904 1.87445 27.358 1.18174 26.2207 0.710028C25.0835 0.238317 23.8639 -0.00300861 22.6327 3.28543e-05C21.4021 -0.00323612 20.1831 0.23747 19.0461 0.708235C17.9091 1.179 16.8767 1.87048 16.0087 2.7427L11.6527 7.09337C11.4025 7.34338 11.2618 7.68253 11.2617 8.03623C11.2616 8.38992 11.402 8.72918 11.652 8.97937C11.902 9.22955 12.2412 9.37018 12.5949 9.3703C12.9485 9.37043 13.2878 9.23004 13.538 8.98003L17.8913 4.6307C18.5121 4.00623 19.2505 3.5111 20.0639 3.17398C20.8773 2.83685 21.7495 2.66443 22.63 2.6667C23.9557 2.66714 25.2515 3.06061 26.3536 3.79737C27.4557 4.53412 28.3146 5.58109 28.8218 6.80593C29.3289 8.03076 29.4616 9.37846 29.2029 10.6787C28.9443 11.9789 28.306 13.1732 27.3687 14.1107L23.0193 18.46C22.7691 18.7102 22.6286 19.0495 22.6286 19.4034C22.6286 19.7572 22.7691 20.0965 23.0193 20.3467C23.2695 20.5969 23.6088 20.7374 23.9627 20.7374C24.3165 20.7374 24.6558 20.5969 24.906 20.3467L29.2553 16C31.01 14.2415 31.9957 11.8588 31.9962 9.37458C31.9967 6.89033 31.012 4.50728 29.258 2.74803Z"
          fill="#1A1D1F"
        />
        <path
          d="M19.0574 11.0579L11.0574 19.0579C10.9301 19.1809 10.8285 19.328 10.7586 19.4907C10.6888 19.6533 10.652 19.8283 10.6504 20.0053C10.6489 20.1824 10.6826 20.3579 10.7497 20.5218C10.8167 20.6857 10.9157 20.8345 11.0409 20.9597C11.1661 21.0849 11.315 21.1839 11.4788 21.251C11.6427 21.318 11.8183 21.3517 11.9953 21.3502C12.1723 21.3487 12.3473 21.3119 12.51 21.242C12.6727 21.1721 12.8198 21.0705 12.9428 20.9432L20.9428 12.9432C21.1857 12.6917 21.32 12.3549 21.317 12.0053C21.314 11.6557 21.1737 11.3213 20.9265 11.0741C20.6793 10.8269 20.3449 10.6867 19.9953 10.6836C19.6457 10.6806 19.3089 10.815 19.0574 11.0579Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_28587">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default KeyLinks;
