import { FC } from "react";
import CloseBtnIcom from "../CloseBtnIcon";
import "./DeeteModal.scss";
interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
  return (
    <div onClick={onClose}>
      <i className="d-flex" style={{ fontSize: "18px", cursor: "pointer" }}>
        <CloseBtnIcom />
      </i>
    </div>
  );
};

interface ModalProps {
  width?: string;
  onClose: () => void;
  isActive: boolean;
  title: string;
  name: string;
  onClick: () => void;
  id: string; // assuming id is a string, adjust as needed
  type: string;
  description: string;
}

const Modal: FC<ModalProps> = ({
  width = "400px",
  onClose,
  isActive,
  title,
  name,
  onClick,
  id,
  type = "Delete",
  description,
}) => {
  const styles = {
    width: width,
  };

  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body py-4">
          <CloseButton onClose={onClose} />
          <p className="modal-heading mb-0 mt-2">
            {type} {title}
          </p>
          {type === "Delete" ? (
            <p className="modal-description">
              Are you sure do you want delete{" "}
              <span style={{ fontWeight: 800, color: "#545C6E" }}>{name}</span>{" "}
              from the {title} list.
            </p>
          ) : (
            <p className="modal-description mt-2">{description}</p>
          )}
          <div className="d-flex w-100 mt-3">
            <button
              onClick={() => onClose()}
              style={{ flex: "1 0 45%" }}
              className="btn btn-outline-dark"
            >
              No{" "}
            </button>
            {/* Adjust the style for the "Confirm" button */}
            <button
              className="btn btn-primary mt-0"
              onClick={onClick}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
