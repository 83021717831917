import { useState } from "react";
import Crausel from "../../../atoms/Crausel";
import Heading from "../../../atoms/Heading";
import "./LatestAdded.scss";

const LatestAdded = ({ Heading: heading, children, dataLength = 0 }) => {
  const [index, setIndex] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className="Investor-Latest-Added-Container">
      <div
        className="mb-large w-100 d-flex align-items-center"
        style={{ justifyContent: "space-between" }}
      >
        <Heading title={heading} type="sub" classes="mb-0" />
        {dataLength > 4 && (
          <Crausel
            index={index}
            setIndex={setIndex}
            dataLength={dataLength}
            setIsClicked={setIsClicked}
          />
        )}
      </div>
      <div
        className="Latest-Added-Container"
        style={
          isClicked
            ? {
                transform: `translateX(${-index * 23}%)`,
              }
            : {}
        }
      >
        {children}
      </div>
    </div>
  );
};

export default LatestAdded;

export const AddLatest = ({
  ImageSrc,
  alt,
  Heading,
  Content,
  link,
  userInfo,
  type,
}) => {
  const handleAddLatest = () => {
    if (type === "product") {
      if (userInfo?.has_master_access || userInfo?.manager_database) {
        window.open(link, "_blank");
      } else {
        window.open(userInfo?.product_learn_more_link, "_blank");
      }
    } else {
      if (userInfo?.has_master_access || userInfo?.investor_database) {
        window.open(link, "_blank");
      } else {
        window.open(userInfo?.investor_learn_more_link, "_blank");
      }
    }
  };
  return (
    <div
      className="Latest-added-company-details-container"
      onClick={handleAddLatest}
    >
      <img className="Latest-added-company-logo" src={ImageSrc} alt={alt} />
      <div className="Latest-added-company-details">
        <span className="Latest-added-company-Heading">{Heading}</span>
        <span className="Latest-added-company-endowment">{Content}</span>
      </div>
    </div>
  );
};
