import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: `${baseURL}`, // Your base URL
  headers: {
    "Content-Type": "application/json",
    "User-Role": "Customer",
  },
});

// // Axios interceptor to track API calls for bot detection
// instance.interceptors.request.use(
//   function (config) {
//     if (botDetectionService.isPlatformFrozen()) {
//       console.error("API call blocked: Platform is frozen.");
//       return Promise.reject(
//         new Error("Platform is frozen due to bot detection.")
//       );
//     }
//     botDetectionService.recordUserInteraction(); // Record the API call before the request is sent
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export default instance;
