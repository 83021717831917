import "./styles.scss";
const ShimmerResources = ({ type = "" }) => {
  return (
    <div className="shimmerResource-container">
      <div className="shre-card-conatiner">
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
        <div className="shre-card-item">
          <div className="shre-card-img"></div>
          <div className="shre-card-heading"></div>
          <div className="shre-card-small-item"></div>
          <div className="shre-card-description"></div>
          <div className="shre-card-small-item"></div>
        </div>
      </div>
    </div>
  );
};
export default ShimmerResources;
