import styles from "./index.module.scss";

const Button = ({ type, className, onClick, label }) => {
  return (
    <div
      className={`${
        type === "small" ? styles.reset_small_btn : styles.reset_btn
      } ${className}`}
      onClick={onClick}
    >
      <label>{label}</label>
    </div>
  );
};

export default Button;
