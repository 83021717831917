import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDelete } from "../../../../../hooks/useDelete";
import { getData } from "../../../../../services";
import { setLoading } from "../../../../../store/slices/loaderSlice";
import Add from "../../../../Icons/Add";
import EditBlue from "../../../../Icons/EditBlue";
import Investement from "../../../../Icons/Investment";
import View from "../../../../Icons/View";
import CustomButton from "../../../../atoms/CustomeButton";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../molecules/NoContentCard";
import Pagination from "../../../../molecules/Pagination";

const InvestmentPreference = ({ type }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t", "cid"]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial items per page
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState();

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const fetchInvestmentData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/getAllPeopleInvestmentPrefrence",
      params: { people_id: id },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setInvestmentData(res);
    }
  };

  useEffect(() => {
    fetchInvestmentData();
  }, []);

  const deletePreference = useDelete();

  const handleView = (data) => {
    navigate(`/dashboard/people/edit/view-investment-preference/${data.id}`);
  };

  //   const handleDelete = (data) => {
  //     setModalState((prevState) => ({
  //       ...prevState,
  //       isDeleteModalOpen: true,
  //       modalId: data.id,
  //       name: data?.product_structure?.name || "",
  //     }));
  //   };

  //   const handleDeletion = async () => {
  //     try {
  //       await deletePreference(
  //         "Company/deleteCompanyInvestmentPrefrence",
  //         "Investment Preference",
  //         {
  //           prefrence_id: modalState.modalId,
  //           user_role: "Customer",
  //         }
  //       );
  //       fetchInvestmentData();
  //       setModalState((prevState) => ({
  //         ...prevState,
  //         isDeleteModalOpen: false,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const handleEdit = (data) => {
    navigate(`/dashboard/people/edit/edit-investment-preference/${data.id}`);
  };

  const preferenceColumns = [
    {
      field: "productDetails",
      headerName: "Product Type",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span
              style={{
                color: "#1A1D1F",
                fontWeight: "500",
                whiteSpace: "pre-wrap",
              }}
            >
              {params?.row?.product_type?.[0]?.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "productStructure",
      headerName: "Product Structure",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span
              style={{
                color: "#1A1D1F",
                fontWeight: "500",
                whiteSpace: "pre-wrap",
              }}
              title={params.row.product_structure
                ?.map((structure) => structure.name)
                .join(", ")}
              className="line-truncate"
            >
              {params.row.product_structure
                ?.map((structure) => structure.name)
                .join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      field: "aumThreshhold",
      headerName: "AUM Threshold",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "#1A1D1F", fontWeight: "500" }}>
            {params.row?.min_aum && `$${params.row?.min_aum}`}
            {params.row?.min_aum && params.row?.max_aum && (
              <>&nbsp;-&nbsp;</>
            )}{" "}
            {params.row?.max_aum && ` $${params.row?.max_aum} `}
          </span>
        );
      },
    },
    {
      field: "lengthTrackRecord",
      headerName: "Track Length Record",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "#1A1D1F", fontWeight: "500" }}>
            {params.row?.min_track_record}{" "}
            {params.row?.min_track_record && params.row?.max_track_record && (
              <>&nbsp;-&nbsp;</>
            )}
            {params.row?.max_track_record}&nbsp;{" "}
            {(params.row?.max_track_record || params.row?.min_track_record) &&
              "years"}
          </span>
        );
      },
    },
    {
      field: "preLaunch",
      headerName: "Pre-Launch",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "#1A1D1F", fontWeight: "500" }}>
            {params.row.prelaunch ? "Yes" : "No"}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton type="button" onClick={() => handleView(params.row)}>
              <i className="">
                <View />
              </i>
            </IconButton>

            <IconButton type="button" onClick={() => handleEdit(params.row)}>
              <i className="">
                <EditBlue className="edit-blue-icon" />
              </i>
            </IconButton>

            {/* <IconButton type="button" onClick={() => handleDelete(params.row)}>
              <i className="fi fi-rr-trash actions-icon-custom"></i>
            </IconButton> */}
          </>
        );
      },
    },
  ];

  // PAgination MaNAGEMENT
  const totalItems = investmentData?.total_records;

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      {investmentData?.data?.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bolder">Investment Preferences</h4>
            {type !== "view" && (
              <CustomButton
                text="Add Investment Preference"
                icon={<Add />}
                handleClick={() => {
                  navigate(
                    `/dashboard/people/edit/add-investment-preference/${id}`
                  );
                }}
                type="btn-primary"
              />
            )}
          </div>

          <CustomDatagrid
            rows={investmentData?.data || []}
            getRowId={(row) => row.id}
            columns={preferenceColumns}
            height={() => 80}
            tHeight="300px"
            checkRequired={false}
          />

          {totalItems > 10 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={false}
          title="No Preferences Added Yet"
          icon={<Investement />}
        />
      ) : (
        <NoContentCard
          small={false}
          title="No Preferences Added Yet"
          subtitle="To add Investor Preferences, tap the add button."
          icon={<Investement />}
          handleClick={() => {
            navigate(`/dashboard/people/edit/add-investment-preference/${id}`);
          }}
          //   color='white'
          tag="Add Investment Preferences"
        />
      )}

      {/* <DeleteModal
        name={modalState.name}
        id={modalState.modalId}
        isActive={modalState.isDeleteModalOpen}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: false,
          }))
        }
        title="Investment Preference"
        onClick={handleDeletion}
      /> */}
    </>
  );
};

export default InvestmentPreference;
