import React from "react";
import CircleAvatar from "../CircleAvatar";
import NoContent from "../../../assets/images/NoContent.png";
import "./people_profile_card.scss";

interface PeopleProfileCardProps {
  profile?: string;
  name?: string;
  size?: string;
  type?: string;
  borderRadius?: string
}

const PeopleProfileCard: React.FC<PeopleProfileCardProps> = ({
  profile,
  name,
  size = "120px",
  borderRadius = '25%',
  type,
}) => {
  return (
    <>
      {profile ? (
        <img
          src={profile}
          alt="Profile"
          className="profile-image"
          style={{
            width: size,
            height: size,
            objectFit: "cover",
            borderRadius:borderRadius,
            border: "2px solid #fff",
          }}
        />
      ) : (
        <>
          {type === "company" ? (
            <img
              src={NoContent}
              alt="Profile"
              style={{
                width: size,
                height: size,
                objectFit: "cover",
                borderRadius: borderRadius,
                border: "2px solid #f4f4f4",
              }}
            />
          ) : (
            <CircleAvatar
              borderRadius="10%"
              name={name}
              size={size}
              fontSize="25px"
              color="#F4F4F4"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            />
          )}
        </>
      )}
    </>
  );
};

export default PeopleProfileCard;
