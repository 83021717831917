import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TabStrip from "../../../../molecules/TabStrip";

import Education from "./Education";
import InvestmentPreferance from "./InvestmentPreferance";
import Keylink from "./keyLink/index";
import Socials from "./Socials/index";
import Work from "./Work";

const PeopleTab = ({ role }) => {
  const [cookies] = useCookies(["t"]);

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const storedTab = sessionStorage.getItem("peopleTab");
    const isManagerOrSponsor = role === "Manager/Sponsor";

    let newTab;
    if (storedTab) {
      newTab =
        isManagerOrSponsor && storedTab === "Investment Preference"
          ? "Keylinks"
          : storedTab;
    } else {
      newTab = isManagerOrSponsor ? "Keylinks" : "Investment Preference";
    }

    localStorage.setItem("peopleTab", newTab);
    setActiveTab(newTab);
  }, [role]);

  const onTabChange = (event, value) => {
    setActiveTab(value);
    sessionStorage.setItem("peopleTab", value);
  };

  let dataArray = [
    {
      id: 1,
      label: "Investment Preference",
      view: <InvestmentPreferance token={cookies.t} />,
    },
    {
      id: 2,
      label: "Keylinks",
      view: <Keylink token={cookies.t} />,
    },
    {
      id: 3,
      label: "Work Details",
      view: <Work token={cookies.t} />,
    },
    {
      id: 5,
      label: "Education",
      view: <Education token={cookies.t} />,
    },
    {
      id: 4,
      label: "Social",
      view: <Socials token={cookies.t} />,
    },
  ];

  if (role === "Manager/Sponsor") {
    dataArray = dataArray.filter(
      (item) => item.label !== "Investment Preference"
    );
  }

  const _getComponet = (tabs) => {
    return dataArray
      .filter((item) => item.label == tabs)
      .map((item) => item.view)[0];
  };
  return (
    <div className="mt-4 pt-2">
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
        allowedTabId="0"
        margin="1rem"
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ pt: 3 }}>{_getComponet(activeTab)}</Box>
      </Box>
    </div>
  );
};

export default PeopleTab;
