import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import TrackIcon from "../../../../../../Icons/Track";
import CloseBtnIcom from "../../../../../../molecules/Modal/CloseBtnIcon";
import DeleteModal from "../../../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../../molecules/NoContentCard";
import ExcelParser from "../../../../../../organisms/ExcelParser";
import "../styles.scss";
import TrackAumForm from "./TrackAumForm";

const TrackAum = ({
  setFieldValue,
  values,
  initialValues,
  reuirement_type,
}) => {
  const { id: pid } = useParams();
  const [cookies] = useCookies(["t"]);

  const [data, setData] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [type, setType] = useState(false);
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [modalId, setModalId] = useState();

  const openModal = () => {
    // console.log("called");
    setIsOpen(true);
    setModalId(uuid());
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadNet = () => {
    const filePath =
      "https://primealpha-prod.s3.amazonaws.com/SampleFiles/8f2e0d70-001e-412e-ac7b-22a03af6c9e6_Net_Aum_File (1).xlsx";

    const downloadLink = document.createElement("a");
    downloadLink.href = filePath;
    downloadLink.download = "netTemplate.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownloadMonthly = () => {
    const filePath =
      "https://primealpha-prod.s3.amazonaws.com/SampleFiles/b0dfe1ba-2763-429d-b182-6916d2cc8469_monthlyAum.xlsx";

    const downloadLink = document.createElement("a");
    downloadLink.href = filePath;
    downloadLink.download = "monthlyTemplate.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDelete = (type, name) => {
    setIsDeleteModalOpen(true);
    setType(type);
    setName(name);
  };

  const handleDeletion = async () => {
    if (type === "all") {
      setFieldValue("add_net_aum", null);
      setFieldValue("add_monthly_aum", null);
      setFieldValue("net_aum_file", null);
      setFieldValue("monthly_aum_file", null);
    } else if (type === "net") {
      setFieldValue("add_net_aum", null);
      setFieldValue("net_aum_file", null);
    } else if (type === "monthly") {
      setFieldValue("monthly_aum_file", null);
      setFieldValue("add_monthly_aum", null);
    }

    setIsDeleteModalOpen(false);
  };

  return (
    <>
      {values?.add_net_aum?.length > 0 ||
      values?.add_monthly_aum?.length ||
      values?.net_aum?.length > 0 ||
      values?.monthly_aum?.length > 0 ? (
        <>
          {reuirement_type !== "view" && (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="h2-j">Track Record/AUM </h4>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-outline-dark mt-0"
                    onClick={handleDownload}
                  >
                    Download Sample
                  </button>
                  <button
                    className="btn btn-primary ms-3 mt-0"
                    onClick={openModal}
                  >
                    Upload Track Record
                  </button>
                  <div
                    className="btn btn-outline-danger ms-3 mt-0"
                    onClick={() =>
                      handleDelete("all", "Net Returns and Monthly AUM")
                    }
                  >
                    Remove Track Record
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mb-2 gap-3">
                {values?.add_net_aum?.length > 0 && values?.net_aum_file && (
                  <>
                    <span className="btn btn-outline-dark d-flex align-items-center ">
                      <span
                        className="remove-aum-data"
                        title={values?.net_aum_file}
                      >
                        {values?.net_aum_file}
                      </span>

                      <span
                        onClick={() => handleDelete("net", "Net Returns")}
                        style={{ marginTop: "2px" }}
                      >
                        <i className="ms-3 d-flex" style={{ fontSize: "14px" }}>
                          <CloseBtnIcom />
                        </i>
                      </span>
                    </span>
                  </>
                )}

                {values?.add_monthly_aum?.length > 0 &&
                  values?.monthly_aum_file && (
                    <>
                      <span className="btn btn-outline-dark d-flex align-items-center">
                        <span
                          className="remove-aum-data"
                          title={values?.monthly_aum_file}
                        >
                          {values?.monthly_aum_file}
                        </span>
                        <span
                          onClick={() => handleDelete("monthly", "Monthly AUM")}
                          style={{ marginTop: "2px" }}
                        >
                          <i
                            className="ms-3 d-flex"
                            style={{ fontSize: "14px" }}
                          >
                            <CloseBtnIcom />
                          </i>
                        </span>
                      </span>
                    </>
                  )}
              </div>
            </>
          )}

          {(values?.add_net_aum?.length > 0 || values?.net_aum?.length > 0) && (
            <>
              <div className="aum-header">
                <h5 className="h3-j mb-small mt-4">Net Returns(%)</h5>
                <div>
                  <div className="add-page-switch">
                    <label htmlFor="" className="mb-0">
                      Show Net Returns
                    </label>
                    <div className="form-switch">
                      {values?.show_net_aum ? "Yes" : "No"}
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        name="show_net_num"
                        checked={values?.show_net_aum}
                        onChange={() => {
                          setFieldValue("show_net_aum", !values.show_net_aum);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <ExcelParser
                fileData={values?.net_aum || values?.add_net_aum}
                req_type="net"
                className="product-edit-parcel"
                calledFrom="edit"
              />
            </>
          )}

          {(values?.add_monthly_aum?.length > 0 ||
            values?.monthly_aum?.length > 0) && (
            <>
              <div className="aum-header">
                <h5 className="h3-j mb-small mt-2">Monthly AUM ($MM)</h5>
                <div>
                  <div className="add-page-switch">
                    <label htmlFor="" className="mb-0">
                      Show Monthly AUM
                    </label>
                    <div className="form-switch">
                      {values.show_monthly_aum ? "Yes" : "No"}
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        name="show_monthly_aum"
                        checked={values.show_monthly_aum}
                        onChange={() => {
                          setFieldValue(
                            "show_monthly_aum",
                            !values.show_monthly_aum
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ExcelParser
                fileData={values?.monthly_aum || values?.add_monthly_aum}
                req_type="monthly"
                className="product-edit-parcel"
                calledFrom="edit"
              />
            </>
          )}
        </>
      ) : (
        <>
          {reuirement_type === "view" ? (
            <NoContentCard
              small={true}
              title="No track record/AUM"
              icon={<TrackIcon />}
            />
          ) : (
            <NoContentCard
              small={true}
              title="No track record/AUM"
              subtitle="If you want to upload Track Record/AUM. You just Download the template and upload it"
              tag="Upload Track Record"
              icon={<TrackIcon />}
            >
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="btn btn-outline-dark"
                  onClick={handleDownload}
                >
                  Download Sample Template
                </button>
                <button className="btn btn-primary ms-3" onClick={openModal}>
                  Upload Track Record
                </button>
              </div>
            </NoContentCard>
          )}
        </>
      )}

      <Modal
        isActive={isOpen}
        onClose={closeModal}
        title="Track AUM"
        id={modalId}
      >
        <TrackAumForm
          onClose={closeModal}
          id={modalId}
          setFieldValue={setFieldValue}
        />
      </Modal>

      <DeleteModal
        // name={modalState.name}
        id={modalId}
        isActive={isDeleteModalOpen}
        name={name}
        onClose={() => setIsDeleteModalOpen(false)}
        title="AUM Record"
        onClick={handleDeletion}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: "200px",
            borderRadius: "16px",
            marginTop: "10px", // Add margin-top to create space
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            fontWeight: "500px !important",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleDownloadNet();
          }}
        >
          Net Returns Sample
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleDownloadMonthly();
          }}
        >
          Monthly AUM Sample
        </MenuItem>
      </Menu>
    </>
  );
};

export default TrackAum;
