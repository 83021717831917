import BlogCard from "../../atoms/BlogCard";
import BlogCardWithEdit from "../../atoms/BlogCard/BlogCardWithEdit";
import NoContentCard from "../../molecules/NoContentCard";
import "./blog_template.scss";

const BlogTemplate = ({ data, type, isSearched, blogReq }: any) => {
  return (
    <div>
      {data?.length > 0 ? (
        <div className="blog-container">
          {data?.map((item: any) => (
            <>
              {blogReq === "edit" ? (
                <BlogCardWithEdit key={item.id} data={item} type={type} />
              ) : (
                <>
                  <BlogCard key={item.id} data={item} type={type} />
                </>
              )}
            </>
          ))}
        </div>
      ) : (
        <div className="no-content">
          {isSearched ? (
            <NoContentCard title="Sorry! No Result Found" type="Search" />
          ) : (
            <NoContentCard title="No Data Found !" type="" />
          )}
        </div>
      )}
    </div>
  );
};

export default BlogTemplate;
