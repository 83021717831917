import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import Building from "../../../../Icons/Building";
import CertificateIcon from "../../../../Icons/Certificate";
import RightUpArrow from "../../../../Icons/RightUpArrow";
import WorkIcon from "../../../../Icons/Work";
import IconCircle from "../../../../atoms/IconCircle";
import ImageIcon from "../../../../atoms/ImageIcon";
import InputLayout from "../../../../atoms/InputLayout";
import "../people.scss";

const About = ({ data }: any) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const screenWidth = useScreenWidth();
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const normalData =
    data?.people_overview?.about === "<p><br></p>" ||
    !data?.people_overview?.about
      ? "__"
      : data?.people_overview?.about;

  const originalText = normalData;
  const displayText = expanded
    ? originalText
    : screenWidth <= 480
    ? originalText?.substring(0, 400)
    : originalText?.substring(0, 700);

  const shouldShowViewMore =
    screenWidth <= 480
      ? originalText?.length > 400
      : originalText?.length > 700;

  const works = data?.work_experience;
  const socialChannels = data?.people_overview?.social_channel;
  const certificates = data?.certificate;
  const education = data?.education;

  return (
    <div className="people-overview-container">
      {/* // About Person */}

      {originalText !== "__" && (
        <div>
          <h3 className="h4-j mb-small">About Me</h3>
          {/* <p className="p">{displayText}</p> */}
          <div className="quill quill-change">
            <div
              className="ql-container ql-snow ql-no-border"
              style={{ background: "white" }}
            >
              <div
                className="ql-editor blog-content"
                style={{ padding: "0px" }}
                dangerouslySetInnerHTML={{ __html: displayText }}
              ></div>
            </div>
          </div>
          {shouldShowViewMore && (
            <span onClick={toggleExpand} className="view-more">
              {expanded ? "View less" : "View more"}
            </span>
          )}
        </div>
      )}

      {/* Profile Information  */}
      <div className={originalText !== "__" ? "mt-large" : ""}>
        <h4 className="h4-j mb-small">Profile Information</h4>

        <div className="profile-info-wrapper">
          <InputLayout>
            <label htmlFor="">Job Title</label>
            <p className="p">{data?.people_overview?.job_title || "--"}</p>
          </InputLayout>
          <InputLayout>
            <label htmlFor="">Company Name</label>
            <p
              className="p companyNameArrow"
              onClick={() => {
                navigate(`/dashboard/company/${data?.company_id}`);
              }}
            >
              {data?.people_overview?.company_name || "--"}
              <div className="rightUpArrow">
                <RightUpArrow />
              </div>
            </p>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Team</label>
            <p className="p">
              {data?.people_overview?.team_type
                ? [data?.people_overview?.team_type].join(", ")
                : "--"}
            </p>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Company Type</label>
            <p className="p">{data?.people_overview?.company_type || "--"}</p>
          </InputLayout>

          <InputLayout>
            <label htmlFor="">Email</label>
            <p className="p">{data?.people_overview?.email || "--"}</p>
          </InputLayout>

          {data?.people_overview?.investor_type?.length > 0 && (
            <InputLayout>
              <label htmlFor="">Investor Type</label>
              <p className="p">
                {data?.people_overview?.investor_type?.length
                  ? data?.people_overview?.investor_type?.map(
                      (item: any, index: number) => item + " "
                    )
                  : "--"}
              </p>
            </InputLayout>
          )}

          {data?.people_overview?.country_code &&
            data?.people_overview?.phone && (
              <div className="col-6">
                <InputLayout>
                  <label htmlFor="">Phone Number 1</label>
                  <p className="p">
                    {data?.people_overview?.country_code}{" "}
                    {data?.people_overview?.phone}{" "}
                    {data?.people_overview.ext_1 &&
                      `, Ext. ${data?.people_overview.ext_1}`}
                  </p>
                </InputLayout>
              </div>
            )}

          {data?.people_overview?.country_code_2 &&
            data?.people_overview?.phone_2 && (
              <div className="col-6">
                <InputLayout>
                  <label htmlFor="">Phone Number 2</label>
                  <p className="p">
                    {data?.people_overview?.country_code_2}{" "}
                    {data?.people_overview?.phone_2}{" "}
                    {data?.people_overview.ext_2 &&
                      `, Ext. ${data?.people_overview.ext_2}`}
                  </p>
                </InputLayout>
              </div>
            )}

          {data?.people_overview?.country_code_3 &&
            data?.people_overview?.phone_3 && (
              <div className="col-6">
                <InputLayout>
                  <label htmlFor="">Phone Number 3</label>
                  <p className="p">
                    {data?.people_overview?.country_code_3}{" "}
                    {data?.people_overview?.phone_3}{" "}
                  </p>
                </InputLayout>
              </div>
            )}
          {data?.people_overview?.city && (
            <div className="col-6">
              <InputLayout>
                <label htmlFor="">City</label>
                <p className="p">{data?.people_overview?.city || "--"}</p>
              </InputLayout>
            </div>
          )}

          {data?.people_overview?.state && (
            <InputLayout>
              <label htmlFor="">State</label>
              <p className="p">{data?.people_overview?.state}</p>
            </InputLayout>
          )}
          {data?.people_overview?.country && (
            <InputLayout>
              <label htmlFor="">Country</label>
              <p className="p">{data?.people_overview?.country}</p>
            </InputLayout>
          )}

          <InputLayout>
            <label htmlFor="">Last Updated Date</label>
            <p className="p">
              {moment
                .utc(data?.people_overview?.last_updated)
                .format("MMM D, YYYY") || "--"}
            </p>
          </InputLayout>

          {/* {data?.people_overview?.tag && (
            <InputLayout>
              <label htmlFor="">Tag</label>
              <p className="p">{data?.people_overview?.tag}</p>
            </InputLayout>
          )} */}
        </div>
      </div>

      {works?.length > 0 && (
        <>
          <h4 className="h4-j mb-small mt-large">Work Experience</h4>

          {works.map((work: any, index: number) => {
            return (
              <div key={index} className="d-flex gap-2 mt-4">
                <IconCircle
                  icon={
                    <i className="d-flex">
                      <Building />
                    </i>
                  }
                  iconColor="#4E5660"
                  size={60}
                  color="#efefef"
                  borderRadius="50%"
                  iconSize="1.6em"
                />
                <div>
                  <h4 className="h4-i mb-1" style={{ fontWeight: 600 }}>
                    {work?.company_name}
                  </h4>
                  <div className="p mb-1 d-flex align-items-center">
                    {work?.job_title}{" "}
                    {(work?.start_date || work?.end_date) && (
                      <>
                        {" "}
                        <div className="dot"></div>{" "}
                      </>
                    )}
                    {work?.start_date &&
                      moment.utc(work?.start_date).format("MMM YYYY")}{" "}
                    {work?.start_date &&
                      (work?.end_date || work?.currently_working_here) && (
                        <>- </>
                      )}
                    {work?.currently_working_here
                      ? "Present"
                      : work?.end_date &&
                        moment.utc(work?.end_date).format("MMM YYYY")}
                  </div>
                  {work?.email && (
                    <div className="p">Email: {work?.email || "--"}</div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}

      {education?.length > 0 && (
        <>
          <h4 className="h4-j mb-small mt-large">Education</h4>

          {education.map((education: any, index: number) => {
            return (
              <div key={index} className="d-flex gap-2 mt-4">
                <IconCircle
                  icon={
                    <i className="d-flex">
                      <WorkIcon width="20" />
                    </i>
                  }
                  iconColor="#4E5660"
                  size={60}
                  color="#efefef"
                  borderRadius="50%"
                  iconSize="1.6em"
                />
                <div>
                  <h4 className="h4-i mb-1" style={{ fontWeight: 600 }}>
                    {education?.degree}
                  </h4>
                  <div className="p mb-1 d-flex align-items-center">
                    {education?.field_of_study}{" "}
                    {education?.field_of_study && education?.institute_name && (
                      <div className="dot"></div>
                    )}{" "}
                    {education?.institute_name || "--"}
                  </div>
                  {/* <div className="p">Email: {education?.email || "--"}</div> */}
                  <div className="p mb-1 d-flex align-items-center">
                    {education?.start_date &&
                      moment.utc(education?.start_date).format("MMM YYYY")}{" "}
                    {education?.start_date && education?.end_date && "-"}{" "}
                    {education?.currently_working_here
                      ? "Present"
                      : education?.end_date &&
                        moment.utc(education?.end_date).format("MMM YYYY")}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      {certificates?.length > 0 && (
        <>
          <h4 className="h4-j mb-small mt-large">Certifications</h4>

          {certificates.map((certificate: any, index: number) => {
            return (
              <div key={index} className="d-flex gap-2 mt-4">
                <IconCircle
                  icon={
                    <i className="d-flex">
                      <CertificateIcon size="20" />
                    </i>
                  }
                  iconColor="#4E5660"
                  size={60}
                  color="#efefef"
                  borderRadius="50%"
                  iconSize="1.6em"
                />
                <div>
                  <h4 className="h4-i mb-1" style={{ fontWeight: 600 }}>
                    {certificate?.title}
                  </h4>
                  <div className="p mb-1 d-flex align-items-center">
                    {certificate?.about}
                  </div>
                  {/* <div className="p">Email: {education?.email || "--"}</div> */}
                  {/* <div className="p mb-1 d-flex align-items-center">
                    {education?.start_date &&
                      moment.utc(education?.start_date).format("MMM YYYY")}{" "}
                    {education?.start_date && education?.end_date && "-"}{" "}
                    {education?.currently_working_here
                      ? "Present"
                      : education?.end_date &&
                        moment.utc(education?.end_date).format("MMM YYYY")}
                  </div> */}
                </div>
              </div>
            );
          })}
        </>
      )}

      {/* Social Channels */}
      {socialChannels && socialChannels.length > 0 && (
        <div className="mt-large">
          <h4 className="h4-j mb-3">Social Channel</h4>
          <div className="social-channels">
            {socialChannels &&
              socialChannels.length > 0 &&
              socialChannels.map((social: any) => (
                <ImageIcon type={social?.channel_type} link={social?.link} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
