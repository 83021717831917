import { Box } from "@mui/material";
import React, { useState } from "react";
import TabStrip from "../../molecules/TabStrip";
import RenderFaq from "../../organisms/RenderFaq";

interface FaqTemplateProps {
  type?: string;
  color: string;
  searchedValue?: string;
}

const FaqTemplate: React.FC<FaqTemplateProps> = ({
  type,
  color,
  searchedValue,
}) => {
  const [activeTab, setActiveTab] = useState("General");
  const [data, setData] = useState({
    general: [],
    investor: [],
    manager: [],
  });

  const onTabChange = (event: any, value: any) => {
    setActiveTab(value);
  };

  const dataArray = [
    // ... existing dataArray ...

    // Modify the items to include the 'type' and 'color' props
    {
      id: 0,
      label: "General",
      view: (
        <RenderFaq
          // data={data && data.general ? data.general : []}
          color={color}
          type={type}
          faq_type="General"
          search_query={searchedValue}
        />
      ),
    },
    {
      id: 1,
      label: "Investor",
      view: (
        <RenderFaq
          // data={data && data.investor ? data.investor : []}
          color={color}
          type={type}
          faq_type="Investor"
          search_query={searchedValue}
        />
      ),
    },
    {
      id: 2,
      label: "Manager/Sponsors",
      view: (
        <RenderFaq
          // data={data && data.manager ? data.manager : []}
          color={color}
          type={type}
          faq_type="Manager/Sponsors"
          search_query={searchedValue}
        />
      ),
    },
  ];

  const _getComponent = (tabs: any) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  return (
    <>
      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
      />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ pt: 2 }}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default FaqTemplate;
