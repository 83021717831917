import "./styles.scss";
const ShimmerSaved = () => {
  const divCount = 10;
  return (
    <div className="shimmerSaved-container">
      <div className="shsd-cards-filter"></div>
      {Array.from({ length: 7 }, (_, index) => (
        <>
          <div className="shsd-cards-conatiner">
            <div className="shsd-card-header">
              <div className="shsd-card-img"></div>
              <div className="shsd-card-details">
                <div className="shsd-card-name"></div>
                <div className="shsd-card-loaction"></div>
                <div className="shsd-card-detals"></div>
              </div>
            </div>
            <div className="shsd-card-btn"></div>
          </div>
          <div className="shsd-border-line"></div>
        </>
      ))}
    </div>
  );
};
export default ShimmerSaved;
