import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { getData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";
import "./edit.scss";
const View = () => {
  const [profileData, setProfileData] = useState([]);
  const [isViewMore, setIsViewMore] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["t", "cuid"]);

  const fetchProfileInfo = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/customer/getUserProfileDetails",
      params: { id: cookies.cuid },
      token: cookies.t,
    });

    if (res) {
      dispatch(setLoading(false));
      setProfileData(res);
    }
  };
  useEffect(() => {
    fetchProfileInfo();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const elementHeight = contentRef.current.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3; // Height for 3 lines

      // If the content height is more than 3 lines, show the View More/Less toggle
      if (elementHeight > maxHeight) {
        setShouldShowToggle(true);
      } else {
        setShouldShowToggle(false); // Hide if content is less than or equal to 3 lines
      }
    }
  }, [profileData?.about]);

  return (
    <>
      <div className="h1-i"> My Profile </div>

      <div className="edit-form-div" id="MyProfile">
        <ImageSelector notShow selectedImage={profileData.profile_photo} />
        <div className="row mb-3 mt-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Name</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {profileData?.first_name} {profileData?.last_name}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Email</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">{profileData?.email}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 gy-3">
          {profileData?.country_code && profileData?.mobile && (
            <div className="col-6">
              <div className="row">
                <div className="col-md">
                  <div className="view-people-label">Phone Number 1</div>
                </div>
                <div className="col-md">
                  <div className="view-people-info">
                    <>
                      {profileData.country_code + " " + profileData.mobile}
                      {profileData.ext_1 && `, Ext. ${profileData.ext_1}`}
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}

          {profileData?.country_code_2 && profileData?.phone_2 && (
            <div className="col-6">
              <div className="row">
                <div className="col-md">
                  <div className="view-people-label">Phone Number 2</div>
                </div>
                <div className="col-md">
                  <div className="view-people-info">
                    {profileData?.country_code_2 && profileData?.phone_2 ? (
                      <>
                        {profileData.country_code_2 + " " + profileData.phone_2}
                        {profileData.ext_2 && `, Ext. ${profileData.ext_2}`}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {profileData?.country_code_3 && profileData?.phone_3 && (
            <div className="col-6">
              <div className="row">
                <div className="col-md">
                  <div className="view-people-label">Phone Number 3</div>
                </div>
                <div className="col-md">
                  <div className="view-people-info">
                    {profileData?.country_code_3 && profileData?.phone_3 ? (
                      <>
                        {profileData.country_code_3 + " " + profileData.phone_3}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-6">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Experience (In Years)</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {profileData?.experience}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
            <div className="view-people-label">About</div>
          </div>
          <div className="col-9">
            {/* <div className="view-people-info">{htmlToText(peopleData?.about) || "--"}</div> */}
            {profileData?.about !== "<p><br></p>" ? (
              <>
                <div className="quill quill-change">
                  <div
                    className="ql-container ql-snow ql-no-border"
                    style={{ background: "white" }}
                  >
                    <div
                      className={`ql-editor blog-content p-0  ${
                        isViewMore ? "" : "line-truncate"
                      }`}
                      ref={contentRef}
                      dangerouslySetInnerHTML={{
                        __html: profileData?.about,
                      }}
                    ></div>
                  </div>
                </div>

                {shouldShowToggle && (
                  <div
                    className="view-more mt-1"
                    onClick={() => setIsViewMore((prev) => !prev)}
                  >
                    {isViewMore ? "View Less" : "View More"}
                  </div>
                )}
              </>
            ) : (
              <div>--</div>
            )}
          </div>
        </div>

        {/* Company Details */}

        {profileData?.work_details?.length > 0 && (
          <div className="companies">
            <div className="company-header">
              <div className="h2-i">Companies Details</div>
            </div>

            {profileData?.work_details?.map((company, index) => (
              <>
                <div key={index}>
                  <div className="company-detail">
                    <p className="p semi-light">COMPANY {index + 1}</p>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className={`view-people-label`}>Company</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.company_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className={`view-people-label`}>Job Title</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">Email</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.email || "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className={`view-people-label`}>
                            Currently Worling Here
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.currently_working_here ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">Start Date</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.start_date
                              ? moment
                                  .utc(company?.start_date)
                                  .format("MMM YYYY")
                              : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className={`view-people-label`}>End Date</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {company?.end_date
                              ? moment.utc(company?.end_date).format("MMM YYYY")
                              : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {company?.work_description && (
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="view-people-label">Description</div>
                      </div>
                      <div className="col-9">
                        <div className="view-people-info">
                          {company?.work_description || "--"}
                        </div>
                      </div>
                    </div>
                  )}
                  <hr style={{ color: "#ced5de" }} className="mt-4" />
                </div>
              </>
            ))}
          </div>
        )}

        {/* Company Details */}

        {profileData?.education?.length > 0 && (
          <div className="educations">
            <div className="education-header">
              <div className="h2-i">Education</div>
            </div>
            {profileData?.education?.map((edu, index) => (
              <>
                <div key={index}>
                  <div className="education-detail">
                    <p className="p semi-light">Education {index + 1}</p>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">
                            Institute Name
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {edu?.institute_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">Degree</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {edu?.degree || "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">
                            Feild of Study
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {edu?.field_of_study || "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md">
                          <div className="view-people-label">Period</div>
                        </div>
                        <div className="col-md">
                          <div className="view-people-info">
                            {edu?.start_date &&
                              moment.utc(edu?.start_date).format("MMM YYYY")}
                            {edu?.start_date && edu?.end_date && "-"}{" "}
                            {edu?.end_date &&
                              moment.utc(edu?.end_date).format("MMM YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {edu?.about && (
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="view-people-label">About</div>
                      </div>
                      <div className="col-9">
                        <div className="view-people-info">{edu?.about}</div>
                      </div>
                    </div>
                  )}
                </div>
                <hr style={{ color: "#ced5de" }} className="mt-4" />
              </>
            ))}
          </div>
        )}

        {profileData?.certificates?.length > 0 && (
          <div className="certificates">
            <div className="certification-header">
              <div className="h2-i">Certifications</div>
            </div>
            {profileData?.certificates?.map((cer, index) => (
              <>
                <div key={index}>
                  <div className="education-detail">
                    <p className="p semi-light">Certificate {index + 1}</p>
                  </div>
                  <div className="row mt-1">
                    <div className="col-3">
                      <div className="view-people-label">Title</div>
                    </div>
                    <div className="col-9">
                      <div className="view-people-info">{cer?.title}</div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-3">
                      <div className="view-people-label">About</div>
                    </div>
                    <div className="col-9">
                      <div className="view-people-info">{cer?.about}</div>
                    </div>
                  </div>
                </div>
                <hr style={{ color: "#ced5de" }} className="mt-4" />
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default View;
