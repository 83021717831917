import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Excel from "../../../../../../assets/images/docTypes/Excel.png";
import Others from "../../../../../../assets/images/docTypes/Others.png";
import Pdf from "../../../../../../assets/images/docTypes/Pdf.png";
import Podcast from "../../../../../../assets/images/docTypes/Podcast.png";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import EditBlue from "../../../../../Icons/EditBlue";
import Investement from "../../../../../Icons/Investment";
import ShareIcon from "../../../../../Icons/ShareIcon";
import ImageViewer from "../../../../../atoms/ImageViewer";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import DeleteModal from "../../../../../molecules/Modal/DeleteModal";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";
import AddDataForm from "./AddDataForm";
import "./dataroom.scss";
import ShareLink from "./shareLink";
import ShareLinkEmail from "./shareLinkEmail";

import { useSelector } from "react-redux";
import { htmlToTextWithSpaces } from "../../../../../../helpers/htmlToText";
import Add from "../../../../../Icons/Add";
import TypeDiv from "../../../../../atoms/TypeDiv";
import FilterComponent from "../../../../../molecules/filters/FilterComponent";

const DataRoom = ({ company_id }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["t"]);
  const [filters, setFilters] = useState({});
  const [generateURL, setGenerateURL] = useState();
  const [isCopy, setIsCopy] = useState(false);
  const [expiry, setExpiry] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const filterData = {
    Type: ["Document", "Video", "Podcast"],
  };

  const fetchData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getAllDataRoomData",
      token: cookies.t,
      params: {
        product_id: id,
        type: JSON.stringify(filters?.Type),
        page: currentPage,
        page_limit: itemsPerPage,
        // is_featured: null,
      },
    });
    dispatch(setLoading(false));
    setData(res);
  };

  useEffect(() => {
    fetchData();
  }, [filters, currentPage, itemsPerPage]);

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    FormData: "",
    isViewModalOpen: false,
  });

  const [modalStateEmail, setModalStateEmail] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    FormData: "",
    isViewModalOpen: false,
  });

  const openModal = (title, formData) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: title,
      modalId: modalId,
      FormData: formData,
      isModalOpen: true,
    }));
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    openModal("Edit Data", data);
  };

  const deleteData = useDelete();

  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.data_id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteData("Product/deleteDataRoomData", "Data", {
        data_id: modalState.modalId,
        user_role: "Customer",
      });
      // queryClient.invalidateQueries("dataRoom");
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleView = (data) => {
  //   const modalId = uuidv4();
  //   setModalState((prev) => ({
  //     ...prev,
  //     title: "View Data",
  //     modalId: modalId,
  //     FormData: data,
  //     isViewModalOpen: true,
  //   }));
  // };

  const handleShare = (data) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      FormData: data,
      isViewModalOpen: true,
    }));
  };

  const handleShareLinkEmail = () => {
    const modalId = uuidv4();
    setModalStateEmail((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      isViewModalOpen: true,
    }));
    setModalState((prev) => ({
      ...prev,
      isViewModalOpen: false,
    }));
  };

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphaurl")?.innerHTML;

    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  // handle filters
  const handleFilterChange = (values) => {
    // console.log("values", values);
    setFilters(values);
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const columns = [
    {
      field: "Data Details",
      headerName: "Name",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div
            className="d-flex align-items-center my-2"
            style={{ width: "100%" }}
          >
            {params.row.type === "Video" && (
              <ImageViewer type="Company" imageUrl={params.row.thumbnail} />
            )}
            {params.row.type === "Podcast" && (
              <ImageViewer type="Company" imageUrl={Podcast} />
            )}
            {params.row.sub_type === "Pdf" && (
              <ImageViewer type="Company" imageUrl={Pdf} />
            )}
            {params.row.sub_type === "Excel" && (
              <ImageViewer type="Company" imageUrl={Excel} />
            )}
            {params.row.sub_type === "Others" && (
              <ImageViewer type="Company" imageUrl={Others} />
            )}

            <div
              className="d-flex flex-column"
              style={{ flex: 1, overflow: "hidden" }}
            >
              <span
                style={{
                  color: "#1A1D1F",
                  fontWeight: 800,
                  fontSize: "14px",
                  whiteSpace: "nowrap", // Prevents the text from wrapping
                  overflow: "hidden", // Hides the overflowed content
                  textOverflow: "ellipsis", // Adds '...' to the overflowed content
                }}
              >
                {params.row.name}
              </span>

              {params?.row?.description && (
                <div
                  style={{
                    color: "#6F767E",
                    fontSize: "14px",
                    whiteSpace: "nowrap", // Prevents the description from wrapping
                    overflow: "hidden", // Hides the overflowed content
                    textOverflow: "ellipsis", // Adds '...' to the overflowed content
                  }}
                  className="description"
                >
                  {htmlToTextWithSpaces(params.row.description?.slice(0, 100))}
                  {params.row.description?.length > 100 ? "..." : ""}
                </div>
              )}
            </div>
          </div>
        );
      },
    },

    {
      field: "tag",
      headerName: "Tag",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className="d-flex align-items-center gap-1 my-2"
            style={{ flexWrap: "wrap" }}
          >
            {params.row.tag?.map((tag, index) => (
              <div className="tag-wrapper" key={index}>
                {tag}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Creation Date",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.last_update).format("MMM D, YYYY")}
          </span>
        );
      },
    },
    {
      field: "upload_date",
      headerName: "Updation Date",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <span>
            {params.row.upload_date &&
              moment.utc(params.row.upload_date).format("MMM D, YYYY")}
          </span>
        );
      },
    },
    {
      field: "is_featured",
      headerName: "Featured",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <TypeDiv
            color={params.row.is_featured ? "#B5E4CA" : "#FFB7B7"}
            data={params.row?.is_featured ? "Yes" : "No"}
          />
        );
      },
    },
    { field: "type", headerName: "Type", flex: 0.6 },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={() => handleEdit(params.row)}
              className="me-3"
              style={{ marginRight: "10px" }}
            >
              <EditBlue className="edit-blue-icon" />
            </span>

            {userInfo?.content_sharing && (
              <span
                type="button"
                onClick={() => handleShare(params.row)}
                style={{ marginRight: "10px" }}
                className="me-3"
              >
                <ShareIcon />
              </span>
            )}

            {/* <IconButton type="button" onClick={() => handleView(params.row)}>
              <i className="fi fi-rr-eye actions-icon-custom"></i>
            </IconButton> */}
            {/* <span type="button" onClick={() => handleDelete(params.row)}>
              <i className="fi fi-rr-trash actions-icon-custom ms-3"></i>
            </span> */}
          </>
        );
      },
    },
  ];

  const CustomNoRow = () => {
    return (
      <>
        {filters.Type === null ? (
          <NoContentCard
            small={true}
            title="No  Data  In  Data  Room"
            icon={<Investement />}
            tag="Add Data"
            handleClick={() => openModal("Add Data")}
            classes="btn-outline-dark"
          />
        ) : (
          <NoContentCard
            small={true}
            title="No  Data  In  Data  Room"
            icon={<Investement />}
            // tag="Add Data"
            // handleClick={() => openModal("Add Data")}
            classes="btn-outline-dark"
          />
        )}
      </>
    );
  };
  return (
    <>
      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          width="642px"
        >
          <AddDataForm
            onClose={closeModal}
            id={modalState.modalId}
            initialData={modalState.FormData}
            onAddorUpdate={() => fetchData()}
          />
        </Modal>
      )}

      {/* {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }))
          }
          title="View Data"
          width="642px"
        >
          <View data={modalState.FormData} id={modalState.modalId} />
        </Modal>
      )} */}

      {/* {data?.data?.length > 0 ? ( */}

      <>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className="fw-bold mb-0">Data Room</h4>

          <div className="d-flex gap-2 align-items-center justify-content-center">
            <FilterComponent
              filterData={filterData}
              onFilterChange={handleFilterChange}
            />
            <div className="align-items-center justify-content-center">
              <button
                className="btn btn-primary d-flex gap-2"
                onClick={() => openModal("Add Data")}
              >
                <Add /> Add Data
              </button>
            </div>
          </div>
        </div>
        <CustomDatagrid
          rows={data?.data || []}
          columns={columns}
          getRowId={(row) => row.data_id}
          height={() => "auto"}
          CustomNoRowsOverlay={CustomNoRow}
        />
        {data?.total_record >= 10 && (
          <div className="mt-2">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={data?.total_record}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </div>
        )}
      </>

      {/* // ) : ( */}
      {/* //   <NoContentCard */}
      {/* //     small={true}
      //     title="No  Data  In  Data  Room"
      //     icon={<Investement />}
      //     tag="Add Data"
      //     handleClick={() => openModal("Add Data")}
      //   />
      // )} */}

      <DeleteModal
        name={modalState.name}
        id={modalState.modalId}
        isActive={modalState.isDeleteModalOpen}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: false,
          }))
        }
        title="Data"
        onClick={handleDeletion}
      />
      {modalState.isViewModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          title={modalState.title}
          width="642px"
          onClose={() => {
            setModalState((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }));
            setGenerateURL(null);
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <ShareLink
            id={modalState.modalId}
            roomData={modalState.FormData}
            handleShareLinkEmail={handleShareLinkEmail}
            generateURL={generateURL}
            setGenerateURL={setGenerateURL}
            setExpiry={setExpiry}
            isCopy={isCopy}
            setIsCopy={setIsCopy}
            handleCopyUrl={handleCopyUrl}
            company_id={company_id}
          />
        </Modal>
      )}
      {modalStateEmail.isViewModalOpen && (
        <Modal
          id={modalStateEmail.modalId}
          isActive={modalStateEmail.isViewModalOpen}
          title={modalStateEmail.title}
          width="642px"
          onClose={() =>
            setModalStateEmail((prev) => ({
              ...prev,
              isViewModalOpen: false,
            }))
          }
        >
          <ShareLinkEmail
            generateURL={generateURL}
            isCopy={isCopy}
            handleCopyUrl={handleCopyUrl}
            setModalStateEmail={setModalStateEmail}
            setGenerateURL={setGenerateURL}
            onClose={() =>
              setModalStateEmail((prev) => ({
                ...prev,
                isViewModalOpen: false,
              }))
            }
            expiry={expiry}
          />
        </Modal>
      )}
    </>
  );
};

export default DataRoom;
