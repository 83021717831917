import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import FundStructureForm from "./FundStructureForm";
import InvestmentProcess from "./InvestmentProcess";
import StrategyComponent from "./StrategyComponent";
import ServiceProviderForm from "./serviceProviderForm";
// import {addorUpdateProductOverview, getProductOerview } from "../../../../services/addProductSetvice";
import { useCookies } from "react-cookie";
// import TrackAum from "./TrackAum";
// import { isUUID } from "../../../../helpers/checkUuid";
import { useDispatch } from "react-redux";
import { getData, patchData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import TrackAum from "./TrackAum";

const ProductOverview = ({ page_type, onTabChange }) => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [strategyData, setStrategyData] = useState([]);
  const [investmentData, setInvestmentData] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [allData, setAllData] = useState([]);

  const getProductOverviewData = useCallback(async (id) => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Product/getProductOverview",
      params: { product_id: id },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setStrategyData(res?.product_strategy?.[0]);
    setInvestmentData(res?.investment_process?.[0]);
    setProductDetail(res?.product_details?.[0]);
    setAllData(res);
  }, []);

  useEffect(() => {
    getProductOverviewData(id);
  }, [id, getProductOverviewData]);

  const initialValues = {
    product_id: id || "",
    add_startegy: {
      id: strategyData?.id || id,
      overview: strategyData?.overview || "",
      investment_universe: strategyData?.investment_universe || "",
      differentiators: strategyData?.diffrentaitors || "",
      risk_and_return_drivers: strategyData?.risk_and_return_drivers || "",
      capacity: strategyData?.capacity || "",
      notes: strategyData?.notes || "",
    },
    add_investement_process: {
      // id : investmentData?.id || id,
      idea_generation: investmentData?.idea_generation || "",
      analysis: investmentData?.analysis || "",
      portfolio_management: investmentData?.portfolio_management || "",
      risk_management: investmentData?.risk_management || "",
      key_man_clause: investmentData?.key_man_clause || "",
      notes: investmentData?.notes || "",
    },
    user_role: "Customer",
    add_details: {
      // id : productDetail?.detail_id,
      prime_broker: productDetail?.prime_broker || "",
      administrator: productDetail?.administrator || "",
      bank_custodian: productDetail?.bank_custodian || "",
      auditor: productDetail?.auditor || "",
      legal_counsel: productDetail?.legal_counsel || "",
      fund_type: productDetail?.open_close_ended || "",
      funding_status: productDetail?.fund_status || "",
      funding_goal: productDetail?.funding_goal || null,
      target_close_date: productDetail?.target_close_date || null,
      eligible_investor: productDetail?.eligible_investor || null,
      minimum_investment: productDetail?.minimum_investment || null,
      management_fee: productDetail?.management_fee || null,
      performance_fee: productDetail?.performance_fee || null,
      hurdle_rate: productDetail?.hurdle_rate || null,
      high_water_mark: productDetail?.high_water_mark,
      subscription_frequency: productDetail?.subscription_frequency || null,
      redemption_frequency: productDetail?.redemtion_frequency || null,
      redemption_notice_period: productDetail?.redemption_notice_period || null,
      redemption_penalty: productDetail?.redemption_penalty || null,
      lockup_period: productDetail?.lockup_period,
    },
    add_net_aum: allData?.net_aum || [],
    add_monthly_aum: allData?.monthly_aum || [],
    monthly_aum_file: allData?.monthly_aum_file || "",
    net_aum_file: allData?.net_aum_file || "",
    show_net_aum: allData?.show_net_aum || null,
    show_monthly_aum: allData?.show_monthly_aum || null,
  };

  const handleEdit = async (values) => {
    try {
      dispatch(setLoading(true));
      const res = await patchData({
        endpoint: "Product/addOrUpdateOverview",
        data: values,
        token: cookies.t,
      });
      if (res.status) {
        dispatch(setLoading(false));
        toast.success("Product Overview Updated Succesfully", {
          duration: 1000,
          style: confimationStyles,
        });
        onTabChange("Video");
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleEdit}
      key={id || 111}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
      }) => (
        <>
          <div className="accordion px-2" id="accordionExample">
            {/* Accordion Item #1 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Strategy
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body">
                  <StrategyComponent
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
              </div>
            </div>

            {/* Accordion Item #2 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Investment Process
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
              >
                <div className="accordion-body">
                  <InvestmentProcess
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
              </div>
            </div>

            {/* Accordion Item #3 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Product Details
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
              >
                <div className="accordion-body">
                  <h6>SERVICE PROVIDERS</h6>
                  <ServiceProviderForm
                    initialValues={initialValues}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <h6 className="mt-4">FUND STRUCTURE AND TERMS</h6>
                  <FundStructureForm
                    initialValues={initialValues}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>

            {/* Accordion Item #4 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Track Record/AUM
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
              >
                <div className="accordion-body">
                  <TrackAum values={values} setFieldValue={setFieldValue} />
                </div>
              </div>
            </div>
          </div>

          {page_type !== "edit" && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary mt-0"
                onClick={() => handleEdit(values)}
              >
                Continue
              </button>
            </div>
          )}

          {page_type === "edit" && (
            <div style={{ marginTop: "auto" }}>
              <footer className="footer">
                <button
                  className="btn btn-outline-dark"
                  onClick={() => navigate(-1)}
                  type="button"
                >
                  Back
                </button>

                <button
                  type="button"
                  className="btn btn-primary mt-0"
                  onClick={() => handleEdit(values)}
                >
                  Update
                </button>
              </footer>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

export default ProductOverview;
