import { useEffect, useState } from "react";
import { reverseTransformData } from "../../../helpers/transformData";
import "./excelParser.scss";

function ExcelParser({ fileData, className, req_type, calledFrom }: any) {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Check if fileData is present
    if (fileData) {
      // Call reverseTransformData to convert fileData to original format
      const originalData = reverseTransformData(fileData, "none", req_type);
      // Set the originalData to the state
      setData(originalData);
    }
  }, [fileData]); // Run the effect whenever fileData changes

  return (
    <div
      className="excel-parser"
      style={{ width: calledFrom === "edit" ? "100%" : "" }}
    >
      {data.length > 0 && (
        <div className={`table-container ${className}`}>
          <table className="table">
            <thead>
              <tr>
                {Object.keys(data[0])?.map((key, index) => (
                  <th key={index}>{key !== "__EMPTY" && key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value: any, colIndex) => {
                    if (colIndex === 0) {
                      return <th key={colIndex}>{value}</th>;
                    } else {
                      const cellStyle = {
                        color: value < 0 ? "#BE0F00" : "inherit",
                      };
                      return (
                        <td key={colIndex} style={cellStyle}>
                          {value?.toLocaleString("en-US")}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ExcelParser;
