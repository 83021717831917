const normalizeYtd = (item) => {
  // Check for Ytd or YTD
  const ytdValue = item?.Ytd || item?.YTD;

  // Return the normalized value
  if (typeof ytdValue === "string") {
    return Number(ytdValue.replace(/,/g, ""));
  } else {
    return ytdValue || 0;
  }
};

export const transformData = (input, req_type = "net") => {
  const outputData = input.map((item) => {
    const yearData = {
      year:
        typeof item.__EMPTY === "string"
          ? Number(item?.__EMPTY?.replace(/,/g, ""))
          : item?.__EMPTY || null,
      jan:
        typeof item?.Jan === "string"
          ? Number(item?.Jan?.replace(/,/g, ""))
          : item?.Jan || null,
      feb:
        typeof item?.Feb === "string"
          ? Number(item?.Feb?.replace(/,/g, ""))
          : item?.Feb || null,
      mar:
        typeof item?.Mar === "string"
          ? Number(item?.Mar?.replace(/,/g, ""))
          : item?.Mar || null,
      apr:
        typeof item?.Apr === "string"
          ? Number(item?.Apr?.replace(/,/g, ""))
          : item?.Apr || null,
      may:
        typeof item?.May === "string"
          ? Number(item?.May?.replace(/,/g, ""))
          : item?.May || null,
      jun:
        typeof item?.Jun === "string"
          ? Number(item?.Jun?.replace(/,/g, ""))
          : item?.jun || null,
      jul:
        typeof item?.Jul === "string"
          ? Number(item?.Jul?.replace(/,/g, ""))
          : item?.Jul || null,
      aug:
        typeof item?.Aug === "string"
          ? Number(item?.Aug?.replace(/,/g, ""))
          : item?.Aug || null,
      sep:
        typeof item?.Sep === "string"
          ? Number(item?.Sep?.replace(/,/g, ""))
          : item?.Sep || null,
      oct:
        typeof item?.Oct === "string"
          ? Number(item?.Oct?.replace(/,/g, ""))
          : item?.Oct || null,
      nov:
        typeof item?.Nov === "string"
          ? Number(item?.Nov?.replace(/,/g, ""))
          : item?.Nov || null,
      dec:
        typeof item?.Dec === "string"
          ? Number(item?.Dec?.replace(/,/g, ""))
          : item?.Dec || null,
    };

    if (req_type !== "monthly") {
      yearData.ytd = normalizeYtd(item);
    }
    return yearData;
  });

  return outputData;
};

export const reverseTransformData = (outputData, type, req_type) => {
  const inputData = outputData.map((yearData) => {
    let item = {};
    const safeGetValue = (value) => (value != null ? value : null);
    if (type === "download") {
      item = {
        __EMPTY: safeGetValue(yearData.year),
        Jan: safeGetValue(yearData.jan),
        Feb: safeGetValue(yearData.feb),
        Mar: safeGetValue(yearData.mar),
        Apr: safeGetValue(yearData.apr),
        May: safeGetValue(yearData.may),
        Jun: safeGetValue(yearData.jun),
        Jul: safeGetValue(yearData.jul),
        Aug: safeGetValue(yearData.aug),
        Sep: safeGetValue(yearData.sep),
        Oct: safeGetValue(yearData.oct),
        Nov: safeGetValue(yearData.nov),
        Dec: safeGetValue(yearData.dec),
      };
      if (req_type !== "monthly") {
        item.YTD = yearData.ytd || null;
      }
    } else {
      item = {
        __EMPTY: safeGetValue(yearData.year),
        Jan: safeGetValue(yearData.jan),
        Feb: safeGetValue(yearData.feb),
        Mar: safeGetValue(yearData.mar),
        Apr: safeGetValue(yearData.apr),
        May: safeGetValue(yearData.may),
        Jun: safeGetValue(yearData.jun),
        Jul: safeGetValue(yearData.jul),
        Aug: safeGetValue(yearData.aug),
        Sep: safeGetValue(yearData.sep),
        Oct: safeGetValue(yearData.oct),
        Nov: safeGetValue(yearData.nov),
        Dec: safeGetValue(yearData.dec),
      };
      if (req_type !== "monthly") {
        item.YTD = safeGetValue(yearData.ytd);
      }
    }

    return item;
  });

  return inputData;
};
