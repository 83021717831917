import { useNavigate } from "react-router-dom";
import "./index.scss";

const Breadcrumb = ({ items, style = {} }) => {
  const navigate = useNavigate();
  const handleClick = (url, id) => {
    if (url === "company") {
      const navigated = navigate(-1);
      window.scrollTo({ top: 0, behavior: "auto" });

      if (!navigated) {
        navigate(`/dashboard/company/${id}`);
      }
    }
    if (url === "other") {
      navigate(`/dashboard/company/${id}`);
    }
  };
  return (
    <nav aria-label="breadcrumb" style={style}>
      <ol className="breadcrumb breadcrumb-box">
        {items?.map((item, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${item.active ? "active" : ""}`}
          >
            {item.url ? (
              <span onClick={() => handleClick(item?.url, item?.id)}>
                {item.label}
              </span>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
