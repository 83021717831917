import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "../../../../../../hooks/useDelete";
import { getData } from "../../../../../../services";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Add from "../../../../../Icons/Add";
import EditBlue from "../../../../../Icons/EditBlue";
import KeyLinks from "../../../../../Icons/KeyLinks";
import View from "../../../../../Icons/View";
import LinkIcon from "../../../../../Icons/linkIcon";
import CustomButton from "../../../../../atoms/CustomeButton";
import IconCircle from "../../../../../atoms/IconCircle";
import TypeDiv from "../../../../../atoms/TypeDiv";
import Modal from "../../../../../molecules/Modal/Normal";
import NoContentCard from "../../../../../molecules/NoContentCard";
import Pagination from "../../../../../molecules/Pagination";
import AddKeylink from "./AddKeylink";
import ViewData from "./View";
import "./styles.scss";

const Keylink = ({ token, type }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    formData: null, // Set initial state to null
    isDeleteModalOpen: false,
    isViewModalOpen: false,
  });

  const openModal = (title, formData, id) => {
    const modalId = id !== undefined ? id : uuidv4();
    setModalState({
      modalId: modalId,
      modalTitle: title,
      isModalOpen: true,
      formData: formData,
    });
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  const ViewModal = (formData) => {
    setModalState({
      modalId: uuidv4(),
      modalTitle: "View Keylink",
      isViewModalOpen: true,
      formData: formData,
    });
  };

  const { navigate } = useNavigate();
  const [keyLinkDataList, setKeyLinkDataList] = useState();

  const fetchKeyLinks = useCallback(async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "People/getAllPeopleKeyLink",
      params: {
        page: currentPage,
        page_limit: itemsPerPage,
        people_id: id,
      },
      token: token,
    });
    dispatch(setLoading(false));

    setKeyLinkDataList(res);
  }, [currentPage, itemsPerPage, token, id]);

  useEffect(() => {
    fetchKeyLinks();
  }, [fetchKeyLinks]);

  //  Handle deletion ---------------------------------
  const handleDelete = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.key_link_id,
      name: data?.title || "",
    }));
  };

  const deleteKeyLink = useDelete();
  const handleDeletion = async () => {
    try {
      await deleteKeyLink("People/deletePeopleKeyLink", "Keylink", {
        key_link_id: modalState.modalId,
      });
      fetchKeyLinks();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // PAgination MaNAGEMENT ----------------------------------
  const totalItems = keyLinkDataList?.total_record;
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <div>
      {keyLinkDataList?.data?.length > 0 ? (
        <>
          <div className="keyLinkWrap">
            <h4 className="fw-bolder mb-2">Keylinks</h4>
            {type !== "view" && (
              <CustomButton
                text="Add Keylink"
                icon={<Add />}
                handleClick={() => {
                  openModal("Add Keylink");
                }}
                type="btn-primary"
              />
            )}
          </div>

          {keyLinkDataList?.data.map((keyLink, index) => (
            <>
              <div key={index} className="keyLink-info-div">
                <div className="d-flex">
                  <IconCircle
                    color="#F4F4F4"
                    icon={
                      <i className="">
                        <LinkIcon />
                      </i>
                    }
                    size="48px"
                  />
                  <div className="keyLink-Images">
                    <div className="keyLink-title-status">
                      <span className="keyLink-title">{keyLink?.title}</span>

                      <div className="ms-3">
                        <TypeDiv
                          data={keyLink?.status}
                          color={keyLink?.status ? "#B5E4CA" : "#FFB7B7"}
                        />
                      </div>
                    </div>
                    <span className="keyLink-description">{keyLink?.link}</span>
                  </div>
                </div>
                {type != "view" && (
                  <div>
                    <div className="keyLink-edit-remove">
                      <IconButton
                        type="button"
                        onClick={() => ViewModal(keyLink)}
                      >
                        <i className="">
                          <View />
                        </i>
                      </IconButton>
                      <IconButton
                        type="button"
                        onClick={() => {
                          openModal("Edit Keylink", keyLink);
                        }}
                      >
                        <i>
                          <EditBlue className="edit-blue-icon" />
                        </i>
                      </IconButton>
                      {/* <IconButton
                        type="button"
                        onClick={() => handleDelete(keyLink)}
                      >
                        <i className="fi fi-rr-trash actions-icon-custom"></i>
                      </IconButton> */}
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
          {totalItems >= 10 && (
            <div className="mb-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      ) : type === "view" ? (
        <NoContentCard
          small={true}
          title="No Keylink Added Yet!"
          icon={<KeyLinks />}
        />
      ) : (
        <NoContentCard
          small={true}
          title="No Keylink Added Yet!"
          subtitle="To add keylinks, click on the add keylink button."
          icon={<KeyLinks />}
          handleClick={() => openModal("Add Keylink")}
          tag="Add Keylink"
        />
      )}

      {modalState.isModalOpen && (
        <Modal
          modalId={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.modalTitle}
        >
          <AddKeylink
            onClose={() => {
              closeModal();
              fetchKeyLinks();
            }}
            id={modalState.modalId}
            initialData={modalState.formData}
            onDelete={() => {}}
          />
        </Modal>
      )}

      {modalState.isViewModalOpen && (
        <Modal
          modalId={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          title={modalState.modalTitle}
        >
          <ViewData data={modalState?.formData} />
        </Modal>
      )}

      {/* {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Keylink"
          onClick={handleDeletion}
        />
      )} */}
    </div>
  );
};

export default Keylink;
