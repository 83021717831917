const Social = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1805_16278)">
        <path
          d="M21.3333 0H10.6667C8.89856 0 7.20286 0.702379 5.95262 1.95262C4.70238 3.20286 4 4.89856 4 6.66667V25.3333C4 26.2088 4.17244 27.0757 4.50747 27.8846C4.8425 28.6934 5.33356 29.4283 5.95262 30.0474C7.20286 31.2976 8.89856 32 10.6667 32H21.3333C22.2088 32 23.0757 31.8276 23.8846 31.4925C24.6934 31.1575 25.4283 30.6664 26.0474 30.0474C26.6664 29.4283 27.1575 28.6934 27.4925 27.8846C27.8276 27.0757 28 26.2088 28 25.3333V6.66667C28 4.89856 27.2976 3.20286 26.0474 1.95262C24.7971 0.702379 23.1014 0 21.3333 0V0ZM25.3333 25.3333C25.3333 26.3942 24.9119 27.4116 24.1618 28.1618C23.4116 28.9119 22.3942 29.3333 21.3333 29.3333H10.6667C9.6058 29.3333 8.58839 28.9119 7.83824 28.1618C7.08809 27.4116 6.66667 26.3942 6.66667 25.3333V6.66667C6.66667 5.6058 7.08809 4.58839 7.83824 3.83824C8.58839 3.08809 9.6058 2.66667 10.6667 2.66667H21.3333C22.3942 2.66667 23.4116 3.08809 24.1618 3.83824C24.9119 4.58839 25.3333 5.6058 25.3333 6.66667V25.3333Z"
          fill="#1A1D1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1805_16278">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Social;
