import React, { useState,useEffect } from "react";

const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    useEffect(() => {
      // Set initial screen width
      setScreenWidth(window.innerWidth);
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Remove event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array to run the effect only once on mount
  
    return screenWidth;
  };

export default useScreenWidth;