import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormHeading from "../../atoms/FormHeading";
import VerificationForm from "../../organisms/forms/authForms/VerificationForm";

const Verification = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e: any) => {
    e.preventDefault();
    navigate("/");
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);

  return (
    <div>
      <FormHeading
        title="2-Step Verification"
        description="Enter the verification code we sent you in your email. It will be valid for 5 minutes. If you need to resend a new code, please wait for 30 seconds."
      />
      <VerificationForm />
    </div>
  );
};

export default Verification;
