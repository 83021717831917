import PropTypes from "prop-types";
const EditBlue = ({
  className,
  width = "21",
  height = "20",
  fill = "#4E5660",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19.1424 2.53733L17.4899 0.886791C17.2085 0.605653 16.8745 0.382633 16.5068 0.230475C16.1391 0.0783163 15.745 0 15.347 0C14.949 0 14.555 0.0783163 14.1873 0.230475C13.8196 0.382633 13.4855 0.605653 13.2042 0.886791L0.873245 13.1998C0.746762 13.3328 0.668262 13.5041 0.650148 13.6867L0.00564494 19.0784C-0.0080221 19.1946 0.00319627 19.3124 0.0385561 19.4239C0.0739159 19.5354 0.132609 19.6381 0.210744 19.7253C0.288879 19.8124 0.38467 19.882 0.491765 19.9293C0.59886 19.9767 0.714811 20.0008 0.831931 20H0.928331L6.32398 19.3535C6.50849 19.3317 6.68024 19.2483 6.81149 19.1169L19.1424 6.81496C19.7101 6.24755 20.0289 5.47826 20.0289 4.67614C20.0289 3.87403 19.7101 3.10474 19.1424 2.53733ZM5.84749 17.7608L1.76839 18.2367L2.24488 14.1626L11.469 4.94986L15.0716 8.54802L5.84749 17.7608ZM17.9664 5.65684L16.2394 7.37889L12.6396 3.78348L14.372 2.05317C14.6303 1.79531 14.9805 1.65046 15.3457 1.65046C15.7108 1.65046 16.061 1.79531 16.3193 2.05317L17.9719 3.7037C18.2304 3.96127 18.3759 4.31085 18.3764 4.67557C18.377 5.04029 18.2324 5.39028 17.9746 5.64858L17.9664 5.65684Z"
        fill={fill}
        className={className}
      />
    </svg>
  );
};

EditBlue.propTypes = {
  className: PropTypes.string, // expects a string for additional class name
};
export default EditBlue;
