const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_6917_22109)">
        <path
          d="M17.3408 6.80183L11.652 1.11233C10.9479 0.410325 9.99426 0.0161133 9.00001 0.0161133C8.00576 0.0161133 7.05208 0.410325 6.34801 1.11233L0.659259 6.80183C0.449578 7.01017 0.283336 7.25806 0.170171 7.53112C0.0570054 7.80419 -0.0008328 8.097 9.05961e-06 8.39258V15.7553C9.05961e-06 16.3521 0.237062 16.9244 0.659019 17.3463C1.08098 17.7683 1.65327 18.0053 2.25001 18.0053H15.75C16.3467 18.0053 16.919 17.7683 17.341 17.3463C17.763 16.9244 18 16.3521 18 15.7553V8.39258C18.0009 8.097 17.943 7.80419 17.8298 7.53112C17.7167 7.25806 17.5504 7.01017 17.3408 6.80183ZM11.25 16.5053H6.75001V13.5548C6.75001 12.9581 6.98706 12.3858 7.40902 11.9638C7.83098 11.5419 8.40327 11.3048 9.00001 11.3048C9.59675 11.3048 10.169 11.5419 10.591 11.9638C11.013 12.3858 11.25 12.9581 11.25 13.5548V16.5053ZM16.5 15.7553C16.5 15.9542 16.421 16.145 16.2803 16.2857C16.1397 16.4263 15.9489 16.5053 15.75 16.5053H12.75V13.5548C12.75 12.5603 12.3549 11.6064 11.6517 10.9032C10.9484 10.1999 9.99457 9.80483 9.00001 9.80483C8.00545 9.80483 7.05162 10.1999 6.34836 10.9032C5.6451 11.6064 5.25001 12.5603 5.25001 13.5548V16.5053H2.25001C2.0511 16.5053 1.86033 16.4263 1.71968 16.2857C1.57903 16.145 1.50001 15.9542 1.50001 15.7553V8.39258C1.5007 8.19382 1.57965 8.00332 1.71976 7.86233L7.40851 2.17508C7.83128 1.75428 8.40351 1.51804 9.00001 1.51804C9.59651 1.51804 10.1687 1.75428 10.5915 2.17508L16.2803 7.86458C16.4198 8.00502 16.4987 8.1946 16.5 8.39258V15.7553Z"
          fill="#4E5660"
        />
      </g>
      <defs>
        <clipPath id="clip0_6917_22109">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HomeIcon;
