const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
    >
      <path
        d="M15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8947 14.2652 15 14 15H6C5.73478 15 5.48043 14.8947 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4805 5.29289 13.2929C5.48043 13.1054 5.73478 13 6 13H14C14.2652 13 14.5196 13.1054 14.7071 13.2929C14.8946 13.4805 15 13.7348 15 14ZM11 17H6C5.73478 17 5.48043 17.1054 5.29289 17.2929C5.10536 17.4805 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8947 5.73478 19 6 19H11C11.2652 19 11.5196 18.8947 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18C12 17.7348 11.8946 17.4805 11.7071 17.2929C11.5196 17.1054 11.2652 17 11 17ZM20 10.485V19C19.9984 20.3256 19.4711 21.5965 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5965 0.00158786 20.3256 0 19V5.00002C0.00158786 3.67443 0.528882 2.40358 1.46622 1.46624C2.40356 0.528905 3.67441 0.00161091 5 2.30487e-05H9.515C10.4346 -0.00234388 11.3456 0.177611 12.1952 0.529482C13.0449 0.881354 13.8163 1.39816 14.465 2.05002L17.949 5.53602C18.6012 6.18426 19.1184 6.95548 19.4704 7.805C19.8225 8.65451 20.0025 9.56545 20 10.485ZM13.051 3.46402C12.7363 3.15918 12.3829 2.89695 12 2.68402V7.00002C12 7.26524 12.1054 7.51959 12.2929 7.70713C12.4804 7.89467 12.7348 8.00002 13 8.00002H17.316C17.103 7.61721 16.8404 7.26417 16.535 6.95002L13.051 3.46402ZM18 10.485C18 10.32 17.968 10.162 17.953 10H13C12.2044 10 11.4413 9.68395 10.8787 9.12134C10.3161 8.55873 10 7.79567 10 7.00002V2.04702C9.838 2.03202 9.679 2.00002 9.515 2.00002H5C4.20435 2.00002 3.44129 2.31609 2.87868 2.8787C2.31607 3.44131 2 4.20437 2 5.00002V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.684 4.20435 22 5 22H15C15.7956 22 16.5587 21.684 17.1213 21.1213C17.6839 20.5587 18 19.7957 18 19V10.485Z"
        fill="#1E273B"
      />
    </svg>
  );
};
export default DocumentIcon;
