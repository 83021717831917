const Upload = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_1804_21844)">
        <path
          d="M5.17365 4.43382L7.02757 2.57924L7.04157 11.6669C7.04157 12.2191 7.48927 12.6668 8.04153 12.6668C8.59379 12.6668 9.04149 12.2191 9.04149 11.6669L9.02749 2.59059L10.8708 4.43385C11.2544 4.83109 11.8875 4.84208 12.2847 4.45841C12.6819 4.07474 12.6929 3.44171 12.3093 3.04447C12.3012 3.03613 12.293 3.02794 12.2847 3.01991L10.1434 0.878662C8.97193 -0.292882 7.07251 -0.292882 5.90097 0.87863L5.90093 0.878662L3.75971 3.01988C3.37604 3.41711 3.38704 4.05015 3.78428 4.43382C4.17179 4.80809 4.78614 4.80809 5.17365 4.43382Z"
          fill="#1A1D1F"
          className={className}
        />
        <path
          d="M14.9998 9.66602C14.4476 9.66602 13.9999 10.1137 13.9999 10.666V13.7265C13.9995 13.8769 13.8777 13.9988 13.7272 13.9991H2.27305C2.12262 13.9988 2.00075 13.8769 2.0004 13.7265V10.666C2.0004 10.1137 1.5527 9.66602 1.00045 9.66602C0.448188 9.66602 0.000488281 10.1137 0.000488281 10.666V13.7265C0.00195697 14.981 1.01857 15.9976 2.27305 15.999H13.7272C14.9817 15.9976 15.9983 14.981 15.9998 13.7265V10.666C15.9998 10.1137 15.5521 9.66602 14.9998 9.66602Z"
          fill="#1A1D1F"
          className={className}
        />
      </g>
      <defs>
        <clipPath id="clip0_1804_21844">
          <rect width="16" height="16" fill="white" className={className} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Upload;
